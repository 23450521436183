import { Form, Field } from 'react-final-form';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import React, { useState, useMemo } from 'react';
import moment from 'moment';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import Card from 'components/base-components/Card';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import DatePicker from 'components/base-components/DatePicker';
import Button from 'components/base-components/Button';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { useGetOrganizationQuery, useGetOrganizationsQuery } from 'api/organizations';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import ORGANISATION_TYPE from 'utils/organisation-type';
import ADDRESS_TYPE from 'utils/address-type';
import { DRAFT, OPEN } from 'utils/invoice-statuses';
import Link from 'components/base-components/Link';
import { useParams } from 'react-router-dom';
import { GASTRONOMY, PRODUCER } from 'utils/organisation-industries';
import Textarea from 'components/base-components/Textarea';
import { ANNUAL, INDIVIDUAL } from 'utils/invoice-types';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { removeEmptyFromObject } from 'utils/object-cleanup';
import classNames from 'classnames';
import ProducerItemsForm from '../shared/producer-items-form.component';
import InvoiceTypeComponent from '../shared/invoice-type.component';
import { organizationAddressToString } from '../../organisations/organisation-address';
import { yearMonthDayWithHyphen } from '../../../utils/date-time-formatter';
import LineItems from './line-items';

const ManualInvoiceForm = ({ invoice, invoiceItems, onSave, status }) => {
  const { isSuccess, isLoading, error, requestId } = status;
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { id } = useParams();

  const { data: organization } = useGetOrganizationQuery(invoice?.organizationId || id, {
    skip: !invoice?.organizationId && !id,
  });

  const [enableInvoiceDatePicker, setEnableInvoiceDatePicker] = useState(null);
  const [organisationQuery, setOrganisationQuery] = useState(null);
  const [department, setDepartment] = useState(null);
  const [removedItems, setRemovedItems] = useState([]);
  const isGastronomy = department === GASTRONOMY;
  const organizationSearchParams = {
    q: organisationQuery,
    status: ORGANISATIONS_STATUS.active,
    'department[]': [department],
    'types[]': [
      ORGANISATION_TYPE.parent_organisation,
      ORGANISATION_TYPE.sub_organisation,
      ORGANISATION_TYPE.single_organisation,
    ],
  };
  const { data: organizationCollection } = useGetOrganizationsQuery(organizationSearchParams, {
    skip: organization,
  });
  const organisationList = organizationCollection?.organizations || [];
  const organisationOptions = organisationList.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const departmentOptions = [
    { label: t('organisation.department.producer'), value: PRODUCER },
    { label: t('organisation.industry.gastronomy'), value: GASTRONOMY },
  ];

  const dueDateOptions = [
    {
      label: t('invoice.due_days.30_days'),
      value: 30,
    },
    {
      label: t('invoice.due_days.60_days'),
      value: 60,
    },
  ];
  const onSearchOrganisation = debounce(500, value => setOrganisationQuery(value));
  const isLineItemsAbsent = items => items.length === 0;
  const onSubmit = values => {
    let modifiedRemovedItems = removedItems?.map(item => {
      if (item.id) {
        return {
          ...item,
          _destroy: true,
        };
      }
      return item;
    });
    modifiedRemovedItems = (modifiedRemovedItems || []).filter(item => item.id);

    values?.items?.push(...modifiedRemovedItems);

    if (values.due_date) {
      values.due_date = getDueDate(values.due_date, values.invoice_date);
    }

    values['general_information'] = removeEmptyFromObject({
      zone_nr: values.zone,
      demeter: values.demeter,
      summer_pasture_holdings: values.summerOperation,
      open_arable_land_in_m2: values.openLand,
      crops_in_protected_cultivation_in_m2: values.protectedCultivation,
      special_and_permanent_crops_in_m2: values.specialCrops,
      livestock_in_dgve: values.livestockInDgve,
      agricultural_usable_area_total_in_m2: values.usableArea,
      grassland_in_m2: values.greenArea,
    });

    return onSave(values)
      .unwrap()
      .then(() => {
        return null;
      })
      .catch(({ data: { errors } }) => {
        const itemsError = [];
        errors?.['items']?.forEach(item => {
          const attributes = {
            name: item.name,
            quantity: item.quantity,
            description: item.description,
            price: item.price,
            vat_rate: item.vatRate,
            id: item.id,
          };
          itemsError.push(attributes);
        });
        return {
          organization: null,
          organization_mailing_address: null,
          invoice_id: errors?.invoiceId,
          invoice_date: errors?.invoiceDate,
          due_date: errors?.dueDate,
          items: itemsError,
        };
      });
  };

  const getDueDateInitialValue = (dueDate, invoiceDate) => {
    return moment(dueDate).diff(invoiceDate, 'days');
  };

  const initialValues = useMemo(() => {
    if (!organization) {
      return;
    }
    const itemsArray = [];
    invoiceItems?.forEach(item => {
      const attributes = {
        name: item.name,
        quantity: item.quantity,
        description: item.description,
        price: item.price,
        vat_rate: item.vatRate,
        id: item.id,
        cost_center: item.costCenter,
        cost_element: item.costElement,
      };
      itemsArray.push(attributes);
    });
    organisationOptions.push({ label: organization.name, value: organization.id });

    return {
      department: organization?.industry,
      organization: organization?.id,
      organization_mailing_address:
        organizationAddressToString(
          organization?.addresses?.find(address => address.type === ADDRESS_TYPE.invoice),
        ) || t('common.not_applicable'),
      invoice_id: invoice?.invoiceId,
      title: invoice?.title,
      note: invoice?.note,
      invoice_date: invoice?.invoiceDate,
      due_date: getDueDateInitialValue(invoice?.dueDate, invoice?.invoiceDate),
      items: itemsArray,
      status: invoice?.status,
    };
  }, [invoice?.id, invoiceItems?.length, organization?.id]);

  const getBillingAddress = e => {
    const selectedOrganisation = organisationList.find(org => org.id === e.value);

    const invoiceAddress = selectedOrganisation?.addresses.find(
      address => address.type === ADDRESS_TYPE.invoice,
    );
    if (invoiceAddress) return invoiceAddress;

    return selectedOrganisation?.addresses.find(
      address => address.type === ADDRESS_TYPE.general && address.main,
    );
  };
  const onRedirect = (values = {}) => {
    if (id) {
      navigate.openOrganisationInvoicePage(id);
    } else {
      navigate.openInvoiceListPage(values.status || 'draft');
    }
  };

  const getSuccessContent = values => {
    if (!isSuccess) {
      return null;
    }

    if (values?.status === OPEN) {
      return <div>{t('invoice.invoice_creation_message')}</div>;
    }

    if (values?.status === DRAFT) {
      return <div>{t('invoice.invoice_draft_message')}</div>;
    }

    return <div>{t('invoice.invoice_saving_message')}</div>;
  };

  const getDueDate = (dueDate, invoiceDate) => {
    return yearMonthDayWithHyphen(moment(invoiceDate).add(dueDate, 'days'));
  };

  return (
    <div className="invoice__form">
      <div>
        <div className="invoice__form_label margin-bottom-20 font-20">
          {invoice ? t('invoice.update_invoice_title') : t('invoice.create_invoice_title')}
        </div>
        <Card>
          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={initialValues}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="invoice__form_label margin-bottom-20">
                  {t('invoice.basic_details')}
                </div>
                <div>
                  <Field name="department">
                    {({ input, meta }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('invoice.business_entity')}
                        errorMsg={meta.submitError}
                        options={departmentOptions}
                        value={departmentOptions?.find(option => option.value === input.value)}
                        onChange={e => {
                          input.onChange(e.value);
                          setDepartment(e.value);
                        }}
                        onInputChange={onSearchOrganisation}
                        placeholderText={t('invoice.invoice_job.department.placeholder')}
                        required={true}
                        isDisabled={organization?.industry}
                        isClearable={true}
                      />
                    )}
                  </Field>
                </div>

                {values?.department === PRODUCER && <InvoiceTypeComponent />}
                <div className="margin-top-20 grid grid-cols-2">
                  <div className="col-span-1  invoice__form-input">
                    <Field name="organization">
                      {({ input, meta }) => (
                        <SelectBox
                          size="tiny"
                          width="large"
                          label={t('invoice.select_organization')}
                          errorMsg={meta.submitError}
                          options={organisationOptions}
                          value={organisationOptions?.find(option => option.value === input.value)}
                          onChange={e => {
                            input.onChange(e.value);
                            form.change(
                              'organization_mailing_address',
                              organizationAddressToString(getBillingAddress(e)) ||
                                t('common.not_applicable'),
                            );
                          }}
                          onInputChange={onSearchOrganisation}
                          placeholderText={t('user_assignment.organisation.placeholder')}
                          isDisabled={organization}
                          required={true}
                          isClearable={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-span-1  invoice__form-input invoice__form-input--right">
                    <Field name="organization_mailing_address">
                      {({ input, meta }) => (
                        <Input
                          size="tiny"
                          width="large"
                          label={t('invoice.invoice_mailing_address')}
                          placeholder={t('organisation.form.attributes.mailing_address')}
                          error={meta.submitError}
                          disabled={true}
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <hr className="invoice__divider" />
                <div>
                  <div className="invoice__form_label margin-top-20">
                    {t('invoice.invoice_details_title')}
                  </div>
                  {!isGastronomy && (
                    <div className="mt-2">
                      <Field name="title">
                        {({ input, meta }) => (
                          <Input
                            size="tiny"
                            width="large"
                            required={true}
                            label={t('invoice.invoice_title')}
                            placeholder={t('invoice.invoice_title')}
                            error={meta.submitError}
                            {...input}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <div
                    className={classNames('grid grid-cols-2 mt-4', {
                      'invoice__date-wrapper': !isGastronomy,
                    })}
                  >
                    <div
                      className={classNames('col-span-1', {
                        'p-0': !isGastronomy,
                        'px-0': isGastronomy,
                      })}
                    >
                      <Field name="invoice_date" initialValue={moment().format('YYYY-MM-DD')}>
                        {({ input, meta }) => (
                          <DatePicker
                            className="invoice__date-picker"
                            focused={enableInvoiceDatePicker}
                            date={input.value}
                            onChange={e => {
                              input.onChange(e);
                            }}
                            touched={!meta.dirtySinceLastSubmit}
                            error={meta.submitError}
                            onFocusChange={() =>
                              setEnableInvoiceDatePicker(!enableInvoiceDatePicker)
                            }
                            required={true}
                            displayFormat="LL"
                            label={t('invoice.invoice_date')}
                            navNext="arrowForwardAlt"
                            navPrev="arrowBackAlt"
                            size="tiny"
                            enablePastDates={true}
                            disableFutureDates={false}
                            futureYearsCount={0}
                            locale={i18n.language}
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      className={classNames('col-span-1  invoice__form-input--right', {
                        'p-0': !isGastronomy,
                        'px-0': isGastronomy,
                      })}
                    >
                      <Field name="due_date">
                        {({ input, meta }) => (
                          <SelectBox
                            size="tiny"
                            width="large"
                            label={t('invoice.due_date')}
                            errorMsg={meta.submitError}
                            required={true}
                            touched={!meta.dirtySinceLastSubmit}
                            options={dueDateOptions}
                            value={dueDateOptions?.find(option => option.value === input.value)}
                            onChange={e => {
                              input.onChange(e.value);
                            }}
                            placeholderText={'select date'}
                            isClearable={true}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  {!isGastronomy && (
                    <div className="invoice__date-wrapper">
                      <div className="col-span-12 ">
                        <Field name="note">
                          {({ input, meta }) => (
                            <Textarea
                              placeholder={t('invoice.write_note')}
                              label={t('invoice.note_label')}
                              error={meta.submitError}
                              touched={!meta.dirtySinceLastSubmit}
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  )}
                  <hr className="invoice__divider mb-2" />
                </div>
                <div>
                  <div className="invoice__form_label">{t('invoice.line_items_header')}</div>
                  {values?.type === ANNUAL ? (
                    <ProducerItemsForm isLineItemForm={true} />
                  ) : (
                    <LineItems setRemovedItems={setRemovedItems} />
                  )}
                </div>

                <div className="invoice__form-submit">
                  <Button
                    label={t('shared.action.save')}
                    disabled={
                      !values?.organization ||
                      (values?.type === INDIVIDUAL && isLineItemsAbsent(values.items || []))
                    }
                    size="small"
                    onClick={() => {
                      form.change('status', 'draft');
                      handleSubmit();
                    }}
                    type="success"
                  />

                  <Link size="small" modifier="default" type="button" onClick={onRedirect}>
                    {t('common.cancel')}
                  </Link>
                </div>
                <SubmitModal
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  requestId={requestId}
                  successTitle={
                    values.status === OPEN
                      ? t('invoice.invoice_publish_title')
                      : t('invoice.invoice_saving_title')
                  }
                  successContent={getSuccessContent(values)}
                  errorTitle={t('invoice.invoice_publish_failed_message')}
                  onSuccess={() => onRedirect(values)}
                  errorContent={error?.data?.message}
                  showError={true}
                />
              </form>
            )}
          />
        </Card>
      </div>
    </div>
  );
};

ManualInvoiceForm.defaultProps = {
  invoice: null,
  invoiceItems: null,
};

ManualInvoiceForm.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number,
    invoiceId: PropTypes.string,
    invoiceDate: PropTypes.instanceOf(Date),
    dueDate: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    organizationId: PropTypes.number,
    title: PropTypes.string,
    note: PropTypes.string,
  }),
  invoiceItems: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      service: PropTypes.string,
      vatRate: PropTypes.number,
      quantity: PropTypes.number,
      costCenter: PropTypes.string,
      costElement: PropTypes.string,
    }),
  ),
  onSave: PropTypes.func.isRequired,
  status: PropTypes.shape().isRequired,
};

export default ManualInvoiceForm;
