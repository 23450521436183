import React from 'react';
import { Field, useFormState } from 'react-final-form';
import Input from 'components/base-components/Input';
import { useTranslation } from 'react-i18next';
import { PHONE, MOBILE } from 'utils/contact_type';
import PropTypes from 'prop-types';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import { useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from 'api/organizations';

const OrganisationContact = ({ isChangeContactForm, validationError }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { values } = useFormState();
  const preferredContact = values?.preferredContact;

  const { data: organisation } = useGetOrganizationQuery(id, { skip: !id });

  const getInitialValue = type => {
    const initialValue = organisation?.phoneNumbers?.find(phone => phone.numberType === type);

    return initialValue?.number;
  };

  const initialPreferredContact = organisation?.phoneNumbers?.find(
    phoneNumber => phoneNumber.primary,
  )?.numberType;

  return (
    <>
      <div className="organisation__form--font-bold">{t('shared.attributes.contact')}</div>
      <div className="organisation__form-contacts-placeholder">
        {t('organisation.form.contacts.description')}
      </div>
      <div className="organisation__form--margin-top">
        <span>{t('organisation.form.contacts.preferred')}</span>
        <span className="margin-left-5">*</span>
      </div>
      <Field name="preferredContact" initialValue={initialPreferredContact}>
        {({ input, meta }) => (
          <>
            <div className="organisation__form-preferred-contact">
              {[PHONE, MOBILE].map(contactType => (
                <RadioWithoutCheck
                  key={contactType}
                  isChecked={input.value === contactType}
                  label={t(`shared.contacts.number_type.${contactType}`)}
                  name={contactType}
                  onChange={() => input.onChange(contactType)}
                  touched={!meta.dirtySinceLastSubmit}
                  error={meta.submitError}
                  size="medium"
                  data-testid={contactType}
                />
              ))}
            </div>
            {!input.value && validationError?.preferredContact && (
              <div className="legal-form__required-field">
                {t('production_error_text.select_contact')}
              </div>
            )}
          </>
        )}
      </Field>
      <>
        <div className="organisation__form-contact organisation__form--margin-top">
          <Field name={PHONE} initialValue={getInitialValue(PHONE)}>
            {({ input, meta }) => (
              <Input
                size="tiny"
                label={t('shared.contacts.number_type.phone')}
                className="organisation__form-contact-field"
                placeholder={t('organisation.form.placeholder.phone_no')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError || validationError.phoneError}
                required={preferredContact === PHONE}
                {...input}
              />
            )}
          </Field>
          <Field name={MOBILE} initialValue={getInitialValue(MOBILE)}>
            {({ input, meta }) => (
              <Input
                size="tiny"
                label={t('shared.contacts.number_type.cellphone')}
                className="organisation__form-contact-field"
                placeholder={t('organisation.form.placeholder.mobile_no')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError || validationError.mobileError}
                required={preferredContact === MOBILE}
                {...input}
              />
            )}
          </Field>
        </div>
        {isChangeContactForm && (
          <>
            <div className="organisation__form-contact organisation__form--margin-top">
              <Field name="email" initialValue={organisation?.emailAddresses?.[0]?.email}>
                {({ input, meta }) => {
                  return (
                    <Input
                      {...input}
                      size="tiny"
                      label={t('login.email_label')}
                      className="organisation__form-contact-field"
                      placeholder={t('login.email_placeholder')}
                      required={true}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                    />
                  );
                }}
              </Field>
              <Field name="website" initialValue={organisation?.website}>
                {({ input, meta }) => (
                  <Input
                    className="organisation__form-contact-field"
                    size="tiny"
                    label={t('organisation_view.overview.attributes.contact.website')}
                    placeholder={t(
                      'organisation_view.overview.attributes.contact.website_placeholder',
                    )}
                    {...input}
                  />
                )}
              </Field>
            </div>
          </>
        )}
        <Field name="emailId" type="hidden" component="input" />
        <div className="organisation__form-contact organisation__form--margin-top">
          <Field name="email">
            {({ input, meta }) => {
              return (
                <Input
                  {...input}
                  size="tiny"
                  label={t('login.email_label')}
                  className="organisation__form-contact-field"
                  placeholder={t('login.email_placeholder')}
                  required={false}
                  touched={!meta.dirtySinceLastSubmit}
                  error={meta.submitError}
                />
              );
            }}
          </Field>
        </div>
      </>
    </>
  );
};
OrganisationContact.defaultProps = {
  isChangeContactForm: false,
  validationError: {},
};

OrganisationContact.propTypes = {
  isChangeContactForm: PropTypes.bool,
  validationError: PropTypes.shape(),
};

export default OrganisationContact;
