import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';
import './farmland.styles.scss';
import { useGetProductTypeOptionsQuery } from 'api/producers/productions';
import CheckboxWithIcon from '../shared/checkbox-with-icon.component';

const FarmlandProductionCheckbox = ({ productTypes, validationError }) => {
  const form = useForm();
  const { data: productionTypes = [] } = useGetProductTypeOptionsQuery();
  const productionTypesMapper = {
    plant_cultivation: 'plant_cultivation',
    meat_production: 'meat_production',
    egg_production: 'egg',
    milk_production: 'milk',
    fish_production: 'fish',
    bee_keeping: 'bee',
    other: 'animal',
    gardening: 'garden',
    recreational_and_green_areas: 'greenArea',
    private_parks_and_municipalities: 'privatePark',
  };

  const reversedProductionTypesMapper = Object.keys(productionTypesMapper).reduce((acc, key) => {
    acc[productionTypesMapper[key]] = key;
    return acc;
  }, {});

  const productTypesData = productionTypes.map(({ value, label }) => {
    return {
      name: productionTypesMapper[value],
      icon: productionTypesMapper[value],
      label,
      key: value,
    };
  });

  const onChangeProductionTypes = (name, checked) => {
    let removedProductionTypes = form.getState().values.removedProductionTypes || [];
    if (checked) {
      removedProductionTypes = removedProductionTypes.filter(
        item => item !== reversedProductionTypesMapper[name],
      );
    } else {
      removedProductionTypes.push(reversedProductionTypesMapper[name]);
    }

    form.change('removedProductionTypes', removedProductionTypes);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-3 gap-x-3 gap-y-5 mt-4">
        <FieldArray name="production">
          {({ fields }) => (
            <>
              {productTypesData.map(({ name, label, icon, key }, index) => (
                <div key={key} className="col-span-1">
                  <CheckboxWithIcon
                    label={label}
                    fieldName={`production[${index}]`}
                    name={name}
                    icon={icon}
                    isChecked={productTypes ? productTypes.includes(key) : ''}
                    onChange={onChangeProductionTypes}
                  />
                </div>
              ))}
            </>
          )}
        </FieldArray>
        <div className="error-text">
          {validationError?.production && validationError.production}
        </div>
      </div>
    </>
  );
};

FarmlandProductionCheckbox.defaultProps = {
  productTypes: [],
  validationError: null,
};

FarmlandProductionCheckbox.propTypes = {
  productTypes: PropTypes.arrayOf(PropTypes.string),
  validationError: PropTypes.shape(),
};

export default FarmlandProductionCheckbox;
