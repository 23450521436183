import React from 'react';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetTVDListQuery } from 'api/tvd';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Input from 'components/base-components/Input';
import Pagination from 'components/base-components/Pagination';
import SidePanel from 'components/base-components/SidePanel';
import useDebounce from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AddTVD from './add-tvd.component';
import './style.scss';
import TVDListOverviewTable from './table.component';

const TVDListOverview = ({ organisationId }) => {
  const [searchInput, setSearchInput] = useState('');
  const [isAddSidepanelOpen, setIsAddSidepanelOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
  });
  const searchQuery = searchParams.get('q');
  const currentSection = searchParams.get('section');
  const currentPage = parseInt(searchParams.get('page'));
  const { t } = useTranslation();

  const tvdParams = {
    q: searchQuery,
    page: currentPage,
    'organization_ids[]': [organisationId],
  };

  const setSearchQueryParams = useCallback(
    params => {
      const validParams = {};

      Object.entries(params).forEach(([key, value]) => {
        if (value) validParams[key] = params[key];
      });

      setSearchParams(validParams);
    },
    [currentSection],
  );

  const setSearchQueryInParams = useDebounce(() => {
    onChangeParams({ section: currentSection, q: searchInput, page: 1 });
  });

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  const { isFetching, data } = useGetTVDListQuery(tvdParams);
  const tvdList = data ? data.collection : [];

  const { data: organization = {}, isOrganizationFetching } =
    useGetOrganizationQuery(organisationId);
  if (isOrganizationFetching) return null;

  const onChangeParams = (params = {}) => {
    const allParams = {
      q: searchQuery,
      page: currentPage,
    };
    setSearchQueryParams({ ...allParams, ...params });
  };

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  return (
    <>
      <div className="tvd-list__header">
        <Input
          className="organisation__search"
          placeholder={t('tvd.search_placeholder')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={onSearch}
        />
        <Button
          type="success"
          size="small"
          label={t('organisation_view.tvd.add_tvd_button')}
          onClick={() => setIsAddSidepanelOpen(true)}
        />
      </div>
      <div className="col-span-12 ">
        <TVDListOverviewTable
          tvdList={tvdList}
          isFetching={isFetching}
          organization={organization}
        />
      </div>
      <SidePanel
        isOpened={isAddSidepanelOpen}
        title={t('organisation_view.tvd.sidepanel.title.add')}
        body={
          isAddSidepanelOpen && (
            <AddTVD organization={organization} setIsAddSidepanelOpen={setIsAddSidepanelOpen} />
          )
        }
        onClose={() => {
          setIsAddSidepanelOpen(false);
        }}
        onClick={() => {
          setIsAddSidepanelOpen(false);
        }}
      />
      <Pagination
        onPaginationClick={newPage => {
          onChangeParams({ section: currentSection, q: searchQuery, page: newPage });
        }}
        totalPages={data && parseInt(data.pagination.totalPages, 10)}
        currentPage={currentPage}
        firstText="First"
        lastText="Last"
        nextText="Next"
        previousText="Prev"
      />
    </>
  );
};

TVDListOverview.propTypes = {
  organisationId: PropTypes.number.isRequired,
};

export default TVDListOverview;
