import {
  useGetOrganisationRolesQuery,
  useGetOrganisationTitlesQuery,
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
} from 'api/organizations';
import {
  useCreateUserAssignmentMutation,
  useGetUserQuery,
  useUpdateUserAssignmentMutation,
} from 'api/users';
import Button from 'components/base-components/Button';
import DatePicker from 'components/base-components/DatePicker';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import { organizationAddressToString } from 'components/organisations/organisation-address';
import createDecorator from 'final-form-calculate';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { debounce } from 'throttle-debounce';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import '../users.styles.scss';
import ORGANISATION_TYPE from 'utils/organisation-type';
import ASSIGNMENT_ROLES from 'utils/assignment-roles';
import { dateTimeWithZone } from '../../../utils/date-time-formatter';

const AssignmentForm = ({ editAssignment, setIsSidePanelOpen }) => {
  const [isValidFromOpen, setIsValidFromDateOpen] = useState(false);
  const [isValidUntilOpen, setIsValidUntilOpen] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [organisationQuery, setOrganisationQuery] = useState(null);
  const organizationSearchParams = {
    q: organisationQuery,
    status: ORGANISATIONS_STATUS.active,
    'types[]': [
      ORGANISATION_TYPE.parent_organisation,
      ORGANISATION_TYPE.sub_organisation,
      ORGANISATION_TYPE.single_organisation,
    ],
  };
  const { data: singleOrganisation } = useGetOrganizationQuery(editAssignment?.organizationId, {
    skip: !editAssignment,
  });

  const [createAssignment] = useCreateUserAssignmentMutation();
  const [updateAssignment] = useUpdateUserAssignmentMutation();
  const { userId } = useParams();
  const { t, i18n } = useTranslation();
  const { data: userDetails, isSuccess: isUserDetailsFetched } = useGetUserQuery({ id: userId });
  const { data } = useGetOrganizationsQuery(organizationSearchParams);

  const organisationList = data?.organizations;

  const organisationOptions = organisationList
    ? organisationList.map(org => ({ label: org.name, value: org.id }))
    : [];

  const { data: roleResponse = [] } = useGetOrganisationRolesQuery();
  const { data: titles = [] } = useGetOrganisationTitlesQuery();

  const possibleRoles =
    singleOrganisation?.type === ORGANISATION_TYPE.controlBody
      ? [ASSIGNMENT_ROLES.owner, ASSIGNMENT_ROLES.certifier, ASSIGNMENT_ROLES.auditor]
      : [ASSIGNMENT_ROLES.owner, ASSIGNMENT_ROLES.member];

  const roleOptions = roleResponse.filter(option => possibleRoles.includes(option.value));

  const titleOptions = titles.map(title => ({
    label: t(`organisation.titles.${title}`),
    value: title,
  }));

  const onSubmit = (values, form) => {
    const payload = removeEmptyFromObject(values);
    payload['valid_from'] = dateTimeWithZone(payload.valid_from);
    payload['valid_until'] = dateTimeWithZone(payload.valid_until);

    if (editAssignment) {
      const params = { id: { user_id: userId, id: editAssignment.id }, payload };
      return updateAssignment(params)
        .unwrap()
        .then(() => {
          form.reset();
          setIsSidePanelOpen(false);
        })
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      return createAssignment({ userId, payload })
        .unwrap()
        .then(() => {
          form.reset();
          setIsSidePanelOpen(false);
        })
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  const updateAddressField = useMemo(
    () =>
      createDecorator({
        field: 'organization_id',
        updates: {
          address: id => {
            const addressData =
              organisationList && organisationList.find(organisation => organisation.id === id);
            return organizationAddressToString(addressData?.mainAddress);
          },
        },
      }),
    [organisationList],
  );

  const onSearchOrganisation = debounce(500, value => setOrganisationQuery(value));

  useEffect(() => {
    if (singleOrganisation) {
      setSelectedOrganisation({
        label: singleOrganisation.name,
        value: singleOrganisation.id,
      });
    }
  }, [singleOrganisation?.id]);

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[updateAddressField]}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="user__edit--form-content">
            <div className="col-span-12 ">
              <Field name="title" initialValue={editAssignment?.title || null}>
                {({ input, meta }) => (
                  <SelectBox
                    className="user__education--form"
                    size="small"
                    width="large"
                    label={t('user_assignment.title_name.label')}
                    placeholderText={t('user_assignment.title_name.placeholder')}
                    isClearable={false}
                    options={titleOptions}
                    value={titleOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="role" initialValue={editAssignment?.role || null}>
                {({ input, meta }) => (
                  <SelectBox
                    className="user__education--form"
                    size="small"
                    width="large"
                    label={t('user_assignment.role.label')}
                    placeholderText={t('user_assignment.role.placeholder')}
                    isClearable={false}
                    options={roleOptions}
                    value={roleOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                    touched={!meta.dirtySinceLastSubmit}
                    errorMsg={meta?.submitError}
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="organization_id" initialValue={editAssignment?.organizationId || null}>
                {({ input, meta }) => (
                  <SelectBox
                    className="user__education--form"
                    size="small"
                    width="large"
                    label={t('user_assignment.organisation.label')}
                    placeholderText={t('user_assignment.organisation.placeholder')}
                    isClearable={false}
                    options={organisationOptions}
                    value={selectedOrganisation}
                    onChange={e => {
                      input.onChange(e.value);
                      setSelectedOrganisation(e);
                    }}
                    onInputChange={onSearchOrganisation}
                    touched={!meta.dirtySinceLastSubmit}
                    errorMsg={meta?.submitError}
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="address">
                {({ input }) => (
                  <Input
                    className="user__education--form"
                    size="small"
                    label={t('user_assignment.address.label')}
                    placeholder={t('user_assignment.address.placeholder')}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="email" initialValue={isUserDetailsFetched ? userDetails.email : null}>
                {({ input }) => (
                  <Input
                    className="user__education--form"
                    size="small"
                    label={t('user_assignment.email.label')}
                    placeholder={t('user_assignment.email.placeholder')}
                    {...input}
                  />
                )}
              </Field>
            </div>
            {/* TODO: when verification can be done by phone number as well*/}
            {/* <div className="col-span-12 ">
              <Field
                name="phone_number"
                initialValue={isUserDetailsFetched ? userDetails.cellphone : ''}
              >
                {({ input }) => (
                  <Input
                    className="user__education--form"
                    size="small"
                    disabled={true}
                    label={t('user_assignment.phone.label')}
                    placeholder={t('user_assignment.phone.number_placeholder')}
                    {...input}
                  />
                )}
              </Field>
            </div> */}
            <div className="col-span-12 ">
              <Field name="valid_from" initialValue={editAssignment?.validFrom || null}>
                {({ input, meta }) => (
                  <DatePicker
                    className="user__education--form"
                    focused={isValidFromOpen}
                    date={input.value}
                    onChange={e => input.onChange(e)}
                    onFocusChange={() => setIsValidFromDateOpen(!isValidFromOpen)}
                    displayFormat="LL"
                    label={t('user_assignment.valid_form.label')}
                    placeholder={t('user_assignment.valid_form.placeholder')}
                    navNext="arrowForwardAlt"
                    navPrev="arrowBackAlt"
                    size="small"
                    enablePastDates={true}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta?.submitError}
                    locale={i18n.language}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="valid_until" initialValue={editAssignment?.validUntil || null}>
                {({ input, meta }) => (
                  <DatePicker
                    className="user__education--form user__education--form__institute"
                    focused={isValidUntilOpen}
                    date={input.value}
                    onChange={e => input.onChange(e)}
                    onFocusChange={() => setIsValidUntilOpen(!isValidUntilOpen)}
                    displayFormat="LL"
                    label={t('user_assignment.valid_until.label')}
                    placeholder={t('user_assignment.valid_until.placeholder')}
                    navNext="arrowForwardAlt"
                    navPrev="arrowBackAlt"
                    size="small"
                    enablePastDates={true}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta?.submitError}
                    locale={i18n.language}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="col-span-12 ">
            <Button
              label={
                editAssignment ? t('user_education.update_button') : t('user_education.add_button')
              }
              type="success"
              size="small"
              disabled={submitting}
              submitType="submit"
              className="user__edit--submit"
            />
          </div>
        </form>
      )}
    />
  );
};

AssignmentForm.defaultProps = {
  editAssignment: null,
  setIsSidePanelOpen: () => {},
};

AssignmentForm.propTypes = {
  editAssignment: PropTypes.shape(),
  setIsSidePanelOpen: PropTypes.func,
};

export default AssignmentForm;
