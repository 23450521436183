import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SideNavList({ children, type }) {
  return (
    <ul
      className={classNames({
        'side-nav__list': type === 'list',
        'side-nav__footer-list': type === 'footer',
      })}
    >
      {children}
    </ul>
  );
}

SideNavList.defaultProps = {
  type: 'list',
  setIsSideNavOpen: () => {},
};

SideNavList.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['footer', 'list']),
  setIsSideNavOpen: PropTypes.func,
};

export default SideNavList;
