import { t } from 'i18next';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import Input from 'components/base-components/Input';
import { useGetOrganizationQuery } from 'api/organizations';
import { useParams } from 'react-router';
import { useGetProductionsQuery } from 'api/producers/productions';
import PropTypes from 'prop-types';

import LandForm from './land-form.component';
import FarmLandProcessingCheckbox from './processing-checkbox.component';
import FarmlandProductionCheckbox from './production-checkbox.component';
import FarmlandPreviousCultivation from './previous-cultivation.component';

const FarmlandForm = ({ validationError }) => {
  const { id } = useParams();

  const { data: farmlandData } = useGetProductionsQuery({ organizationId: id }, { skip: !id });
  const { data: organization } = useGetOrganizationQuery(id, { skip: !id });
  const previousCultivation = organization?.previousCultivation;
  const { values } = useFormState();
  const isDGVERequired = values.production?.some(item =>
    ['egg', 'meat_production', 'milk', 'animal'].includes(item),
  );

  return (
    <>
      <div className="farmland__main-title">{t('farmland.title')}</div>
      <div className="farmland__main-description">{t('farmland.description')}</div>
      <LandForm
        agriculturalLandInHectare={farmlandData?.agriculturalLandInHectare}
        openCultivatedLandInHectare={farmlandData?.openCultivatedLandInHectare}
        greenlandInHectare={farmlandData?.greenlandInHectare}
        startingYear={organization?.customerSince ? parseInt(organization.customerSince) : null}
        validationError={validationError}
      />
      <div className="farmland__operation-form--title">
        <span>{t('farmland.operation_form_title')}</span>
        <span className="margin-left-5">*</span>
      </div>
      <div className="farmland__operation-form--description">
        {t('farmland.operation_form_description')}
      </div>
      <FarmlandProductionCheckbox
        productTypes={farmlandData?.productTypes}
        validationError={validationError}
      />
      <FarmLandProcessingCheckbox processings={farmlandData?.processings} />
      <div className="grid grid-cols-2">
        <FarmlandPreviousCultivation previousCultivation={previousCultivation} />
      </div>
      <div className="grid grid-cols-2">
        <div className="col-span-1">
          <Field name="dgve" initialValue={farmlandData?.dgve}>
            {({ input, meta }) => (
              <Input
                className="farmland__dgve--input"
                type="number"
                size="tiny"
                label={t('farmland.dgve_title')}
                placeholder={t('farmland.dgve_estimation_placeholder')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError || validationError?.dgve}
                required={isDGVERequired}
                {...input}
              />
            )}
          </Field>
        </div>
      </div>
    </>
  );
};

FarmlandForm.defaultProps = {
  farmlandData: {},
  validationError: {},
};

FarmlandForm.propTypes = {
  farmlandData: PropTypes.shape(),
  validationError: PropTypes.shape(),
};

export default FarmlandForm;
