import React from 'react';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import RecognitionDetails from './index.component';

const RecognitionDetailsWrapper = () => {
  const onSubmit = () => {};
  return (
    <div className="m-3">
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <RecognitionDetails form={form} />
          </form>
        )}
      />
    </div>
  );
};

export default RecognitionDetailsWrapper;
