import { Tabs } from 'components/base-components/RTabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCurrentUserQuery } from 'api/users';
import { useSearchParams } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import CommentsComponent from './comments.component';
import TaskDetailsComponent from './task-details.component';

const TaskDetailsTab = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'task_details' });
  const currentTab = searchParams.get('tab');

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);

  const TAB_MAPPER = {
    task_details: 0,
    comments: 1,
  };
  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab });
  };

  return (
    <div className="grid grid-cols-12 task__details-wrapper">
      <div className="col-span-5 col-start-4 task__details-content">
        {isAdmin ? (
          <Tabs
            controlled={true}
            selectedTabKey={TAB_MAPPER[currentTab]}
            items={[
              {
                title: t('task.details'),
                key: 0,
                getContent: () => <TaskDetailsComponent />,
              },
              {
                title: t('task.comments'),
                key: 1,
                getContent: () => <CommentsComponent />,
              },
            ]}
            onChange={onChangeTab}
          />
        ) : (
          <div className="task__details-content--task">
            <TaskDetailsComponent />
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskDetailsTab;
