import { useGetActiveSurveyQuery } from 'api/control-body-survey';
import { useGetOrganizationQuery } from 'api/organizations';
import OrganizationName from 'components/organisations/organization-name';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import { useParams } from 'react-router';
import { NEGATIVE, TEMPORARY } from 'utils/control-body-survey';
import { PRODUCER } from 'utils/organisation-industries';
import ORGANISATION_TYPE from 'utils/organisation-type';
import NegativeReports from './bio-cuisine-assessment/negative-reports';
import LastControlFlow from './bio-cuisine-assessment/schedules/last-control-flow.component';
import UpcomingControlFlow from './bio-cuisine-assessment/schedules/upcoming-control-flow.component';
import CurrentControlBodyComponent from './current-control-body.component';

const CurrentControlBodyWrapper = () => {
  const { id } = useParams();
  const { data: organisation = {} } = useGetOrganizationQuery(id);
  const { data: activeSurvey = {}, error } = useGetActiveSurveyQuery({ organizationId: id });

  const showNegativeReport =
    !error &&
    activeSurvey &&
    activeSurvey.status === TEMPORARY &&
    activeSurvey.assessmentOutcome === NEGATIVE;

  const isProducer = organisation.industry === PRODUCER;

  const showControlFlow =
    organisation.type !== ORGANISATION_TYPE.parent_organisation && !isProducer;

  return (
    <>
      {isProducer ? (
        <>
          <div className="control-body__producer--title">
            {t('control_body.table_field.control_body')}
          </div>
          <div className="control-body__producer--name">
            <OrganizationName
              disableLink={true}
              organizationId={organisation.controlBodyOrganizationId}
            />
          </div>
        </>
      ) : (
        <CurrentControlBodyComponent />
      )}

      {showControlFlow && (
        <Fragment>
          <LastControlFlow />
          {!showNegativeReport && <UpcomingControlFlow />}
          {showNegativeReport && (
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <div className="negative-reports__title">{t('control_body.current_wrapper')}</div>
                <NegativeReports />
              </div>
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};

export default CurrentControlBodyWrapper;
