import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import TextEditor from 'components/base-components/TextEditor';
import CheckBox from 'components/base-components/CheckBox';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Title } from 'components/base-components/Typography';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import {
  useCreateMessageMutation,
  useGetSingleMessageQuery,
  useUpdateMessageMutation,
} from 'api/messages';
import { useParams } from 'react-router';
import { useGetUsersQuery } from 'api/users';
import { useGetOrganizationsQuery } from 'api/organizations';
import SelectBox from 'components/base-components/SelectBox';
import { debounce } from 'throttle-debounce';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { Link } from 'react-router-dom';
import { ORGANISATIONS_STATUS, USERS_STATUS } from '../../utils/statuses';
import './message-management.styles.scss';

const CreateMessage = () => {
  const [selectedRecipientType, setSelectedRecipientType] = useState('organisation_members');
  const [assigneeQuery, setAssigneeQuery] = useState(null);
  const [recipientOption, setRecipientOption] = useState('');

  // TODO: setRecipientOption state will be handled in the story: https://welltravel.atlassian.net/browse/BIOS-179

  const [
    createMessage,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess, requestId: createRequestId },
  ] = useCreateMessageMutation();
  const [
    updateMessage,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, requestId: updateRequestId },
  ] = useUpdateMessageMutation();
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: message } = useGetSingleMessageQuery(id);

  const userSearchParams = {
    q: assigneeQuery,
    status: USERS_STATUS.active,
  };

  const organizationSearchParams = {
    q: assigneeQuery,
    status: ORGANISATIONS_STATUS.active,
  };

  const onSearchAssignee = debounce(500, value => {
    setAssigneeQuery(value);
  });

  const { data: organisations } = useGetOrganizationsQuery(organizationSearchParams);
  const { data: usersCollection } = useGetUsersQuery(userSearchParams);
  const users = usersCollection ? usersCollection.users : [];

  const recipientUserIds = 'recipient_user_ids';
  const recipientOrganizationId = 'recipient_organization_id';
  const biosuisse = 'biosuisse';

  useEffect(() => {
    if (message?.recipientOrganizationId) {
      setSelectedRecipientType('organisation');
    } else if (message?.recipientUserIds?.length === 1) {
      setSelectedRecipientType('organisation_members');
    } else if (id) {
      setSelectedRecipientType('bio_suisse');
    }
  }, [message]);

  const bioSuisseOptions = [
    {
      label: t('messages.recipient.biosuisse'),
      value: true,
    },
  ];

  const recipientCheckOptions = [
    {
      name: t('messages.recipient.organisation_members'),
      value: 'organisation_members',
    },
    {
      name: t('messages.recipient.organisation'),
      value: 'organisation',
    },
    {
      name: t('messages.recipient.biosuisse'),
      value: 'bio_suisse',
    },
  ];

  let recipientOptions;

  const getRecipient = () => {
    if (selectedRecipientType === 'organisation_members') {
      recipientOptions =
        users &&
        users.map(user => {
          return { label: user?.name, value: user?.id };
        });
      return recipientOptions;
    } else if (selectedRecipientType === 'organisation') {
      recipientOptions =
        organisations &&
        organisations.organizations.map(organisation => {
          return { label: organisation?.name, value: organisation?.id };
        });
      return recipientOptions;
    } else if (selectedRecipientType === 'bio_suisse') {
      recipientOptions = bioSuisseOptions;
      return recipientOptions;
    }
  };

  const getRecipientInitialValue = () => {
    switch (selectedRecipientType) {
      case 'bio_suisse':
        return true;
      case 'organisation':
        return message?.recipientOrganizationId;
      case 'organisation_members':
        return message?.recipientUserIds?.[0];
      default:
        return null;
    }
  };

  const getRecipientName = () => {
    if (selectedRecipientType === 'organisation') {
      return recipientOrganizationId;
    } else if (selectedRecipientType === 'organisation_members') {
      return recipientUserIds;
    } else if (selectedRecipientType === 'bio_suisse') {
      return biosuisse;
    }
  };

  const recipientFieldNames = [recipientUserIds, recipientOrganizationId, biosuisse];

  const updateRecipientType = (value, form) => {
    recipientFieldNames.forEach(field => {
      form.change(field, undefined);
    });

    setSelectedRecipientType(value);
    setRecipientOption(value === 'bio_suisse' ? bioSuisseOptions[0] : null);
  };

  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();

  const onRecipientInputChange = (recipient, form) => {
    const recipientName = getRecipientName();
    setRecipientOption(recipient);

    recipientFieldNames.forEach(field => {
      if (field !== recipientName) {
        form.change(field, undefined);
      }
    });

    switch (recipientName) {
      case recipientUserIds:
        form.change(recipientName, [recipient.value]);
        break;
      case recipientOrganizationId:
        form.change(recipientName, recipient.value);
        break;
      case biosuisse:
        form.change(recipientName, true);
        break;
      default:
        return null;
    }
  };

  const onSubmit = values => {
    if (id) {
      return updateMessage({ id: message.id, payload: values })
        .unwrap()
        .then(() => {})
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      return createMessage(values)
        .unwrap()
        .then(() => {})
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <div className="col-span-12">
      <SubmitModal
        isLoading={isCreateLoading}
        isSuccess={isCreateSuccess}
        requestId={createRequestId}
        loaderContent={t('messages.modal.create.loader_message')}
        successTitle={t('messages.modal.create.success.title')}
        successContent={t('messages.modal.create.success.content')}
        onSuccess={() => navigate.openMessagesListPage()}
        showError={false}
      />
      <SubmitModal
        isLoading={isUpdateLoading}
        isSuccess={isUpdateSuccess}
        requestId={updateRequestId}
        loaderContent={t('messages.modal.update.loader_message')}
        successTitle={t('messages.modal.update.success.title')}
        successContent={t('messages.modal.update.success.content')}
        onSuccess={() => navigate.openMessagesListPage()}
        showError={false}
      />
      <Card className="col-span-12 p-0 message__create-card">
        <div className="message__create-card__container">
          <Title className="message__create--form__header">
            {id ? t('messages.edit_message') : t('messages.send_new_message')}
          </Title>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitErrors, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="col-span-12 message__create--form__field">
                  <Field name="title" initialValue={message?.title}>
                    {({ input, meta }) => (
                      <Input
                        size="tiny"
                        label={t('messages.create_form.title')}
                        placeholder={t('messages.create_form.title_placeholder')}
                        disabled={id}
                        required={true}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-span-12 message__create--form__field">
                  <Field name="text" initialValue={message?.text}>
                    {({ input, meta }) => (
                      <TextEditor
                        placeholder={t('messages.create_form.description_placeholder')}
                        label={t('messages.create_form.description')}
                        error={meta.submitError}
                        isRequired={true}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-span-12 message__create--form__field">
                  <div className="message__create--form__wrapper">
                    <div className="message__create--form__checkbox--label">
                      {t('messages.create_form.message_to')}
                    </div>
                    <div className="message__create--form__checkbox">
                      {recipientCheckOptions.map((option, index) => (
                        <CheckBox
                          key={index}
                          size="tiny"
                          label={option.name}
                          isChecked={option.value === selectedRecipientType}
                          onChange={() => updateRecipientType(option.value, form)}
                          disabled={id}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 message__create--form__field">
                  <Field
                    name={getRecipientName()}
                    type="select"
                    initialValue={getRecipientInitialValue()}
                  >
                    {({ input, meta }) => (
                      <div>
                        <SelectBox
                          size="tiny"
                          isDisabled={!selectedRecipientType || id}
                          onInputChange={onSearchAssignee}
                          isSearchable={true}
                          placeholderText={t('tasks.create_form.assignee_placeholder')}
                          isClearable={false}
                          options={getRecipient()}
                          value={recipientOption}
                          selectedValue={input.label}
                          onChange={e => {
                            onRecipientInputChange(e, form);
                          }}
                          required={true}
                          touched={true}
                          errorMsg={meta.submitError}
                        />
                        <div className="message__create--form__field-error">
                          {submitErrors?.base}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-span-12 message__create--form__field">
                  <Field
                    name="need_confirmation"
                    type="checkbox"
                    initialValue={id ? message?.needConfirmation : false}
                  >
                    {({ input }) => {
                      return (
                        <CheckBox
                          className="message__create--form__confirmation"
                          isChecked={input.checked}
                          size="tiny"
                          label={t('messages.create_form.confirmation')}
                          onChange={checked => {
                            input.onChange(checked);
                          }}
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="message__create--footer">
                  <Button
                    label={id ? t('common.edit_button') : t('shared.action.send')}
                    type="success"
                    size="small"
                    onClick={handleSubmit}
                  />
                  <Link to={navigationLinks.messagesListPage()}>
                    <Button
                      className="message__create--footer__cancel-btn"
                      size="small"
                      label={t('tasks.create_form.cancel_button')}
                    />
                  </Link>
                </div>
              </form>
            )}
          />
        </div>
      </Card>
    </div>
  );
};

export default CreateMessage;
