import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useGetOrganizationQuery } from 'api/organizations';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Avatar from 'components/base-components/Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import { PRODUCER } from 'utils/organisation-industries';
import { useGetCurrentUserQuery } from 'api/users';
import useResponsive from 'hooks/useResponsive';
import useNavigationLinks from 'hooks/useNavigationLinks';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import Popover from 'components/base-components/Popover';
import { Link } from 'react-router-dom';
import DefaultSpinner from 'components/shared-components/default-spinner';
import OrganizationName from '../organization-name';
import PopoverContent from './overview/popover-content.component';

const OrganisationViewHeader = () => {
  const { isMobile } = useResponsive();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();
  const { data: organisation, isFetching } = useGetOrganizationQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();
  if (!currentUser) return <DefaultSpinner />;

  const isAdmin = isBioSuisseAdmin(currentUser);
  const isControlBodyUser = isControlBodyMember(currentUser);

  const mainAddress = organisation?.addresses?.find(address => address.main === true);

  const isProducer = organisation?.industry === PRODUCER;

  return (
    <div className="flex items-center justify-between mt-3 sm:mt-4">
      <div className="flex items-start">
        {isFetching ? (
          <div className="mr-3">
            <ContentLoaderPlaceholder numberOfLines={0} shape="circle" diameter="large" />
          </div>
        ) : (
          <Avatar
            src={organisation?.logoUrl}
            firstName={organisation?.name.split(' ')[0]}
            lastName={organisation?.name.split(' ')[1]}
            size={isMobile ? 'large' : 'huge'}
          />
        )}
        <div className="ml-2 text-blueish-black">
          {isFetching ? (
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          ) : (
            <div className="flex items-start sm:items-center">
              <div>
                <div className="max-w-[180px]">
                  <OrganizationName
                    className="font-bold text-lg sm:text-2xl sm:overflow-hidden sm:whitespace-nowrap sm:text-ellipsis"
                    organizationId={organisation?.id}
                    disableLink={true}
                  />
                </div>
                <div className="text-base my-1">{`${mainAddress?.line1}, ${mainAddress?.city}`}</div>
                <div className="flex items-center text-base">
                  <div>{organisation.bioId || t('common.not_applicable')}</div>
                  <div className="bg-blueish-black-16 h-[17px] mx-2 w-[1px]" />
                  <div>{organisation.uid || t('common.not_applicable')}</div>
                </div>
              </div>
              <div className="bg-blueish-black-16 h-[42px] mx-2 w-[1px] hidden sm:block" />
              <Popover
                isVisible={isPopoverVisible}
                onOutsideClick={() => setIsPopoverVisible(false)}
                content={
                  <PopoverContent
                    isProducer={isProducer}
                    id={id}
                    organisation={organisation}
                    isControlBodyUser={isControlBodyUser}
                    isAdmin={isAdmin}
                  />
                }
                size="medium"
                direction="bottom-left"
              >
                <IconButton
                  className="mr-1 icon-button-styles"
                  icon={<Icon name="viewdetails" />}
                  color="default"
                  onClick={() => setIsPopoverVisible(true)}
                  size="large"
                  isIconOnly={true}
                />
              </Popover>
              <Link to={navigationLinks.organisationDetailsPage(id)}>
                <IconButton
                  className="icon-button-styles"
                  icon={<Icon name="goBack" size="small" />}
                  color="default"
                  size="medium"
                  isIconOnly={true}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganisationViewHeader;
