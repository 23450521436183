import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetPasswordRequestMutation } from 'api/password-management';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import '../styles.scss';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import Icon from 'components/base-components/Icon';
import Spinner from 'components/base-components/Spinner/Spinner';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { Form, Field } from 'react-final-form';
import { toSnakecaseKeys } from 'utils/object-cleanup';

const RequestForm = () => {
  const [resetPasswordRequest, { isLoading, isSuccess, error }] = useResetPasswordRequestMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const sendVerificationEmail = values => {
    const payload = {
      email: values['email'],
    };
    return resetPasswordRequest(payload)
      .unwrap()
      .then(() => {
        setIsModalOpen(true);
      })
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  const modalContent = () => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('user_invite.modal.sending_invitation')}
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('user_invite.modal.failure_header')}</div>
            <div>{t('user_invite.modal.failure_description')}</div>
            <div>{t('user_invite.modal.try_again')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('forgot_password.email_sent_header')}</div>
          <div>{t('forgot_password.email_sent_description')}</div>
        </div>
      </div>
    );
  };

  return (
    <WelcomePageComponent isForgotPassword={true}>
      <span className="mt-6 max-w-[700px] text-center">
        {t('forgot_password.email_suggestion')}
      </span>
      <Form
        onSubmit={sendVerificationEmail}
        render={({ handleSubmit, submitting, submitErrors, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email">
              {({ input, meta }) => (
                <Input
                  className="mt-6 w-[345px] md:mt-[50px]"
                  label={t('forgot_password.email_input')}
                  placeholder="email@example.com"
                  error={meta.submitError}
                  touched={!meta.dirtySinceLastSubmit}
                  {...input}
                />
              )}
            </Field>
            <Button
              label={t('forgot_password.submit_button')}
              size="normal"
              type="success"
              className="mt-[46px] w-[345px] rounded"
              submitType="submit"
              disabled={!values.email}
            />
          </form>
        )}
      />
      <SubmitModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={!!error}
        loaderContent={t('forgot_password.sending_reset_password_link')}
        successTitle={t('forgot_password.sending_reset_password_link_success')}
        errorTitle={t('forgot_password.sending_reset_password_link_failure')}
        errorContent={t('forgot_password.try_again')}
      />
      <ConfirmationModalWrapper
        isVisible={isModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent()}
        className="user__invite--modal"
        timeOut={3000}
        onTimeout={() => {
          setIsModalOpen(false);
          if (isSuccess) navigate.openLoginPage();
        }}
      />
    </WelcomePageComponent>
  );
};

export default RequestForm;
