import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PillTabs } from 'components/base-components/PillTabs';
import { useGetUsersQuery } from 'api/users';
import { Tabs } from 'components/base-components/RTabs';
import Pagination from 'components/base-components/Pagination';
import { USERS_STATUS } from 'utils/statuses';
import HeaderComponent from 'components/shared-components/header-component';
import { ADMIN, ALL_USER, CONTROL_BODY_USER, USER } from 'utils/users-role';
import { ACTIVE, ARCHIVED, INVITED } from 'utils/verification-status';
import TabContent from './tab-content.component';
import '../users.styles.scss';

const UsersList = ({ isOrganisationView, organizationId }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    type: ALL_USER,
    tab: USERS_STATUS.active,
    q: '',
    page: 1,
  });
  const { t } = useTranslation();
  const currentType = searchParams.get('type');
  const currentTab = searchParams.get('tab');
  const currentDirection = searchParams.get('direction');
  const currentColumn = searchParams.get('column');
  const currentPage = parseInt(searchParams.get('page'), 10);

  const userParams = {
    q: searchParams.get('q'),
    status: USERS_STATUS[currentTab],
    page: currentPage,
    'organization_ids[]': [organizationId],
    'types[]': currentType === ALL_USER ? [] : [currentType],
    direction: currentDirection,
    column: currentColumn,
  };

  const { isFetching, data } = useGetUsersQuery(userParams);
  const users = data ? data.users : [];

  const TYPE_MAPPER = {
    all_user: 0,
    admin: 1,
    user: 2,
    control_body_user: 3,
  };

  const TAB_MAPPER = {
    active: 0,
    archived: 1,
    invited: 2,
  };

  const onChangeType = selectedTypeKey => {
    const selectedType = Object.keys(TYPE_MAPPER).find(
      type => TYPE_MAPPER[type] === selectedTypeKey,
    );
    setSearchQueryParams({ type: selectedType, tab: USERS_STATUS.active, page: 1 });
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchQueryParams({ tab: selectedTab, page: 1 });
  };

  const getPagination = () => {
    return (
      <Pagination
        onPaginationClick={newPage => {
          setSearchQueryParams({
            page: newPage,
          });
        }}
        totalPages={data && parseInt(data.pagination.totalPages, 10)}
        currentPage={currentPage}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
      />
    );
  };

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentTab],
  );

  const tabTitles = [ACTIVE, ARCHIVED, INVITED];

  const tabItems = tabTitles.map(tabItem => ({
    title: t(`user.${tabItem}_user_tab`),
    key: tabItem,
    getContent: () => (
      <TabContent
        tab={tabItem}
        key={tabItem}
        onChangeParams={setSearchQueryParams}
        users={users}
        isLoading={isFetching}
        organizationId={organizationId}
        isOrganisationView={isOrganisationView}
      />
    ),
  }));

  const typeTitles = [ALL_USER, ADMIN, USER, CONTROL_BODY_USER];

  const typeItems = typeTitles.map(userType => ({
    title: t(`role.${userType}`),
    key: userType,
    getContent: () => (
      <PillTabs
        controlled={true}
        size="tiny"
        selectedTabKey={TAB_MAPPER[currentTab]}
        items={tabItems}
        onChange={onChangeTab}
      />
    ),
  }));

  return (
    <div className="user__list">
      {!isOrganisationView && <HeaderComponent />}

      {isOrganisationView ? (
        <div className="user__pill-tabs">
          <PillTabs
            controlled={true}
            size="tiny"
            selectedTabKey={TAB_MAPPER[currentTab]}
            items={tabItems}
            onChange={onChangeTab}
          />
          {getPagination()}
        </div>
      ) : (
        <div className="user__list--rtabs">
          <Tabs
            controlled={true}
            items={typeItems}
            selectedTabKey={TYPE_MAPPER[currentType]}
            onChange={onChangeType}
          />
          {getPagination()}
        </div>
      )}
    </div>
  );
};

UsersList.defaultProps = {
  isOrganisationView: false,
  organizationId: null,
};

UsersList.propTypes = {
  isOrganisationView: PropTypes.bool,
  organizationId: PropTypes.number,
};

export default UsersList;
