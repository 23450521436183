import PropTypes from 'prop-types';
import { useGetAvailableLocalesQuery } from 'api/available-languages';
import Icon from 'components/base-components/Icon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { Menu, MenuItem } from '../base-components/Menu';

const LanguageComponent = ({ className }) => {
  const [isVisibleLanguagePopover, setIsVisibleLanguagePopover] = useState(false);
  const { data: availableLocales = [] } = useGetAvailableLocalesQuery();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');
  const { i18n } = useTranslation();

  const associatedLanguages = {
    de: 'de',
    'de-CH': 'de',
    'de-DE': 'de',
    en: 'en',
    'en-GB': 'en',
    'en-US': 'en',
    fr: 'fr',
    it: 'it',
  };

  const languageHumanName = {
    de: 'Deutsch (D)',
    en: 'English (E)',
    fr: 'Français (F)',
    it: 'Italiano (I)',
  };

  const locales = availableLocales.reduce((locales, locale) => {
    if (locale.length === 2) {
      locales.push(
        <MenuItem
          onClick={() => {
            onChangeLocale(locale);
            setIsVisibleLanguagePopover(false);
          }}
        >
          {languageHumanName[locale]}
        </MenuItem>,
      );
    }
    return locales;
  }, []);

  const onChangeLocale = locale => {
    if (currentTab === 'charta') {
      i18n.changeLanguage(locale).then(() => {
        i18n.changeLanguage(locale);
        window.location.reload();
      });
    } else {
      i18n.changeLanguage(locale);
    }
  };

  const savedLanguageCode = associatedLanguages[i18n.language];

  return (
    <Menu
      isVisible={isVisibleLanguagePopover}
      size="small"
      popOverDirection="bottom-left"
      onOutsideClick={() => {
        setIsVisibleLanguagePopover(false);
      }}
      label={
        <div className={classNames('flex items-center font-normal mr-2', className)}>
          <Icon className="mr-1" name="globeAlt" color="tertiary" size="medium" />
          <div className="text-base">{languageHumanName[savedLanguageCode]}</div>
          <div
            className="cursor-pointer"
            onClick={() => setIsVisibleLanguagePopover(prevState => !prevState)}
            role="button"
            tabIndex={0}
          >
            <Icon className="ml-4" name="iconDownChevron" size="tiny" color="tertiary" />
          </div>
        </div>
      }
    >
      {locales}
    </Menu>
  );
};

LanguageComponent.defaultProps = {
  className: null,
};

LanguageComponent.propTypes = {
  className: PropTypes.node,
};

export default LanguageComponent;
