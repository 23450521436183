import React from 'react';
import { t } from 'i18next';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import OverviewContent from './overview-content.component';

const OrganizationIDS = ({ organisation, isFetching, isAdmin }) => (
  <Card className="mb-3 sm:mb-4">
    {isFetching ? (
      <ContentLoaderPlaceholder numberOfLines={6} showBackground={false} />
    ) : (
      <>
        <span className="inline-block text-lg font-bold mb-3">
          {t('organisation_view.overview.attributes.ids.header')}
        </span>
        <div className="grid sm:grid-cols-3 gap-x-3 gap-y-0 capitalize">
          <OverviewContent
            className="col-span-1"
            label={t('organisation_view.overview.attributes.ids.bio_id')}
            value={organisation?.bioId}
          />
          <OverviewContent
            className="col-span-1"
            label={t('organisation_type.uid')}
            value={organisation?.uid}
          />
          <OverviewContent
            className="col-span-1"
            label={t('organisation_view.overview.attributes.ids.bur')}
          />
          <OverviewContent
            className="col-span-1"
            label={t('organisation_view.overview.attributes.ids.biobetriebsnummer')}
            noMargin={true}
            value={organisation?.numberAtCertificationBody}
          />
          <OverviewContent
            className="col-span-1"
            label={t('organisation_view.overview.attributes.ids.kt_id_b_1')}
            noMargin={true}
          />
          <OverviewContent
            className="col-span-1"
            label={t('organisation_view.overview.attributes.ids.kt_id_b_2')}
            noMargin={true}
          />
        </div>
      </>
    )}
  </Card>
);

OrganizationIDS.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default OrganizationIDS;
