import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDeleteImageMutation } from 'api/control-body-survey';
import Textarea from 'components/base-components/Textarea/Textarea';
import { t } from 'i18next';
import { Field, useFormState } from 'react-final-form';
import Input from 'components/base-components/Input';
import IconButton from 'components/base-components/IconButton';
import SelectBox from 'components/base-components/SelectBox';
import Icon from 'components/base-components/Icon';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import Card from 'components/base-components/Card';
import classNames from 'classnames';
import { monthYearShort } from 'utils/date-time-formatter';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import { useGetCustomVatPercentageReasonsQuery } from 'api/control-body-survey';
import numberFormatter from 'utils/number-formatter';
import { boolToYesNo } from 'utils/yes-no-helper';
import YesNoInput from 'components/base-components/YesNoInput';
import {
  DRAFT,
  isTemporaryOrPermanent,
  NO,
  PERMANENT,
  SAVED,
  SUBMITTED,
  TEMPORARY,
  YES,
} from 'utils/control-body-survey';
import AmountInput from 'components/control-body/bio-cuisine-assessment/checklist/entities/amount-input.component';
import RandomGoodsDeclarationInfo from '../../negative-reports/helpers/random-goods-declaration-info.component';

const VerifyCustomInput = ({
  validityPeriod,
  status,
  data,
  totalAmount,
  isCertifier,
  handleVerifyInputAmount,
  verifiyInputData,
}) => {
  return (
    <>
      <div className="organisation-checklist__quarter-goods">
        <div>
          {t('negative_report.random_goods.header')}{' '}
          <span className="organisation-checklist__quarter-goods--bold">{validityPeriod}</span>
        </div>
        <div className="organisation-checklist__quarter-goods--bold">{`${numberFormatter(
          totalAmount,
        )} CHF`}</div>
      </div>
      <AmountInput
        validFrom={monthYearShort(data?.periodFrom)}
        validUntil={monthYearShort(data?.periodUntil)}
        handleVerifyInputAmount={handleVerifyInputAmount}
        status={status}
        isCertifier={isCertifier}
        verifiyInputData={verifiyInputData}
      />
      <hr className="organisation-checklist__checkbox-horizontal-line" />
    </>
  );
};

VerifyCustomInput.propTypes = {
  validityPeriod: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  totalAmount: PropTypes.number.isRequired,
  isCertifier: PropTypes.bool.isRequired,
  handleVerifyInputAmount: PropTypes.func.isRequired,
  verifiyInputData: PropTypes.shape().isRequired,
};

const EvaluateGoodsDeclaration = ({
  goodsDeclarationEvaluation,
  isCertifier,
  status,
  errorMessage,
  setEvaluationPeriods,
  setVerifyInputDataLists,
  verifyInputDataLists,
}) => {
  const { data: customVatReasons = [] } = useGetCustomVatPercentageReasonsQuery();

  const customVatReasonsOptions = customVatReasons.map(reason => ({
    label: t(`control_body.survey.options.vat_percentage_reasons.${reason}`),
    value: reason,
  }));

  const handleVerifyInputAmount = input => {
    if (!input) return;
    setVerifyInputDataLists(prev => {
      const filteredList = prev.filter(list => list.id !== input.id);
      return [...filteredList, input];
    });
  };

  return (
    <Fragment>
      <div className="organisation-checklist__deviation-card-vat-percentage">
        <Field name="vat_percentage" initialValue={goodsDeclarationEvaluation?.vatPercentage}>
          {({ input, meta }) => (
            <Input
              size="tiny"
              type="number"
              label={t('control_body.survey.form.vat_percentage')}
              placeholder={t('control_body.survey.form.vat_percentage')}
              required={true}
              fullWidth={true}
              disabled={isCertifier || status === PERMANENT}
              touched={!meta.dirtySinceLastSubmit}
              error={errorMessage?.goodsDeclarationEvaluationVatPercentage}
              {...input}
            />
          )}
        </Field>
        <Field
          name="vat_percentage_reason"
          initialValue={goodsDeclarationEvaluation?.vatPercentageReason}
        >
          {({ input }) => (
            <SelectBox
              size="tiny"
              width="full"
              label={t('shared.reason')}
              isClearable={true}
              options={customVatReasonsOptions}
              value={customVatReasonsOptions.find(option => option.value === input.value)}
              isDisabled={isCertifier || status === PERMANENT}
              selectedValue={input.label}
              onChange={e => input.onChange(e.value)}
              errorMsg={errorMessage?.goodsDeclarationEvaluationVatPercentageReason}
            />
          )}
        </Field>
      </div>
      <Field
        name="comment_to_change_vat_percentage"
        initialValue={goodsDeclarationEvaluation?.commentToChangeVatPercentage}
      >
        {({ input, meta }) => (
          <Textarea
            className={'organisation-checklist__deviation-card-vat-percentage-comment'}
            label={t('control_body.survey.form.vat_percentage_comment')}
            placeholder={t('control_body.survey.form.comments_placeholder')}
            disabled={isCertifier || status === PERMANENT}
            touched={meta.touched}
            error={errorMessage?.goodsDeclarationEvaluationCommentToChangeVatPercentage}
            {...input}
          />
        )}
      </Field>
      {verifyInputDataLists.map(goods => (
        <RandomGoodsDeclarationInfo
          randomGoodsDeclaration={goods}
          setEvaluationPeriods={setEvaluationPeriods}
        >
          <VerifyCustomInput
            handleVerifyInputAmount={handleVerifyInputAmount}
            status={status}
            isCertifier={isCertifier}
            verifiyInputData={goods}
          />
        </RandomGoodsDeclarationInfo>
      ))}
    </Fragment>
  );
};

EvaluateGoodsDeclaration.propTypes = {
  goodsDeclarationEvaluation: PropTypes.shape().isRequired,
  errorMessage: PropTypes.shape().isRequired,
  setEvaluationPeriods: PropTypes.func.isRequired,
  setVerifyInputDataLists: PropTypes.func.isRequired,
  verifyInputDataLists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  status: PropTypes.string.isRequired,
  isCertifier: PropTypes.bool.isRequired,
};

const UploadDeliveryNotes = ({
  goodsDeclarationEvaluation,
  verifyInputDataLists,
  uploadedFiles,
  status,
  isCertifier,
  setUploadedFiles,
}) => {
  const [deleteImage] = useDeleteImageMutation();
  const maxDeclarationStatus = (() => {
    if (verifyInputDataLists.length === 0) {
      return null;
    }

    const STATUS_WEIGHT = { good: 1, average: 2, bad: 3 };
    let maxStatus = verifyInputDataLists[0].differenceStatus;

    verifyInputDataLists.forEach(input => {
      if ((STATUS_WEIGHT[input.differenceStatus] || 0) > (STATUS_WEIGHT[maxStatus] || 0)) {
        maxStatus = input.differenceStatus;
      }
    });
    return maxStatus;
  })();

  const totalUploaded = uploadedFiles.length;
  const totalImageUploaded = uploadedFiles.filter(file => file.type.startsWith('image/')).length;
  const totalPdfUploaded = uploadedFiles.filter(file => file.type === 'application/pdf').length;

  const hasAnyBadStatus = verifyInputDataLists.some(good => good.differenceStatus === 'bad');
  const hasAnyAvgStatus = verifyInputDataLists.some(good => good.differenceStatus === 'average');
  const hasAnyGoodStatus = verifyInputDataLists.some(good => good.differenceStatus === 'good');

  const hasAnyGoodsDeclaration = hasAnyBadStatus || hasAnyGoodStatus || hasAnyAvgStatus;

  const uploadCounts = { bad: 15, average: 10, good: 5 };
  const requiredImageCount = uploadCounts[maxDeclarationStatus] || 0;

  const isUploadFileDisabled = () => {
    if (totalPdfUploaded > 0 || status === TEMPORARY) return true;

    return totalImageUploaded >= requiredImageCount;
  };

  const isPdfUploadDisabled = totalImageUploaded || totalPdfUploaded === 1;

  const requiredDeliveryNotesCount =
    (totalImageUploaded > 0 && requiredImageCount) || (totalPdfUploaded > 0 && 1) || 0;

  const handleFileRange = `${totalUploaded}/${requiredDeliveryNotesCount}`;

  const getUploadedFileType =
    (totalImageUploaded > 0 && 'Photos') || (totalPdfUploaded > 0 && 'Pdf');

  const onUploadFile = file => {
    const url = URL.createObjectURL(file);
    setUploadedFiles(prev => [
      ...prev,
      {
        file,
        id: new Date().getTime(),
        filename: file.name,
        sizeInByte: file.size,
        type: file.type,
        url,
      },
    ]);
  };

  const handleImageDelete = note => {
    if (note.file) {
      const filteredFiles = uploadedFiles.filter(file => file.id !== note.id);

      setUploadedFiles(filteredFiles);
      return;
    }
    deleteImage({
      attachmentId: note.id,
    });
  };

  return (
    <>
      <div className="organisation-checklist__category">
        {hasAnyGoodsDeclaration && (
          <>
            <div>
              {t('control_body.survey.form.delivery_list_items', {
                count: requiredImageCount,
              })}
            </div>
            <div className="organisation-checklist__category-description">
              {t('control_body.survey.form.recorded_values')}
            </div>
            <ul>
              <li>{t('control_body.survey.form.organisation.list_item1')}</li>
              <li>{t('control_body.survey.form.organisation.list_item2')}</li>
              <li>{t('control_body.survey.form.organisation.list_item3')}</li>
              {hasAnyBadStatus && (
                <>
                  <li> {t('control_body.survey.form.organisation.list_item4')}</li>
                  <li>{t('control_body.survey.form.organisation.list_item5')}</li>
                </>
              )}
            </ul>
          </>
        )}

        {hasAnyGoodsDeclaration && (
          <div className="organisation-checklist__upload-wrapper">
            <div>
              <div className="organisation-checklist__upload-wrapper--header">
                {t('control_body.survey.form.uploaded_files')}
              </div>
              <div>
                {t('control_body.survey.form.uploaded_files_subtext', {
                  count: requiredImageCount,
                })}
              </div>
            </div>

            <div className="organisation-checklist__upload-btn">
              {getUploadedFileType && (
                <div className="organisation-checklist__upload-btn--count">
                  {handleFileRange} {getUploadedFileType} {t('control_body.survey.form.uploaded')}
                </div>
              )}
              {!isCertifier && (
                <div className="organisation-checklist__delivery-notes-wrapper">
                  <ChooseFile
                    showText={false}
                    disabled={isUploadFileDisabled()}
                    acceptedType="image/*"
                    onChange={file => onUploadFile(file)}
                    labelText={
                      <>
                        <Icon
                          className="choose-file__contract-delete--icon"
                          name="uploadFile"
                          size="tiny"
                        />
                        <span className="choose-file__contract-delete--text">
                          {t('control_body.survey.form.upload_photo')}
                        </span>
                      </>
                    }
                  />
                  <ChooseFile
                    showText={false}
                    disabled={isPdfUploadDisabled}
                    onChange={file => {
                      onUploadFile(file);
                    }}
                    acceptedType={'application/pdf'}
                    labelText={
                      <>
                        <Icon
                          className="choose-file__contract-delete--icon"
                          name="uploadFile"
                          size="tiny"
                        />
                        <span className="choose-file__contract-delete--text">
                          {t('control_body.survey.form.upload_pdf')}
                        </span>
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames({
            'organisation-checklist__upload-list--wrapper': hasAnyBadStatus || hasAnyAvgStatus,
          })}
        >
          {uploadedFiles.map(note => (
            <div
              className={classNames({
                'col-span-6': hasAnyBadStatus || hasAnyAvgStatus,
              })}
            >
              <Card className="organisation-checklist__upload-list--card">
                <div className="organisation-checklist__upload-list">
                  <div className="organisation-checklist__upload-list--name">
                    <Icon name="photo" color="tertiary" size="normal" />
                    <span className="organisation-checklist__upload-list--name__truncate">
                      {note.filename}
                    </span>
                  </div>
                  <div>{bytesToMegabytes(note.sizeInByte)} MB</div>
                  <div className="organisation-checklist__audio-card--icon">
                    <a href={note?.url} target="_blank" rel="noreferrer">
                      <IconButton
                        className="topnav__back-button"
                        icon={
                          <Icon
                            className="choose-file__contract-delete--icon"
                            name="downloadFile"
                            size="normal"
                          />
                        }
                      />
                    </a>
                    <IconButton
                      className="topnav__back-button"
                      color="danger"
                      icon={
                        <Icon
                          className="choose-file__contract-delete--icon"
                          name="delete"
                          size="normal"
                          color="danger"
                        />
                      }
                      disabled={isCertifier || status === PERMANENT}
                      onClick={() => handleImageDelete(note)}
                    />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
        <hr className="organisation-checklist__checkbox-horizontal-line organisation-checklist__checkbox-horizontal-line--upload" />
        {hasAnyGoodsDeclaration && (
          <Field
            name="delivery_notes_comment"
            initialValue={goodsDeclarationEvaluation?.deliveryNotesComment || ''}
          >
            {({ input }) => (
              <Textarea
                className="organisation-checklist__quarter-comment"
                label={
                  isCertifier
                    ? t('control_body.survey.form.auditor_comments')
                    : t('control_body.survey.form.comments')
                }
                placeholder={t('control_body.survey.form.comments_placeholder')}
                disabled={isCertifier || status === TEMPORARY}
                {...input}
              />
            )}
          </Field>
        )}
      </div>
    </>
  );
};

UploadDeliveryNotes.propTypes = {
  goodsDeclarationEvaluation: PropTypes.shape().isRequired,
  verifyInputDataLists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  status: PropTypes.string.isRequired,
  isCertifier: PropTypes.bool.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
};

const RandomDeclarationComponent = ({
  goodsDeclarationEvaluation,
  uploadedFiles,
  errorMessage,
  isAuditor,
  isCertifier,
  randomDeclarationsComment,
  setUploadedFiles,
  status,
}) => {
  const [verifyInputDataLists, setVerifyInputDataLists] = useState([]);
  const [evaluationPeriods, setEvaluationPeriods] = useState([]);
  const formState = useFormState();
  const hasAnyGoodsDeclaration = setVerifyInputDataLists.length;
  const auditorAcknowledgement = boolToYesNo(goodsDeclarationEvaluation?.auditorAcknowledgement);
  const certifierResponseChanged =
    goodsDeclarationEvaluation.auditorAcknowledgement !==
    goodsDeclarationEvaluation.certifierAcknowledgement;
  const errorMessageToShow =
    errorMessage &&
    (errorMessage['goodsDeclarationEvaluation'] ||
      errorMessage['deliveryNotes'] ||
      errorMessage['goodsDeclarationEvaluationDeliveryNotes']);

  useEffect(() => {
    setVerifyInputDataLists(goodsDeclarationEvaluation?.randomGoodsDeclarations || []);
    setUploadedFiles(goodsDeclarationEvaluation?.deliveryNotes || []);
  }, [JSON.stringify(goodsDeclarationEvaluation)]);

  return (
    <>
      <div className="organisation-checklist__quarter-text--wrapper">
        <div className="organisation-checklist__quarter-text">
          <span className="organisation-checklist__number-bold">5 .</span>{' '}
          <span>{t('control_body.survey.form.fifth_step')}</span>
          <span className="organisation-checklist__quarter-text--value">
            {evaluationPeriods.join(', ')}
          </span>
        </div>
        {verifyInputDataLists.length === 0 && (
          <div className="organisation-checklist__quarter-text--no-data">
            <span className="organisation-checklist__deviation-legend--status-disabled">
              {t('common.no_data_available')}
            </span>
          </div>
        )}
      </div>
      <div className="organisation-checklist__quarter-text--wrapper">
        <span className="organisation-checklist__quarter-text--description">
          {t('control_body.survey.form.fifth_step_details')}
        </span>
      </div>
      <Card className="organisation-checklist__deviation-card">
        {verifyInputDataLists.length > 0 ? (
          <EvaluateGoodsDeclaration
            goodsDeclarationEvaluation={goodsDeclarationEvaluation}
            isCertifier={isCertifier}
            status={status}
            errorMessage={errorMessage}
            setEvaluationPeriods={setEvaluationPeriods}
            verifyInputDataLists={verifyInputDataLists}
            setVerifyInputDataLists={setVerifyInputDataLists}
          />
        ) : (
          <span className="organisation-checklist__deviation-legend--status-disabled">
            {t('common.no_data_available')}
          </span>
        )}
        <Field name="random_declarations_comment" initialValue={randomDeclarationsComment || ''}>
          {({ input }) => (
            <Textarea
              className={classNames({
                'organisation-checklist__quarter-comment': goodsDeclarationEvaluation,
              })}
              label={
                isCertifier
                  ? t('control_body.survey.form.auditor_comments')
                  : t('control_body.survey.form.comments')
              }
              placeholder={t('control_body.survey.form.comments_placeholder')}
              disabled={isCertifier || isTemporaryOrPermanent(status)}
              {...input}
            />
          )}
        </Field>
        <UploadDeliveryNotes
          goodsDeclarationEvaluation={goodsDeclarationEvaluation}
          verifyInputDataLists={verifyInputDataLists}
          uploadedFiles={uploadedFiles}
          status={status}
          isCertifier={isCertifier}
          setUploadedFiles={setUploadedFiles}
        />
        {hasAnyGoodsDeclaration && (
          <>
            {isAuditor ? (
              <div className="organisation-checklist__auditor-acknowledgement">
                <Field name="auditorAcknowledgement" initialValue={auditorAcknowledgement}>
                  {({ input, meta }) => (
                    <YesNoInput
                      {...input}
                      error={meta.submitError}
                      label={t('control_body.survey.form.auditor_acknowledgement')}
                      disabled={![DRAFT, SAVED].includes(status)}
                    />
                  )}
                </Field>
              </div>
            ) : (
              <div className="organisation-checklist__auditor-acknowledgement organisation-checklist__certifier-acknowledgement">
                <Field
                  name="certifierAcknowledgement"
                  initialValue={boolToYesNo(goodsDeclarationEvaluation.certifierAcknowledgement)}
                >
                  {({ input, meta }) => (
                    <YesNoInput
                      {...input}
                      error={meta.submitError}
                      label={t('control_body.survey.form.auditor_acknowledgement')}
                      disabled={status !== SUBMITTED}
                    />
                  )}
                </Field>
              </div>
            )}
          </>
        )}
        {isCertifier && (
          <div className="organisation-checklist__certifier-audio">
            <Field
              name="certifierComment"
              initialValue={goodsDeclarationEvaluation.certifierComment || ''}
            >
              {({ input }) =>
                formState.values.certifierAcknowledgement !== null &&
                formState.values.certifierAcknowledgement !==
                  boolToYesNo(goodsDeclarationEvaluation.auditorAcknowledgement) && (
                  <Textarea
                    className="organisation-checklist__certifier-audio--field"
                    label={t('control_body.survey.form.reason_of_change')}
                    placeholder={t('control_body.survey.form.comments_placeholder')}
                    {...input}
                    disabled={status === TEMPORARY}
                  />
                )
              }
            </Field>
          </div>
        )}
        {status === TEMPORARY && (
          <div className="organisation-checklist__auditor-acknowledgement">
            <YesNoInput
              value={certifierResponseChanged ? YES : NO}
              label={t('control_body.survey.form.pass_random')}
              disabled={true}
            />
          </div>
        )}
        {errorMessageToShow && (
          <div className="organisation-checklist__errors">
            <Icon name="invalid" color="danger" size="small" />
            <span className="organisation-checklist__errors-text">{errorMessageToShow}</span>
          </div>
        )}
      </Card>
    </>
  );
};

RandomDeclarationComponent.defaultProps = {
  goodsDeclarationEvaluation: {},
  uploadedFiles: [],
  errorMessage: {},
  isAuditor: false,
  isCertifier: false,
  randomDeclarationsComment: null,
  status: null,
};

RandomDeclarationComponent.propTypes = {
  goodsDeclarationEvaluation: PropTypes.shape(),
  errorMessage: PropTypes.shape(),
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape()),
  isAuditor: PropTypes.bool,
  isCertifier: PropTypes.bool,
  randomDeclarationsComment: PropTypes.string,
  status: PropTypes.string,
  setUploadedFiles: PropTypes.func.isRequired,
};

export default RandomDeclarationComponent;
