import { useGetProductionByYearQuery } from 'api/producers/productions';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { seasonalDatas } from 'utils/seasonal-data';

const SeasonalDataComponent = ({ title, className, year, season }) => {
  const { id } = useParams();
  const navigationLinks = useNavigationLinks();

  const { data: seasonalData = {} } = useGetProductionByYearQuery({
    organisationId: id,
    year,
  });

  const productTypes = seasonalData.productTypes || [];

  const currentYear = new Date().getFullYear();

  if (productTypes?.length === 0) {
    return (
      <div
        className={classNames('font-bold text-lg [&_.card]:min-h-[266px]', {
          'mt-4': year < currentYear,
        })}
      >
        {title}
        <EmptyContentPlaceholder iconName="plant_little" text={t('common.no_data_available')} />
      </div>
    );
  }

  return (
    <div className={classNames('font-bold text-lg', className)}>
      {title}
      <div className="mt-4 grid grid-cols-2 gap-3 pt-1">
        {productTypes.map(product => (
          <Card
            className="flex items-center col-span-1 p-2 h-[60px] rounded border border-solid border-blueish-black-16 shadow-none"
            key={product}
          >
            <div className="flex items-center gap-2 text-sm sm:text-base">
              <Icon name={seasonalDatas[product].icon} size="medium" />
              <span className="font-bold">{seasonalDatas[product].name}</span>
            </div>
          </Card>
        ))}
      </div>
      <div className="mt-4 text-info-dark text-base font-semibold">
        <Link to={navigationLinks.organisationOperationPage(id, season)}>
          {t('producer.dashboard.view_seasonal_data')}
        </Link>
      </div>
    </div>
  );
};

SeasonalDataComponent.defaultProps = {
  productTypes: [],
  title: null,
  className: null,
  year: null,
};

SeasonalDataComponent.propTypes = {
  productTypes: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  className: PropTypes.node,
  year: PropTypes.node,
  season: PropTypes.string.isRequired,
};

export default SeasonalDataComponent;
