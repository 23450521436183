import React from 'react';
import PropTypes from 'prop-types';
import LanguageComponent from 'components/shared-components/language';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import { Header } from '../base-components/Typography';
import LoginImage from '../../assets/bio_image.png';
import LoginLogo from '../../assets/bio_logo.png';
import './welcome-page.styles.scss';

const WelcomePageComponent = ({ children, isForgotPassword, onlyChildren, showLogoOnly }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsive();

  return (
    <>
      <div className="absolute top-3 right-3">
        <LanguageComponent />
      </div>
      <div
        className={classNames('flex min-h-[calc(100vh-60px)]', {
          'h-full': showLogoOnly,
        })}
      >
        {!isMobile && !isTablet && (
          <div className="relative hidden md:block w-[450px]">
            <img
              className="absolute left-[-70px] top-[-60px] h-[100vh] "
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
        )}
        {showLogoOnly ? (
          <div className="flex basis-0 grow justify-center self-center px-4">{children}</div>
        ) : (
          <div className="flex basis-0 grow self-center justify-center px-4 welcome-page__form md:relative md:left-[-30px]">
            <div className="welcome-page__form--content mb-[80px] md:mb-8">
              {!onlyChildren && (
                <>
                  <img src={LoginLogo} alt="BioSuisseLogo" />
                  <Header
                    className="text-primary-text mt-6 text-center md:mt-[60px] md:mb-[20px]"
                    weight="boldest"
                    level={3}
                  >
                    {isForgotPassword
                      ? t('forgot_password.title_message')
                      : t('welcome_page.welcome_message')}
                  </Header>
                </>
              )}
              {children}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

WelcomePageComponent.defaultProps = {
  children: null,
  isForgotPassword: false,
  onlyChildren: false,
  showLogoOnly: false,
};

WelcomePageComponent.propTypes = {
  children: PropTypes.node,
  isForgotPassword: PropTypes.bool,
  onlyChildren: PropTypes.bool,
  showLogoOnly: PropTypes.bool,
};

export default WelcomePageComponent;
