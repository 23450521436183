import {
  useGetInvoiceJobQuery,
  useGetInvoicesQuery,
  useRejectInvoiceMutation,
  useReleaseInvoiceMutation,
} from 'api/invoices';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Input from 'components/base-components/Input';
import Pagination from 'components/base-components/Pagination/Pagination';
import SidePanel from 'components/base-components/SidePanel/SidePanel';
import ReleaseInvoiceForm from 'components/invoices/Form/release/index.component';
import InvoiceFilterModal from 'components/invoices/filter/invoice-filter-modal';
import { GENERATED, SCHEDULED } from 'components/invoices/helpers/invoice-tag-types';
import InvoiceJobTag from 'components/invoices/shared/invoice-job-tag';
import { CANCELLED, DRAFT, FAILED, OPEN, PAID } from 'utils/invoice-statuses';
import InvoiceTable from 'components/invoices/shared/table.component';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { dateMonthYear, dateTimeFormatterWithAmPm } from 'utils/date-time-formatter';

const InvoiceListComponent = ({ enableBulkAction, organisationId }) => {
  const { id } = useParams();
  const [searchInput, setSearchInput] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [enableSidePannel, setEnableSidePanel] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
    invoiceStart: '',
    invoiceEnd: '',
    dueDateStart: '',
    dueDateEnd: '',
    statuses: [],
    types: [],
    organizationIds: [],
    id: [],
    industries: [],
  });

  const currentTab = searchParams.get('tab');
  const jobId = searchParams.get('jobIds');
  const creationDate = searchParams.get('date');
  const industries = searchParams.get('industries');
  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');
  const invoiceStart = searchParams.get('invoiceStart');
  const invoiceEnd = searchParams.get('invoiceEnd');
  const dueDateStart = searchParams.get('dueDateStart');
  const dueDateEnd = searchParams.get('dueDateEnd');
  const statuses = (currentTab && [currentTab]) || searchParams.getAll('statuses');
  let organizationIds = searchParams.getAll('organizationIds');
  const types = searchParams.getAll('types');
  const currentDirection = searchParams.get('direction');
  const currentColumn = searchParams.get('column');
  const [rejectInvoice, { isLoading, isSuccess, requestId }] = useRejectInvoiceMutation();

  const navigationLinks = useNavigationLinks();

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  const invoiceParams = {
    'organization_ids[]': id ? [id] : organizationIds,
    q: searchQuery,
    page: currentPage,
    'job_ids[]': jobId !== 'null' ? [jobId] : '',
    'invoice_date[start_date]': invoiceStart,
    'invoice_date[end_date]': invoiceEnd,
    'due_date[start_date]': dueDateStart,
    'due_date[end_date]': dueDateEnd,
    creation_date: creationDate,
    'industries[]': industries ? [industries] : [],
    'statuses[]': statuses,
    'types[]': currentTab ? types : [],
    direction: currentDirection,
    column: currentColumn,
  };

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  const setSearchQueryParams = useCallback(params => {
    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    setSearchParams(searchParams);
  }, []);

  const setFilterQueryParams = params => {
    const validParams = { section: 'invoices', tab: currentTab };

    if (organisationId) {
      delete validParams['tab'];
    }

    Object.entries(params).forEach(([key, value]) =>
      value ? (validParams[key] = params[key]) : searchParams.delete(key),
    );

    setSearchParams(validParams);
  };

  const setSearchQueryInParams = debounce(500, value => {
    setSearchQueryParams({ q: value, tab: currentTab, page: 1 });
  });

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  const onRejectInvoices = () => {
    rejectInvoice({
      invoiceId: jobId,
      payload: {
        id: jobId,
      },
    })
      .unwrap()
      .then(() => setIsDeleteModalOpen(false));
  };

  const { data: invoiceCollection, isFetching } = useGetInvoicesQuery(invoiceParams);

  const invoices = invoiceCollection?.collection || [];

  const { data: invoiceJob = {} } = useGetInvoiceJobQuery(
    {
      invoiceJobId: jobId,
    },
    { skip: id || jobId === 'null' },
  );

  const totalInvoiceCount = invoiceCollection?.pagination.totalCount;

  const isActionsEnabled =
    !currentTab && invoiceJob.totalInvoiceCount > 0 && invoiceJob.status === GENERATED;

  const renderInvoiceCountHeader = status => {
    const statusCount = invoiceJob[`${status}InvoiceCount`];
    const isActiveTab = currentTab === status;
    const displayCount = statusCount ? statusCount : isActiveTab ? totalInvoiceCount : '0';

    return (
      <div key={status}>
        {t(`invoice.tag_status.${status}`)} - {displayCount}
      </div>
    );
  };

  const generateCreationDate = () => {
    if (invoiceJob.createdAt) {
      return dateMonthYear(invoiceJob.createdAt);
    }

    if (creationDate) {
      return dateMonthYear(creationDate);
    }

    return t('common.not_applicable');
  };

  return (
    <>
      {!organisationId && (
        <div className="invoice__list--top">
          <div>
            <div className="invoice__list--header">
              <div className="invoice__list--title">
                {t('invoice.release.invoice_job', {
                  id: jobId !== 'null' ? jobId : t('common.not_applicable'),
                })}
              </div>
              {invoiceJob.status && (
                <InvoiceJobTag
                  status={invoiceJob.status}
                  releaseStatus={invoiceJob.releaseStatus}
                />
              )}
            </div>

            <div className="invoice__list--content">
              <div className="invoice__list--date">
                {t('invoice.release.creation_date', {
                  date: generateCreationDate(),
                })}
              </div>
              {invoiceJob.status === SCHEDULED &&
                moment().isSameOrBefore(moment(invoiceJob.scheduledFor)) && (
                  <>
                    <div className="organisation-details__head--title__id-vertical-line" />
                    <div style={{ display: 'inline-block' }}>
                      {t('invoice.invoice_job.scheduled_at')}:{' '}
                      {dateTimeFormatterWithAmPm(invoiceJob.scheduledFor)}
                    </div>
                  </>
                )}
            </div>

            <div className="invoice__list--content invoice__list--content_divider">
              <div>
                {t('invoice.header_title')} -{' '}
                {invoiceJob.totalInvoiceCount || totalInvoiceCount || '0'}
              </div>
              {[DRAFT, OPEN, PAID, CANCELLED, FAILED].map(status =>
                renderInvoiceCountHeader(status),
              )}
            </div>
          </div>
          <div className="organisation-details__head--title__vertical-line" />
          <Link to={navigationLinks.invoiceJobListPage()}>
            <IconButton
              className="organisation__head-popover--go-back"
              icon={<Icon name="goBack" size="small" />}
              color="default"
              size="medium"
              isIconOnly={true}
            />
          </Link>
        </div>
      )}
      <div className="invoice__list-page">
        <div className="invoice__header--wrapper">
          <div className="user__list--header">
            <div className="organisation__search-tab">
              <Input
                className="organisation__search"
                placeholder={t('invoice.search_invoice')}
                preIcon={<Icon name="search" />}
                size="tiny"
                value={searchInput}
                onChange={onSearch}
              />
              <Button
                onClick={() => setIsFilterVisible(true)}
                label={
                  <span className="organisation__filter-button">
                    <Icon name="filter" />
                    <span>{t('shared.action.filter')}</span>
                  </span>
                }
                size="small"
              />
            </div>
          </div>
          {organisationId ? (
            <Link
              type="link"
              to={
                id
                  ? navigationLinks.OrganisationInvoiceCreatePage(id)
                  : navigationLinks.invoiceCreatePage()
              }
            >
              <Button type="success" size="small" label={t('invoice.create_new_invoice')} />
            </Link>
          ) : (
            <div className="invoice__create-button">
              <Button
                className="invoice__reject-button"
                type="default"
                size="small"
                label={t('invoice.release.reject_invoices')}
                disabled={!isActionsEnabled}
                onClick={() => setIsDeleteModalOpen(true)}
              />
              <Button
                type="success"
                size="small"
                label={t('invoice.release.release_invoices')}
                disabled={!isActionsEnabled}
                onClick={() => setEnableSidePanel(true)}
              />
            </div>
          )}
        </div>
        <InvoiceTable
          organisationId={id}
          invoices={invoices}
          isFetching={isFetching}
          totalInvoiceCount={totalInvoiceCount}
          enableBulkAction={enableBulkAction}
          statuses={statuses}
        />
        <div className="organisation__pagination invoice__pagination">
          <Pagination
            onPaginationClick={newPage => {
              setSearchQueryParams({
                page: newPage,
                direction: currentDirection,
                column: currentColumn,
              });
            }}
            totalPages={invoiceCollection && parseInt(invoiceCollection.pagination.totalPages, 10)}
            currentPage={currentPage}
            firstText={t('pagination.first')}
            lastText={t('pagination.last')}
            nextText={t('pagination.next')}
            previousText={t('pagination.prev')}
          />
        </div>
        <InvoiceFilterModal
          setFilterQueryParams={setFilterQueryParams}
          isFilterVisible={isFilterVisible}
          setIsFilterVisible={setIsFilterVisible}
        />
        <SidePanel
          title={t('invoice.release.release_invoice')}
          closeIcon={<Icon name="close" />}
          body={
            <ReleaseInvoiceForm
              setEnableSidePanel={setEnableSidePanel}
              callbackMutation={useReleaseInvoiceMutation}
              entityId={jobId}
              releaseInvoiceJob={true}
              scheduledFor={invoiceJob?.scheduledFor}
            />
          }
          isOpened={enableSidePannel}
          onClose={() => setEnableSidePanel(false)}
        />
        <WarningModal
          onOutsideClick={() => setIsDeleteModalOpen(false)}
          isVisible={isDeleteModalOpen}
          title={t('invoice.reject_modal.warning_title')}
          content={t('invoice.reject_modal.warning_description', {
            count: invoiceJob.totalInvoiceCount,
          })}
          confirmActionText={t('shared.action.yes')}
          cancelActionText={t('shared.action.no')}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={onRejectInvoices}
        />
        <SubmitModal
          isLoading={isLoading}
          isSuccess={isSuccess}
          requestId={requestId}
          successTitle={t('invoice.reject_modal.title')}
          successContent={t('invoice.reject_modal.title')}
          errorTitle={t('invoice.reject_modal.error_title')}
          errorContent={t('invoice.reject_modal.error_description')}
          showError={true}
          showLoader={true}
        />
      </div>
    </>
  );
};

InvoiceListComponent.defaultProps = {
  currentTab: null,
  organisationId: null,
  enableBulkAction: false,
};

InvoiceListComponent.propTypes = {
  currentTab: PropTypes.string,
  organisationId: PropTypes.node,
  enableBulkAction: PropTypes.bool,
};

export default InvoiceListComponent;
