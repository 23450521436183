import React from 'react';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { useGetUserQuery } from 'api/users';
import { useGetOrganizationQuery, useGetOrganizationAssignmentsQuery } from 'api/organizations';
import { OPERATION_MANAGER, MAIN_CONTACT } from 'utils/users-role';
import { Link, useParams } from 'react-router-dom';
import { getCertificationStatus } from 'utils/get-certification-status';
import { t } from 'i18next';
import Card from 'components/base-components/Card';
import UserInfo from 'components/users/user-info.component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import PropTypes from 'prop-types';

const OverviewComponent = () => {
  const { id } = useParams();
  const navigationLinks = useNavigationLinks();
  const { data: organisation } = useGetOrganizationQuery(id);

  const { data: assignments = [] } = useGetOrganizationAssignmentsQuery({
    organizationId: id,
    'roles[]': [OPERATION_MANAGER, MAIN_CONTACT],
  });

  const operationManagers = assignments.filter(assignment => assignment.role === OPERATION_MANAGER);

  const mainContactAssignment = assignments.find(assignment => assignment.role === MAIN_CONTACT);

  const { data: mainContact } = useGetUserQuery(
    { id: mainContactAssignment?.userId },
    { skip: !mainContactAssignment?.userId },
  );
  const { data: contolBodyOrganisation } = useGetOrganizationQuery(
    organisation?.controlBodyOrganizationId,
    {
      skip: !organisation?.controlBodyOrganizationId,
    },
  );

  const producerStatusTagColor = {
    u1: 'accent',
    u2: 'accent',
    u3: 'accent',
    vk: 'success',
  };

  const organisationStatusTagColor = {
    active: 'success',
    inactive: 'danger',
    aborted: 'danger',
    draft: 'accent',
    registered: 'accent',
    interested: 'warning',
    undefined: 'warning',
  };

  return (
    <Card className="rounded-lg">
      <div className="font-bold text-lg mb-4">{t('organisation.details.tabs.overview')}</div>
      <div className="flex-space-between">
        <div className="flex flex-col gap-2">
          <span className="font-bold">{t('producer.dashboard.bio_suisse_status')}</span>
          <EmphasisTag
            type={producerStatusTagColor[organisation?.bioSuisseStatus]}
            text={organisation?.bioSuisseStatus?.toUpperCase()}
            size="medium"
          />
        </div>
        <div className="bg-blueish-black-16 h-[42px] w-[1px]" />
        <div className="flex flex-col gap-2 text-right">
          <span className="font-bold">{t('producer.dashboard.certification_status')}</span>
          {getCertificationStatus(organisation?.certificationStatus) ? (
            <EmphasisTag
              type={producerStatusTagColor[organisation?.certificationStatus]}
              text={organisation?.certificationStatus?.toUpperCase()}
              size="medium"
            />
          ) : (
            <span>{t('common.not_applicable')}</span>
          )}
        </div>
      </div>
      <div className="flex justify-between my-3">
        <span className="font-bold">{t('producer.dashboard.organisation_status')}</span>
        <EmphasisTag
          type={organisationStatusTagColor[organisation?.status]}
          text={organisation?.status}
          size="medium"
        />
      </div>
      <div className="flex justify-between">
        <span className="font-bold">{t('producer.dashboard.contract_status')}</span>
        {organisation?.contractStatus ? (
          <span>{t(`contract.attributes.${organisation?.contractStatus}`)}</span>
        ) : (
          <span className="text-disabled">{t('common.not_applicable')}</span>
        )}
      </div>
      <hr className="border-horizontal-line my-4" />
      <div>
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <span className="font-bold">{t('producer.dashboard.debit_number')}</span>
            <span>{organisation?.numberAtCertificationBody || t('common.not_applicable')}</span>
          </div>
          <div className="flex flex-col gap-2 text-right">
            <span className="font-bold">{t('producer.dashboard.maf')}</span>
            <span>{t('producer.dashboard.disabled')}</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <span className="font-bold">{t('organisation_overview.control_body')}</span>
          <span>
            {contolBodyOrganisation?.name || (
              <span className="text-disabled">{t('common.not_applicable')}</span>
            )}
          </span>
        </div>
        <hr className="border-horizontal-line my-4" />
        <div className="flex flex-col gap-2 mt-3">
          <span className="font-bold">{t('legal_form.contract_person.title')}</span>
          <span className="flex items-center">
            {mainContact ? (
              <UserInfo userData={mainContact} size="tiny" />
            ) : (
              <span className="text-disabled">{t('common.not_applicable')}</span>
            )}
          </span>
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <span className="font-bold">{t('producer.dashboard.operation_manager')}</span>
          <span className="flex flex-col space-y-2">
            {operationManagers.map(manager => (
              <div key={manager.userId}>
                <UserInfo userId={manager.userId} size="tiny" />
              </div>
            ))}
          </span>
        </div>
      </div>
      <div className="mt-4 font-semibold">
        <Link to={navigationLinks.organisationUserPage(id)}>
          {t('producer.dashboard.view_users')}
        </Link>
      </div>
    </Card>
  );
};

OverviewComponent.defaultProps = {
  operationManagers: [],
};

OverviewComponent.propTypes = {
  operationManagers: PropTypes.arrayOf(PropTypes.shape()),
};

export default OverviewComponent;
