import React from 'react';
import { useLogInMutation } from 'api/session/index';
import { useVerifyTwoFactorAuthenticationMutation } from 'api/two-factor-authentication';
import Alert from 'components/base-components/Alert';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { DEACTIVATED } from 'utils/statuses';
import Button from '../base-components/Button';
import Input from '../base-components/Input';
import VerifyOtp from './verify-otp.component';

export default function LoginComponent() {
  const [userLogIn, { isError, error: { data: errorResponse } = {} }] = useLogInMutation();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();
  const [searchParams] = useSearchParams();
  const authError = searchParams.get('error');

  const onUserLogIn = values => {
    userLogIn(values)
      .unwrap()
      .then(() => {
        navigate.openRootPage();
      })
      .catch(() => {});
  };

  const loginError = errorResponse?.invalidCredential || errorResponse?.error;

  if (errorResponse?.enforce2Fa) {
    return navigate.openEnforce2FAPage();
  }

  if (errorResponse?.twoFactorEnabled) {
    return (
      <WelcomePageComponent>
        <VerifyOtp
          onSuccess={navigate.openRootPage}
          verifyMethod={useVerifyTwoFactorAuthenticationMutation}
        />
      </WelcomePageComponent>
    );
  }

  return (
    <WelcomePageComponent>
      {isError && loginError ? (
        <Alert
          isVisible={isError}
          hideClose={true}
          type="danger"
          isIconVisible={false}
          className="min-w-[345px] p-[10px]"
        >
          {loginError}
        </Alert>
      ) : (
        authError && (
          <div>
            <Alert
              isVisible={!!authError}
              hideClose={true}
              type="warning"
              isIconVisible={false}
              className="min-w-[345px] p-[10px]"
            >
              {authError === DEACTIVATED
                ? t('shared.errors.deactivated')
                : t('shared.errors.unauthenticated')}
            </Alert>
          </div>
        )
      )}
      <Form
        onSubmit={onUserLogIn}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email">
              {({ input }) => (
                <Input
                  className="mt-6 w-[345px]"
                  label={t('login.email_label')}
                  placeholder={t('login.email_placeholder')}
                  touched={true}
                  error={errorResponse?.errors?.email}
                  {...input}
                />
              )}
            </Field>
            <Field name="password">
              {({ input }) => (
                <Input
                  className="mt-4 w-[345px]"
                  type="password"
                  label={
                    <div className="flex justify-between w-[345px]">
                      <span>{t('login.password_label')}</span>
                      <Link
                        className="text-normal-red cursor-pointer"
                        to={navigationLinks.forgotPasswordPage()}
                      >
                        {t('login.forgot_password')}
                      </Link>
                    </div>
                  }
                  placeholder={t('login.password_placeholder')}
                  touched={true}
                  error={errorResponse?.errors?.password}
                  data-testid="user-password"
                  {...input}
                />
              )}
            </Field>
            <Button
              label={t('login.sign_in_button')}
              type="success"
              size="normal"
              className="rounded mt-[46px] w-[345px]"
              submitType="submit"
            />
          </form>
        )}
      />
    </WelcomePageComponent>
  );
}
