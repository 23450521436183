import { useGetActiveLogoAttachmentsAdditionalTaskQuery } from 'api/control-body-survey/logo-attachments';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import bytesToMegabytes from 'utils/bytes-to-megabytes';

const NegativeUploadedLogoAttachments = ({ surveyId }) => {
  const { data = {} } = useGetActiveLogoAttachmentsAdditionalTaskQuery({ surveyId });
  const logos = data.logos || [];

  if (logos?.length === 0) return null;

  return (
    <div className="col-span-12 ">
      <div className="logo-attachment-task">
        <div className="logo-attachment-task__header">
          <div className="logo-attachment-task__header--title">
            {t('control_body.survey.form.uploaded_files_followup')}
          </div>
          <div className="logo-attachment-task__header--files">
            {logos.length}/{logos.length} {t('negative_report.logo.uploaded')}
          </div>
        </div>
      </div>
      <div>
        {logos.map(logo => (
          <div key={logo.id} className="col-span-6  logo-attachment-task__file-dynamic-margin">
            <div className="logo-attachment-task__file-wrapper">
              <div className="logo-attachment-task__file-info">
                <Icon name="photo" color="tertiary" />
                <span className="logo-attachment-task__file-info--name">{logo?.filename}</span>
              </div>

              <div className="organisation-checklist__audio-card--icon">
                <span>{bytesToMegabytes(logo?.sizeInByte)} MB</span>
                <a href={logo?.url} target="_blank" rel="noreferrer">
                  <IconButton
                    className="topnav__back-button"
                    icon={
                      <Icon
                        className="choose-file__contract-delete--icon"
                        name="downloadFile"
                        size="small"
                      />
                    }
                  />
                </a>
                <IconButton
                  className="topnav__back-button"
                  color="danger"
                  disabled={true}
                  icon={
                    <Icon
                      className="choose-file__contract-delete--icon"
                      name="delete"
                      size="small"
                      color="danger"
                    />
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

NegativeUploadedLogoAttachments.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default NegativeUploadedLogoAttachments;
