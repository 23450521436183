import SelectBox from 'components/base-components/SelectBox';
import { t } from 'i18next';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { ANNUAL, IN_CONVERSION, INDIVIDUAL, MANUAL, VK_U2 } from 'utils/invoice-types';

const InvoiceTypeComponent = () => {
  const { values } = useFormState();
  const invoiceTypes = [INDIVIDUAL, ANNUAL];
  const invoiceSubTypes = values?.type === INDIVIDUAL ? [MANUAL] : [VK_U2, IN_CONVERSION];

  const invoiceTypeOptions = invoiceTypes.map(type => {
    return { label: t(`invoice.type_options.${type}`), value: type };
  });

  const invoiceSubTypeOptions = invoiceSubTypes.map(type => {
    return { label: t(`invoice.subtype_options.${type}`), value: type };
  });

  return (
    <div className="grid grid-cols-2 margin-top-20">
      <div className="col-span-1  invoice__form-input">
        <Field name="type">
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="large"
              label={t('invoice.invoice_job.invoice_type.label')}
              errorMsg={meta.submitError}
              options={invoiceTypeOptions}
              value={invoiceTypeOptions?.find(option => option.value === input.value)}
              onChange={e => input.onChange(e.value)}
              placeholderText={t('invoice.invoice_job.invoice_type.placeholder')}
              required={true}
            />
          )}
        </Field>
      </div>
      <div className="col-span-1  invoice__form-input invoice__form-input--right">
        <Field name="sub_type">
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="large"
              label={t('invoice.invoice_job.invoice_subtype.label')}
              errorMsg={meta.submitError}
              options={invoiceSubTypeOptions}
              value={invoiceSubTypeOptions.find(option => option.value === input.value)}
              onChange={e => input.onChange(e.value)}
              placeholderText={t('invoice.invoice_job.invoice_subtype.placeholder')}
              required={true}
              isDisabled={!values?.type}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export default InvoiceTypeComponent;
