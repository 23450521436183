import React, { Fragment } from 'react';
import { useDeleteUserAssignmentMutation, useGetUserAssignmentsQuery } from 'api/users';
import { useSearchParams } from 'react-router-dom';
import Pagination from 'components/base-components/Pagination';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Modal from 'components/base-components/Modal';
import SidePanel from 'components/base-components/SidePanel';
import Spinner from 'components/base-components/Spinner/Spinner';
import Tooltip from 'components/base-components/Tooltip';
import OrganizationAddress from 'components/organisations/organisation-address';
import OrganizationName from 'components/organisations/organization-name';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { useGetCurrentUserQuery } from 'api/users';
import isControlBodyMember from 'utils/is-control-body-member';
import ConfirmationModalWrapper from '../ConfirmationModalWrapper';
import AssignmentLoader from '../loaders/AssignmentLoader';
import UserEmail from '../user-email.component';
import AssignmentForm from './assignment-form.component';

const AssignmentEditComponent = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const { userId } = useParams();
  const [deleteAssignment, { isLoading, isError }] = useDeleteUserAssignmentMutation();
  const [deleteAssignmentId, setDeleteAssignmentId] = useState();
  const [editAssignment, setEditAssignment] = useState(null);
  const currentPage = searchParams.get('page');
  const { data, isFetching } = useGetUserAssignmentsQuery(
    { id: userId, page: currentPage },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const assignments = data ? data.assignments : [];
  const assignmentCount = assignments.length;
  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const handleDeleteModal = () => {
    setIsModalOpen(false);
    setIsConfirmationModalOpen(true);
  };

  const modalContent = () => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('user_details.assignment.modal.deleting_assignment')}
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('user_details.assignment.modal.failure_header')}</div>
            <div>{t('user_details.assignment.modal.failure_description')}</div>
            <div>{t('user_details.assignment.modal.try_again')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('user_details.assignment.modal.success_header')}</div>
          <div>{t('user_details.assignment.modal.success_description')}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="user__assignment--container">
      <div className="user__education--header">
        <div className="user__form--title">{t('user_edit.assignment_tab')}</div>
        <Button
          type="success"
          size="small"
          label={t('user_contact.add_button')}
          onClick={() => {
            setEditAssignment(null);
            setIsSidePanelOpen(true);
          }}
          disabled={isControlBodyMember(currentUser)}
        />
      </div>
      <>
        {isFetching ? (
          <div className="user__loader--wrapper">
            <AssignmentLoader />
            <AssignmentLoader />
          </div>
        ) : assignmentCount > 0 ? (
          <Fragment>
            {assignments.map(assignment => (
              <div key={assignment.id}>
                <Card className="col-span-12 user__education--card">
                  <div className="user__education--card__icon">
                    {assignmentCount === 1 ? (
                      <Tooltip
                        content={t('user_assignment.one_assignment_required')}
                        type="inverse"
                        size="small"
                      >
                        <IconButton
                          className="user__education-action--show"
                          icon={<Icon name="delete" size="tiny" />}
                          color="tertiary"
                          disabled={true}
                        />
                      </Tooltip>
                    ) : (
                      <IconButton
                        className="user__education-action--show"
                        icon={<Icon name="delete" size="tiny" />}
                        color="tertiary"
                        onClick={() => {
                          setDeleteAssignmentId(assignment.id);
                          setIsModalOpen(true);
                        }}
                      />
                    )}

                    <IconButton
                      className="user__education-action--show"
                      icon={<Icon name="edit" size="tiny" />}
                      color="tertiary"
                      onClick={() => {
                        setIsSidePanelOpen(true);
                        setEditAssignment(assignment);
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-12 gap-4 user__education--card__wrapper">
                    <div className="col-span-4 user__education--card__first-column">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.title')}
                      </div>
                      <div className="user__education--card__content">
                        {assignment.title
                          ? t(`organisation.titles.${assignment.title}`)
                          : t('common.not_applicable')}
                      </div>
                    </div>
                    <div className="col-span-4 user__education--card__second-column">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.address')}
                      </div>
                      <div className="user__education--card__content">
                        <OrganizationAddress organizationId={assignment.organizationId} />
                      </div>
                    </div>
                    <div className="col-span-4 user__education--card__third-column">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.organisation')}
                      </div>
                      <div className="user__education--card__content">
                        <OrganizationName organizationId={assignment.organizationId} />
                      </div>
                    </div>
                    <div className="col-span-4">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.role')}
                      </div>
                      <div className="user__education--card__content">
                        {assignment.role
                          ? t(`role.${assignment.role}`)
                          : t('common.not_applicable')}
                      </div>
                    </div>
                    <div className="col-span-4 user__education--card__second-column">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.valid_from')}
                      </div>
                      <div className="user__education--card__content">
                        {assignment.validFrom
                          ? dateMonthYearFormatter(assignment.validFrom)
                          : t('common.not_applicable')}
                      </div>
                    </div>
                    <div className="col-span-4 user__education--card__third-column">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.valid_until')}
                      </div>
                      <div className="user__education--card__content">
                        {assignment.validUntil
                          ? dateMonthYearFormatter(assignment.validUntil)
                          : t('common.not_applicable')}
                      </div>
                    </div>
                    <div className="col-span-4">
                      <div className="user__education--card__header">
                        {t('user_details.assignment.table_head.email')}
                      </div>
                      <div className="user__education--card__content">
                        {<UserEmail userID={assignment.userId} /> || t('common.not_available')}
                      </div>
                    </div>
                    {/* TODO: when verification can be done by phone number as well*/}
                    {/*<div className="col-span-4 user__education--card__second-column">
                        <div className="user__education--card__header">
                          {t('user_details.assignment.table_head.phone')}
                        </div>
                        <div className="user__education--card__content">
                          <UserPhone userID={assignment.userId} />
                        </div>
                      </div> */}
                  </div>
                </Card>
              </div>
            ))}
            <Pagination
              onPaginationClick={newPage => {
                setSearchParams({ page: newPage });
              }}
              totalPages={data && parseInt(data.pagination.totalPages, 10)}
              currentPage={currentPage}
              nextText={t('pagination.next')}
              previousText={t('pagination.prev')}
              firstText={t('pagination.first')}
              lastText={t('pagination.last')}
            />
          </Fragment>
        ) : (
          <EmptyPlaceholder
            emptyCardText={t('user_assignment.assignment.empty_placeholder')}
            emptyIcon={<Icon name="organization" color="tertiary" />}
          />
        )}
      </>

      <Modal
        className="user-details__modal"
        isVisible={isModalOpen}
        onOutsideClick={() => setIsModalOpen(false)}
      >
        <Modal.Title className="user-details__modal--title">
          <Icon name="warning" showBGColor={true} size="medium" color="warning" />
          <div className="user-details__modal--title__name">
            {t('user_assignment.delete_modal_title')}
          </div>
        </Modal.Title>
        <Modal.Content className="user-details__modal--content">
          {t('user_assignment.delete_message')}
        </Modal.Content>
        <Modal.Footer>
          <Button
            label={t('user_details.deactivate_user_modal.delete_button')}
            type="success"
            onClick={() => {
              deleteAssignment({
                user_id: userId,
                id: deleteAssignmentId,
              }).then(() => handleDeleteModal());
            }}
          />
          <Button
            label={t('user_details.deactivate_user_modal.cancel_button')}
            onClick={() => setIsModalOpen(false)}
          />
        </Modal.Footer>
      </Modal>
      <SidePanel
        isOpened={isSidePanelOpen}
        title={
          editAssignment
            ? t('user_edit.assignment_edit_title')
            : t('user_edit.assignment_create_title')
        }
        body={
          isSidePanelOpen && (
            <AssignmentForm
              setIsSidePanelOpen={setIsSidePanelOpen}
              editAssignment={editAssignment}
            />
          )
        }
        onClose={() => setIsSidePanelOpen(false)}
        onClick={() => setIsSidePanelOpen(false)}
      />

      <ConfirmationModalWrapper
        isVisible={isConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent()}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsConfirmationModalOpen(false);
        }}
      />
    </div>
  );
};

export default AssignmentEditComponent;
