import { useGetMessagesQuery } from 'api/messages';
import PropTypes from 'prop-types';
import OrganizationName from 'components/organisations/organization-name';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import UserAvatar from 'components/users/user-avatar.component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormatterWith24Hour } from 'utils/date-time-formatter';
import { Link } from 'react-router-dom';

const TopnavMessageComponent = ({ setIsSidePanelOpen }) => {
  const messageParams = {
    recipient: 'me',
  };

  const { data: messageDataCollection, isSuccess: isMessageDataFetched } =
    useGetMessagesQuery(messageParams);

  const messageData = (isMessageDataFetched && messageDataCollection?.collection) || [];
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();

  return (
    <div className="grid grid-cols-12 topnav__message-wrapper">
      {isMessageDataFetched && messageData.length > 0 ? (
        messageData.map(message => (
          <>
            <div className="topnav__message col-span-12">
              <div className="topnav__message-avatar">
                {message?.recipientOrganizationId ? (
                  <OrganizationName organizationId={message.recipientOrganizationId} />
                ) : (
                  <UserAvatar userID={message?.recipientUserIds[0]} />
                )}
                <div className="topnav__message-avatar--title">
                  <div>{message.title}</div>
                </div>
              </div>
              <div className="topnav__message-time">
                {dateTimeFormatterWith24Hour(message.updatedAt)}
              </div>
            </div>
            <hr className="col-span-12 topnav__task-horizontal topnav__task-horizontal--message" />
          </>
        ))
      ) : (
        <EmptyContentPlaceholder
          text={t('messages.no_message_found')}
          iconName="chat"
          showCard={false}
        />
      )}

      {isMessageDataFetched && messageData.length > 0 && (
        <Link className="col-span-12 " to={navigationLinks.messagesListPage()}>
          <div onClick={() => setIsSidePanelOpen(false)}>
            <hr className="topnav__task-horizontal" />
            <div className="topnav__task-view"> {t('dashboard.view_message')} </div>
            <hr className="topnav__task-horizontal" />
          </div>
        </Link>
      )}
    </div>
  );
};

TopnavMessageComponent.defaultProps = {
  setIsSidePanelOpen: () => {},
};

TopnavMessageComponent.propTypes = {
  setIsSidePanelOpen: PropTypes.func,
};

export default TopnavMessageComponent;
