import React from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import Alert from 'components/base-components/Alert';
import Avatar from 'components/base-components/Avatar/Avatar';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import isControlBodyMember from 'utils/is-control-body-member';
import Title from 'components/base-components/Typography/components/Title';
import { USER } from 'utils/users-role';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import './home.styles.scss';
import { Link } from 'react-router-dom';
import useNavigationLinks from 'hooks/useNavigationLinks';
import UserName from '../users/user-name.component';
import LatestUpdateComponent from './latest-update.component';
import MessageList from './message-list.component';
import OrganisationOverviewAdmin from './organisation-overview-admin.component';
import OrganisationOverviewComponent from './organisation-overview.component';
import TasksList from './tasks-list.component';
import UpcomingControls from './upcoming-controls.component';
import RecentOrganizations from './recent-organisations.component';

const HomeComponent = () => {
  const { t } = useTranslation();
  const { data: currentUser = {}, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();
  const navigationLinks = useNavigationLinks();
  const [searchParams] = useSearchParams();
  const unauthorized = searchParams.get('error');

  let latestUpdateOrMessageList;

  if (isBioSuisseAdmin(currentUser)) {
    latestUpdateOrMessageList = <LatestUpdateComponent />;
  } else if (isControlBodyMember(currentUser)) {
    latestUpdateOrMessageList = <UpcomingControls />;
  } else {
    latestUpdateOrMessageList = <MessageList />;
  }

  return (
    <div className="dashboard">
      {!!unauthorized && (
        <div className="dashboard__unauthorized-warning">
          <Alert isVisible={true} hideClose={true} type="warning" isIconVisible={false}>
            {t('shared.errors.unauthorized')}
          </Alert>
        </div>
      )}
      <div className="dashboard__container">
        <div className="dashboard__container-item">
          <div>
            <Card>
              <div className="dashboard__organisation-header">
                <div className="dashboard__organisation-title">
                  <Avatar
                    src={currentUser.profilePictureUrl}
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    size="huge"
                    showImageModal={true}
                  />
                  <div className="dashboard__organisation-title--info">
                    <UserName
                      userData={currentUser}
                      className="dashboard__organisation-title--info__name"
                    />
                    <div className="dashboard__organisation-title--info__email">
                      {currentUser.email}
                    </div>
                  </div>
                </div>
                <Link to={navigationLinks.dashboardUserEditPage(currentUser.id)}>
                  <IconButton
                    className="dashboard__user-edit--icon"
                    icon={<Icon name="settings" size="medium" />}
                    size="small"
                    color="default"
                  />
                </Link>
              </div>
            </Card>
          </div>
          <div className="dashboard__organisation-wrapper">
            {isBioSuisseAdmin(currentUser) && <OrganisationOverviewAdmin />}
            {currentUser && currentUser.role === USER && (
              <Card>
                <Title level={6} weight="boldest">
                  {t('common.organisations')}
                </Title>
                {isCurrentUserFetched &&
                  currentUser.assignments.map(assignment => (
                    <OrganisationOverviewComponent assignment={assignment} key={assignment.id} />
                  ))}
              </Card>
            )}
            {isControlBodyMember(currentUser) && <RecentOrganizations />}
          </div>
        </div>
        <TasksList />
        {latestUpdateOrMessageList}
      </div>
    </div>
  );
};

export default HomeComponent;
