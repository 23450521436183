import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useResponsive from 'hooks/useResponsive';

const SideNav = props => {
  const { children, className } = props;
  const { isMobile } = useResponsive();

  return (
    <div
      className={classNames(className, {
        'side-nav': !isMobile,
        'side-nav__mobile': isMobile,
      })}
    >
      {children}
    </div>
  );
};

SideNav.defaultProps = {
  className: null,
};

SideNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SideNav;
