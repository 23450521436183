const ACTIVE = 'active';
const ARCHIVED = 'archived';
const SUBMITTED = 'submitted';
const COMPLAINED = 'complained';
const APPROVED = 'approved';
const DRAFT = 'draft';
const EXPIRED = 'expired';
const EXPIRING_SOON = 'expiring_soon';
const PENDING = 'pending';
const FINAL = 'final';
const INVITED = 'invited';
const INTERESTED = 'interested';

export {
  ACTIVE,
  ARCHIVED,
  SUBMITTED,
  COMPLAINED,
  APPROVED,
  DRAFT,
  EXPIRING_SOON,
  EXPIRED,
  PENDING,
  FINAL,
  INVITED,
  INTERESTED,
};
