import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useUpdateOrganizationGoodsMutation } from 'api/goods-declarations';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';

import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';

const GoodsEditFormComponent = ({
  setIsEditSidepanelOpen,
  goodsId,
  selectedMonth,
  initialEditData,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [updateGoods, { error }] = useUpdateOrganizationGoodsMutation();

  const onSubmit = (values, form) => {
    values['organization_id'] = id;
    values['goods_declaration_id'] = goodsId;
    const payload = removeEmptyFromObject(values);
    return updateGoods({ params: { goods_id: goodsId }, payload })
      .unwrap()
      .then(() => {
        form.reset();
        setIsEditSidepanelOpen(false);
      })
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <Fragment>
      <div className="goods-form__select-month col-span-12 ">
        {t('goods.adding_goods')}
        <span>( {initialEditData.date} )</span>
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name={`month_${selectedMonth}_total_amount`}
              initialValue={initialEditData.totalAmount}
            >
              {({ input }) => (
                <div className="goods-form--with-top-margin">
                  <Input
                    size="tiny"
                    label={t('goods.total_amount_chf')}
                    touched={true}
                    error={
                      error?.data?.errors?.month1TotalAmount ||
                      error?.data?.errors?.month2TotalAmount ||
                      error?.data?.errors?.month3TotalAmount
                    }
                    placeholder={t('goods.total_amount_placeholder')}
                    {...input}
                  />
                </div>
              )}
            </Field>
            <Field
              name={`month_${selectedMonth}_share_knospe`}
              initialValue={initialEditData.shareKnospe}
            >
              {({ input }) => (
                <div className="goods-form--with-top-margin">
                  <Input
                    size="tiny"
                    label={t('goods.knospp_certified')}
                    touched={true}
                    error={
                      error?.data?.errors?.base ||
                      error?.data?.errors?.month1ShareKnospe ||
                      error?.data?.errors?.month2ShareKnospe ||
                      error?.data?.errors?.month3ShareKnospe
                    }
                    placeholder={t('goods.knospp_certified_placeholder')}
                    {...input}
                  />
                </div>
              )}
            </Field>
            <Field
              name={`month_${selectedMonth}_share_bio`}
              initialValue={initialEditData.shareBio}
            >
              {({ input }) => (
                <div className="goods-form--with-top-margin">
                  <Input
                    size="tiny"
                    label={t('goods.bio_certified_percentage')}
                    touched={true}
                    error={
                      error?.data?.errors?.base ||
                      error?.data?.errors?.month1ShareBio ||
                      error?.data?.errors?.month2ShareBio ||
                      error?.data?.errors?.month3ShareBio
                    }
                    placeholder={t('goods.bio_certified_placeholder')}
                    {...input}
                  />
                </div>
              )}
            </Field>
            <div className="goods-form__buttons">
              <Button
                label={t('common.save_button')}
                type="success"
                size="small"
                disabled={submitting}
                submitType="submit"
              />
              <Button
                className="goods-form__buttons-cancel"
                size="small"
                label={t('common.cancel_button')}
                onClick={() => setIsEditSidepanelOpen(false)}
              />
            </div>
          </form>
        )}
      />
    </Fragment>
  );
};

GoodsEditFormComponent.defaultProps = {
  setIsEditSidepanelOpen: () => {},
  goodsId: null,
  initialEditData: null,
};

GoodsEditFormComponent.propTypes = {
  setIsEditSidepanelOpen: PropTypes.func,
  goodsId: PropTypes.number,
  selectedMonth: PropTypes.number.isRequired,
  initialEditData: PropTypes.shape(),
};

export default GoodsEditFormComponent;
