import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { Text } from '../Typography/index';
import './Topnav.scss';

const Topnav = props => {
  const {
    className,
    pageHeader,
    breadcrumbs: Breadcrumbs,
    showBackButton,
    backButtonLabel,
    onBackButtonClick,
    actions,
    imageSrc,
    extraOptions,
  } = props;

  const backButton = (
    <IconButton
      className="topnav__back-button"
      icon={<Icon name="arrowBack" />}
      label={
        <Text level={3} weight="medium">
          {backButtonLabel}
        </Text>
      }
      onClick={onBackButtonClick}
    />
  );

  const testTag = (
    <div className="topnav__test-tag">
      <EmphasisTag radius="oval" size="normal" text="TEST" type="danger" />
    </div>
  );

  const header = <div className="topnav__page-header">{pageHeader}</div>;

  return (
    <>
      <div className={classNames('topnav', className)}>
        {imageSrc}
        {['staging', 'integration'].includes(process.env.REACT_APP_ENV) && testTag}
        {showBackButton ? backButton : null}
        {header}
        {Breadcrumbs}
        {actions.map(item => {
          return <div className={'topnav__action'}> {item} </div>;
        })}
      </div>
      {extraOptions}
    </>
  );
};

Topnav.defaultProps = {
  className: null,
  pageHeader: null,
  breadcrumbs: null,
  extraOptions: null,
  showBackButton: true,
  imageSrc: null,
  backButtonLabel: null,
  actions: [],
  onBackButtonClick: () => {},
};

Topnav.propTypes = {
  className: PropTypes.string,
  pageHeader: PropTypes.node,
  breadcrumbs: PropTypes.node,
  showBackButton: PropTypes.bool,
  backButtonLabel: PropTypes.node,
  extraOptions: PropTypes.node,
  imageSrc: PropTypes.node,
  onBackButtonClick: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.node),
};

export default Topnav;
