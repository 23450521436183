import { useGetMeatFirmsQuery, useGetProductionsQuery } from 'api/producers/productions';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import EnsureTransparencyComponent from 'components/shared-components/ensure-transparency';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router';
import { MEAT_PRODUCTION } from 'utils/production-type';
import { COW, EWE_GOAT, EWE_SHEEP, PIG, POULTRY } from 'utils/production-data-type';
import TitleComponent from '../../plant-cultivate/shared/title.component';
import CheckboxWithIcon from '../../shared/checkbox-with-icon.component';
import CowForm from '../form/cow-form.component';
import PigsForm from '../form/pigs-form.component';
import PoultryForm from '../form/poultry-form.component';
import SharedMeatField from '../form/shared-field.component';
import EmptyProducerContent from '../../shared/empty-producer-content.component';

const MeatTypesComponent = ({ onChangeSubTypeSelection, validationError }) => {
  const { id } = useParams();
  const { values } = useFormState();

  const { data: farmlandData } = useGetProductionsQuery(
    {
      organizationId: id,
    },
    { skip: !id },
  );

  const { data: meatProductionData } = useGetMeatFirmsQuery(
    {
      productionId: farmlandData?.id,
    },
    { skip: !farmlandData },
  );

  const isFieldChecked = name =>
    meatProductionData && meatProductionData.some(meat => meat.subType === name);

  const meats = [COW, PIG, POULTRY, EWE_SHEEP, EWE_GOAT];

  const meatTypes = meats.map(meat => {
    return {
      name: meat,
      icon: meat,
      label: t(`meat_production.types.${meat}`),
    };
  });

  const isCardVisible =
    values?.meat?.cow ||
    values?.meat?.poultry ||
    values?.meat?.ewe_goat ||
    values?.meat?.pig ||
    values?.meat?.ewe_sheep;

  const getInitialValue = type => meatProductionData?.find(meat => meat.subType === type);

  const onChange = (name, checked) => {
    onChangeSubTypeSelection(MEAT_PRODUCTION, name, checked);
  };

  return (
    <>
      <Card className="product-cultivation__outside-card">
        <div className="product-cultivation__header">
          {t('plant_cultivation.bee_hives.produce_types')}
        </div>
        <div className="product-cultivation__description">
          {t('plant_cultivation.bee_hives.produce_types_description')}
        </div>
        <div className="grid grid-cols-2 gap-4 pt-4">
          {meatTypes.map(({ name, label, icon }, index) => (
            <div className="col-span-1">
              <CheckboxWithIcon
                label={label}
                name={name}
                fieldName={`meat.${name}`}
                icon={icon}
                isChecked={isFieldChecked(name)}
                onChange={onChange}
              />
            </div>
          ))}
          {validationError?.meat && <div className="error-text">{validationError?.meat}</div>}
        </div>
      </Card>
      <EnsureTransparencyComponent />
      <Card
        className={classNames({
          'product-cultivation__outside-card': isCardVisible,
          'product-cultivation__outside-card--empty': !isCardVisible,
        })}
      >
        {isCardVisible ? (
          <Card className="product-cultivation__outside-card">
            <div className="product-cultivation__details-text">
              {t('egg_production.produce_details')}
            </div>
            <CowForm
              isVisible={values?.meat?.cow}
              label={t('meat_production.types.cow')}
              icon="cow"
              initialValue={getInitialValue('cow')}
              error={validationError?.[COW]}
            />
            <PigsForm
              isVisible={values?.meat?.pig}
              label={t('meat_production.types.pig')}
              icon="pig"
              initialValue={getInitialValue('pig')}
              error={validationError?.[PIG]}
            />
            <PoultryForm
              isVisible={values?.meat?.poultry}
              label={t('meat_production.types.poultry')}
              icon="poultry"
              initialValue={getInitialValue('poultry')}
              error={validationError?.[POULTRY]}
            />
            {values?.meat?.ewe_sheep && (
              <Card className="product-cultivation__card">
                <TitleComponent title={t('meat_production.types.ewe_sheep')} icon="ewe_sheep" />
                <Card className="product-cultivation__inside-card">
                  <SharedMeatField
                    fieldName="numberOfEweSheep"
                    label={t('meat_production.ewe_sheep_number')}
                    initialValue={getInitialValue('ewe_sheep')}
                  />
                </Card>
                {validationError?.[EWE_SHEEP] && (
                  <div className="error-text margin-left-20 margin-bottom-20">
                    {validationError?.[EWE_SHEEP]}
                  </div>
                )}
              </Card>
            )}
            {values?.meat?.ewe_goat && (
              <Card className="product-cultivation__card">
                <TitleComponent title={t('meat_production.types.ewe_goat')} icon="ewe_goat" />
                <Card className="product-cultivation__inside-card">
                  <SharedMeatField
                    fieldName="numberOfEweGoat"
                    label={t('meat_production.ewe_goat_number')}
                    initialValue={getInitialValue('ewe_goat')}
                  />
                </Card>
                {validationError?.[EWE_GOAT] && (
                  <div className="error-text margin-left-20 margin-bottom-20">
                    {validationError?.[EWE_GOAT]}
                  </div>
                )}
              </Card>
            )}
          </Card>
        ) : (
          <EmptyProducerContent />
        )}
      </Card>
    </>
  );
};

MeatTypesComponent.defaultProps = {
  validationError: null,
};

MeatTypesComponent.propTypes = {
  onChangeSubTypeSelection: PropTypes.func.isRequired,
  validationError: PropTypes.shape(),
};

export default MeatTypesComponent;
