import React from 'react';
import { useCreateUserEducationMutation, useUpdateUserEducationMutation } from 'api/educations';
import Button from 'components/base-components/Button';
import DatePicker from 'components/base-components/DatePicker';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import {
  useGetEducationDegreesQuery,
  useGetEducationInstitutesQuery,
} from '../../../api/educations';
import '../users.styles.scss';

const EducationForm = ({ editEducation, setIsSidePanelOpen }) => {
  const [isCertificationDateOpen, setIsCertificationDateOpen] = useState(false);
  const [createEducation] = useCreateUserEducationMutation();
  const [updateEducation] = useUpdateUserEducationMutation();
  const { userId } = useParams();
  const { data: institutes = [] } = useGetEducationInstitutesQuery();
  const { data: degrees = [] } = useGetEducationDegreesQuery();
  const { t, i18n } = useTranslation();

  const institutesOptions = institutes.map(institute => ({
    label: t(`educations.institute.${institute}`),
    value: institute,
  }));

  const getDegreeLabel = degree => {
    if (i18n.language === 'en') return t(`educations.degree.${degree}`);

    return (
      t(`educations.degree.${degree}`, { lng: 'en' }) + ' - ' + t(`educations.degree.${degree}`)
    );
  };

  const degreesOptions = degrees.map(degree => ({
    label: getDegreeLabel(degree),
    value: degree,
  }));

  const onSubmit = (values, form) => {
    let payload = new FormData();

    values = removeEmptyFromObject(values);
    Object.keys(values).map(key => payload.append(key, values[key]));

    if (editEducation) {
      const params = { queryParams: { educationId: editEducation.id }, payload };
      return updateEducation(params)
        .unwrap()
        .then(() => {
          form.reset();
          setIsSidePanelOpen(false);
        })
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      const params = { queryParams: { userId }, payload };
      return createEducation(params)
        .unwrap()
        .then(() => {
          form.reset();
          setIsSidePanelOpen(false);
        })
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="user__edit--form-content">
            <div className="col-span-12 ">
              <Field name="degree" initialValue={editEducation?.degree || ''}>
                {({ input, meta }) => (
                  <SelectBox
                    className="user__education--form"
                    size="small"
                    width="large"
                    label={t('user_education.degree.label')}
                    placeholderText={t('user_education.degree.placeholder')}
                    isClearable={false}
                    options={degreesOptions}
                    value={degreesOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                    touched={!meta.dirtySinceLastSubmit}
                    errorMsg={meta?.submitError}
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="certification_id" initialValue={editEducation?.certificationId || null}>
                {({ input }) => (
                  <Input
                    className="user__education--form"
                    size="small"
                    label={t('user_education.certification_id.label')}
                    placeholder={t('user_education.certification_id.placeholder')}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="institute" initialValue={editEducation?.institute || null}>
                {({ input, meta }) => (
                  <SelectBox
                    className="user__education--form"
                    size="small"
                    width="large"
                    label={t('user_education.institute.label')}
                    placeholderText={t('user_education.institute.placeholder')}
                    isClearable={false}
                    options={institutesOptions}
                    value={institutesOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                    required={true}
                    touched={!meta.dirtySinceLastSubmit}
                    errorMsg={meta?.submitError}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field
                name="diploma_for_education"
                initialValue={editEducation?.diplomaForEducation || null}
              >
                {({ input, meta }) => (
                  <ChooseFile
                    fileName={input.value?.name}
                    label={t('shared.attributes.diploma.label')}
                    onChange={file => {
                      input.onChange(file);
                    }}
                    errorMsg={meta?.submitError}
                    touched={!meta.dirtySinceLastSubmit}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field
                name="certification_date"
                initialValue={editEducation?.certificationDate || null}
              >
                {({ input, meta }) => (
                  <DatePicker
                    className="user__education--form"
                    focused={isCertificationDateOpen}
                    date={input.value}
                    onChange={e => input.onChange(e)}
                    onFocusChange={() => setIsCertificationDateOpen(!isCertificationDateOpen)}
                    displayFormat="LL"
                    label={t('user_education.doc.label')}
                    placeholder={t('user_education.doc.placeholder')}
                    navNext="arrowForwardAlt"
                    navPrev="arrowBackAlt"
                    size="small"
                    enablePastDates={true}
                    required={true}
                    touched={true}
                    error={meta?.submitError}
                    locale={i18n.language}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="col-span-12 ">
            <Button
              label={
                editEducation
                  ? t('user_education.update_button')
                  : t('user_education.add_button_education')
              }
              type="success"
              size="small"
              disabled={submitting}
              submitType="submit"
              className="user__edit--submit"
            />
          </div>
        </form>
      )}
    />
  );
};

EducationForm.defaultProps = {
  editEducation: null,
  setIsSidePanelOpen: () => {},
};

EducationForm.propTypes = {
  editEducation: PropTypes.shape(),
  setIsSidePanelOpen: PropTypes.func,
};

export default EducationForm;
