import React, { useRef } from 'react';
import LanguageContext from 'contexts/languageContext';
import { useGetOrganizationQuery } from 'api/organizations';
import Card from 'components/base-components/Card';
import Stepper from 'components/base-components/stepper';
import ProducerContractPDF from 'components/pdf/producer-contract/producer-contract.document';
import i18next, { t as translate } from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { WARNING } from 'utils/status';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery, useGetUserQuery } from 'api/users';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { VERIFIED } from 'utils/signed-contract-statuses';
import ProducerCoverLetterPdf from 'components/pdf/producer-contract/cover-letter/producer-cover-letter.document';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import i18n from 'i18n';
import { PRODUCER } from 'utils/organisation-industries';
import ContractVerification from '../shared/shared-verification.component';
import ContractSendComponent from './contract-send.component';
import ProducerBlobProvider from './producer-blob-provider.component';

const ProducerContractComponent = ({ contractData }) => {
  const contractFileRef = useRef();
  const coverLetterRef = useRef();
  const { id } = useParams();
  const language = i18n.language;

  const { data: organisationData, isSuccess: isOrganisationFetched } = useGetOrganizationQuery(id);
  const isProducer = organisationData?.industry === PRODUCER;

  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);

  const isDeactivated = organisationData?.status === ORGANISATIONS_STATUS.inactive;
  const { data: user = {} } = useGetUserQuery(
    { id: organisationData?.mainContactId },
    { skip: !organisationData?.mainContactId },
  );

  const t = i18next.getFixedT(organisationData?.language);
  const PDF_FILENAME = t('contract.producer.pdf.contract_file');
  const PDF_COVER_LETTER_FILENAME = t('contract.producer.pdf.cover_letter');

  let thirdStepName = translate('contract.attributes.verified'),
    stepStatesArray = [],
    isLastStepDone = false;

  const steps = {
    generate: 1,
    sent: 2,
    cancelled: 3,
    verified: 3,
    aborted: 3,
    terminated: 3,
  };

  const onDownloadAll = () => {
    coverLetterRef.current.click();
    contractFileRef.current.click();
  };

  const activeIndex = () => {
    if (!contractData?.status) {
      return steps['generate'];
    } else if (steps[contractData?.status] === 3) {
      isLastStepDone = true;
    } else return steps[contractData?.status];
  };

  if (steps[contractData?.status] === 3) {
    thirdStepName = translate(`contract.attributes.${contractData?.status}`);
    stepStatesArray[2] = contractData?.status !== VERIFIED && WARNING;
  }

  return (
    <>
      {isAdmin && (
        <Stepper
          activeStepIndex={activeIndex()}
          direction="horizontal"
          doneAllStep={isLastStepDone}
          showActionButtons={false}
          showStepTitle={false}
          stepStates={stepStatesArray}
          steps={[
            { stepName: translate('contract.attributes.generate') },
            { stepName: translate('contract.attributes.sent') },
            { stepName: thirdStepName },
          ]}
        />
      )}
      <div className="mt-4">
        <Card className="organisation-contract__step-download">
          <div className="organisation-contract__first-step">
            {isAdmin
              ? translate('contract.producer.generate_step.title')
              : translate('contract.producer.generate_step.user.title')}
          </div>
          <div className="organisation-contract__eligibility">
            {translate('contract.producer.generate_step.text')}
          </div>
          {isOrganisationFetched && (
            <>
              <ProducerBlobProvider
                document={
                  <LanguageContext.Provider value={{ t }}>
                    <ProducerContractPDF organisation={organisationData} language={language} />
                  </LanguageContext.Provider>
                }
                fileName={PDF_FILENAME}
                ref={contractFileRef}
              />
              {isProducer && (
                <div className="organisation-contract__eligibility">
                  {translate('contract.producer.generate_step.contract_type')}
                </div>
              )}
              <ProducerBlobProvider
                document={
                  <LanguageContext.Provider value={{ t }}>
                    <ProducerCoverLetterPdf
                      organisation={organisationData}
                      user={user}
                      language={language}
                    />
                  </LanguageContext.Provider>
                }
                fileName={PDF_COVER_LETTER_FILENAME}
                ref={coverLetterRef}
              />
              <Button
                label={translate('contract.download_button')}
                size="small"
                icon={<Icon name="download" color="tertiary" size="tiny" />}
                disabled={isDeactivated}
                onClick={onDownloadAll}
              />
            </>
          )}
        </Card>
      </div>
      {isAdmin && (
        <div className="my-4">
          <ContractSendComponent
            contractData={contractData}
            title={translate('contract.producer.sent_or_cancel_step.title')}
          />
        </div>
      )}

      <div className="organisation-contract__step-three">
        <ContractVerification
          contractData={contractData}
          title={
            isAdmin
              ? translate('contract.producer.verify_or_terminate_step.title')
              : translate('contract.producer.sent_or_cancel_step.user.title')
          }
        />
      </div>
    </>
  );
};

ProducerContractComponent.defaultProps = {
  contractData: null,
};

ProducerContractComponent.propTypes = {
  contractData: PropTypes.shape(),
};

export default ProducerContractComponent;
