import React, { useState, useEffect } from 'react';
import { Tabs } from 'components/base-components/RTabs';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery, useGetUserQuery } from 'api/users';
import { CONTROL_BODY_USER } from 'utils/users-role';
import useCustomNavigate from 'hooks/useCustomNavigate';
import ASSIGNMENT_ROLES from 'utils/assignment-roles';
import DefaultSpinner from 'components/shared-components/default-spinner';
import AssignmentEditComponent from './assignment/assignment-edit.component';
import EducationEditComponent from './education/education-edit.component';
import CredentialEditComponent from './credential-management/credential-edit-component';
import GeneralInfoEditComponent from './general/general-info-edit.component';
import ContactEditComponent from './contact/contact-edit.component';
import ManageUserComponent from './manage/manage-user.component';

const UserEditComponent = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const { userId } = useParams();
  const navigate = useCustomNavigate();

  const { data: user, isFetching } = useGetUserQuery({ id: userId });
  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();
  const owners =
    isCurrentUserFetched &&
    currentUser.assignments.filter(assignment => assignment.role === ASSIGNMENT_ROLES.owner);

  const ownersId = owners.map(owner => owner.userId);

  const isOwner = owners.length > 0 && ownersId.includes(currentUser.id);

  useEffect(() => {
    if (
      isCurrentUserFetched &&
      parseInt(userId) !== currentUser?.id &&
      !isBioSuisseAdmin(currentUser) &&
      !isOwner
    ) {
      navigate.openRootPage();
    }
  }, [currentUser]);

  if (isFetching) {
    return <DefaultSpinner className="default-spinner--user-edit" />;
  }

  const disabledEducationAssignment = isBioSuisseAdmin(user);

  const getCredentialTab = () => {
    if (parseInt(userId) === currentUser?.id) {
      return [
        {
          title: t('user_edit.credential_tab'),
          key: 4,
          getContent: () => <CredentialEditComponent />,
        },
      ];
    }
    return [];
  };

  const getManageUserTab = () => {
    if (user?.role !== CONTROL_BODY_USER || currentUser?.id === parseInt(userId)) return [];
    return [
      {
        title: t('user_edit.manage_user'),
        key: 5,
        getContent: () => <ManageUserComponent />,
      },
    ];
  };

  const getEducationAssignmentTab = () => {
    if (disabledEducationAssignment) return [];
    return [
      {
        title: t('user_edit.education_tab'),
        key: 2,
        getContent: () => <EducationEditComponent userId={userId} />,
      },
      {
        title: t('user_edit.assignment_tab'),
        key: 3,
        getContent: () => <AssignmentEditComponent />,
      },
    ];
  };

  return (
    <div className="grid grid-cols-12 justify-center">
      <div className="col-span-6 col-start-4 user__rtabs user__rtabs--edit">
        <Tabs
          controlled={true}
          selectedTabKey={selectedTab}
          items={[
            {
              title: t('user_edit.general_tab'),
              key: 0,
              getContent: () => <GeneralInfoEditComponent />,
            },
            {
              title: t('user_edit.email_phone_tab'),
              key: 1,
              getContent: () => <ContactEditComponent />,
            },
            ...getEducationAssignmentTab(),
            ...getCredentialTab(),
            ...getManageUserTab(),
          ]}
          onChange={key => setSelectedTab(key)}
        />
      </div>
    </div>
  );
};

export default UserEditComponent;
