import { useGetOrganizationQuery } from 'api/organizations';
import { useDeclareControlBodyDeclarationMutation } from 'api/control-body-declarations';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useCustomNavigate from 'hooks/useCustomNavigate';
import SelectControlBody from './select-control-body.component';

const ControlBodyFormComponent = ({ setIsSidepanelOpen, controlBodyData }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useCustomNavigate();

  const [declareControlBody, status] = useDeclareControlBodyDeclarationMutation();
  const { data: organisation, isFetching } = useGetOrganizationQuery(id);

  const onSubmit = values => {
    values['uid'] = organisation.uid;
    return declareControlBody({ id, payload: values })
      .unwrap()
      .then(() => setIsSidepanelOpen(false));
  };

  if (isFetching) {
    return;
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <SelectControlBody controlBodyData={controlBodyData} />
            </div>
            <div className="control-body-form__buttons col-span-12 my-4">
              <SubmitModal
                isLoading={status.isLoading}
                isSuccess={status.isSuccess}
                requestId={status.requestId}
                successTitle={t('control_body.modal.declare.success.title')}
                successContent={t('control_body.modal.declare.success.content')}
                showError={false}
                showLoader={false}
                onSuccess={() => navigate.openOrganisationDetailsPage(id)}
              />
              <Button
                label={t('control_body.declaration_button')}
                type="success"
                size="small"
                submitType="submit"
                disabled={submitting}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

ControlBodyFormComponent.defaultProps = {
  controlBodyData: null,
};

ControlBodyFormComponent.propTypes = {
  setIsSidepanelOpen: PropTypes.func.isRequired,
  controlBodyData: PropTypes.shape(),
};

export default ControlBodyFormComponent;
