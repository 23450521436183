import { Provider, ErrorBoundary } from '@rollbar/react';
import ShowErrorPage from 'components/error-page';
import rollbarInstance from 'utils/rollbar-instance';
import RoutesComponent from './components/routes/routes.component';
import './App.scss';

function App() {
  return (
    <>
      <div className="h-[90vh] pt-[60px] ml-0 md:ml-[65px]">
        <Provider instance={rollbarInstance}>
          <ErrorBoundary fallbackUI={ShowErrorPage}>
            <RoutesComponent />
          </ErrorBoundary>
        </Provider>
      </div>
    </>
  );
}

export default App;
