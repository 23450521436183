import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from '../base-components/Button';
import Input from '../base-components/Input';

export default function VerifyOtp({ onSuccess, verifyMethod }) {
  const [verifyOtp, { error: { data: errorResponse } = {} }] = verifyMethod();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const verify_token = searchParams.get('token');

  const OnVerifyOtp = values => {
    verifyOtp({ otp: values.otp, verify_token })
      .unwrap()
      .then(() => onSuccess())
      .catch(() => {});
  };

  return (
    <Form
      onSubmit={OnVerifyOtp}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <div className="mt-6 text-center min-w-[250px]">{t('login.two_factor_label')}</div>
          <Field name="otp">
            {({ input }) => (
              <Input
                className="mt-6 w-[345px]"
                label={t('user_credential.two_factor.authentication_code.label')}
                touched={true}
                placeholder={t('user_credential.two_factor.enter_otp')}
                error={errorResponse?.error}
                {...input}
              />
            )}
          </Field>
          <Button
            label={t('shared.action.verify')}
            type="success"
            size="normal"
            className="rounded mt-[46px] w-[345px]"
            submitType="submit"
          />
        </form>
      )}
    />
  );
}

VerifyOtp.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  verifyMethod: PropTypes.func.isRequired,
};
