import CheckBox from 'components/base-components/CheckBox/CheckBox';
import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { FieldArray } from 'react-final-form-arrays';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useGetArableCropOptionsQuery } from 'api/producers/productions';
import Card from 'components/base-components/Card';
import TitleComponent from '../shared/title.component';

const ArableCropsComponent = ({ isVisible, initialValue, error }) => {
  const initialValueToSnakeCase = initialValue && toSnakecaseKeys(initialValue);

  const onCheckArableCrop = name => {
    if (!initialValueToSnakeCase) return null;
    if (
      initialValueToSnakeCase &&
      Object.keys(initialValueToSnakeCase).some(crop => crop === name)
    ) {
      return name;
    }
    return null;
  };

  const { data: cropData = [] } = useGetArableCropOptionsQuery({}, { skip: !isVisible });

  if (!isVisible) return;

  return (
    <Card className="plant-cultivation__card">
      <TitleComponent title={t('plant_cultivation.arable_crops.title')} icon="arable_crop" />
      <FieldArray name="arableCrops">
        {() => (
          <div className="grid grid-cols-2 w-fit gap-x-4 p-4 pt-2">
            {cropData.map(({ value, label }, index) => (
              <div className="col-span-1" key={index}>
                <Field
                  name={`arableCrops[${index}]`}
                  type="checkbox"
                  initialValue={onCheckArableCrop(value)}
                >
                  {({ input }) => (
                    <CheckBox
                      className={classNames('plant-cultivation__method--arable-crop--content', {
                        'plant-cultivation__method--arable-crop--content__checked': input.checked,
                      })}
                      isChecked={input.checked}
                      size="tiny"
                      label={label}
                      onChange={e => input.onChange(e.target.checked ? value : null)}
                    />
                  )}
                </Field>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
      {error && <div className="error-text margin-bottom-20 margin-left-20">{error}</div>}
    </Card>
  );
};

ArableCropsComponent.defaultProps = {
  isVisible: false,
  initialValue: {},
  error: null,
};

ArableCropsComponent.propTypes = {
  isVisible: PropTypes.bool,
  initialValue: PropTypes.shape(),
  error: PropTypes.string,
};

export default ArableCropsComponent;
