import {
  useDeactivateOrganizationMutation,
  useGetOrganizationQuery,
  useReactivateOrganizationMutation,
} from 'api/organizations';
import classNames from 'classnames';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import DeactivateReactivateModal from './deactivate-reactivate-modal.component';

const ManageOrganisationComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deactivateOrganisation, organisationDeactivationStatus] =
    useDeactivateOrganizationMutation();
  const [activateOrganisation, organisationReactivationStatus] =
    useReactivateOrganizationMutation();
  const { data: organisationData, refetch } = useGetOrganizationQuery(id);

  return (
    <>
      <DeactivateReactivateModal
        type={organisationData?.status}
        orgId={id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        status={
          organisationData?.status === ORGANISATIONS_STATUS.inactive
            ? organisationReactivationStatus
            : organisationDeactivationStatus
        }
        callback={
          organisationData?.status === ORGANISATIONS_STATUS.inactive
            ? activateOrganisation
            : deactivateOrganisation
        }
        orgName={organisationData?.name}
        refetch={refetch}
      />
      <Card>
        <div>
          {t('organisation_view.manage_organisation.deactivate_title', {
            organisation_name: organisationData?.name,
          })}
        </div>
        <div className="my-4">{t('organisation_view.manage_organisation.deactivate_text')}</div>
        <div>
          <Button
            className={classNames({
              'organisation__inactive-btn':
                organisationData?.status !== ORGANISATIONS_STATUS.inactive,
            })}
            type={organisationData?.status === ORGANISATIONS_STATUS.inactive ? 'default' : 'danger'}
            size="small"
            label={
              organisationData?.status === ORGANISATIONS_STATUS.inactive
                ? t('organisation_view.manage_organisation.reactivate_button')
                : t('organisation_view.manage_organisation.deactivate_button')
            }
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </Card>
    </>
  );
};

export default ManageOrganisationComponent;
