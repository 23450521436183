import { useGetDairyFarmsQuery, useGetProductionsQuery } from 'api/producers/productions';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import EnsureTransparencyComponent from 'components/shared-components/ensure-transparency';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { MILK_PRODUCTION } from 'utils/production-type';
import EmptyProducerContent from '../shared/empty-producer-content.component';
import CheckboxWithIcon from '../shared/checkbox-with-icon.component';
import MilkProductionForm from './forms/milk-form.component';

export const milksTypes = [
  {
    name: 'milk_cow',
    icon: 'cow',
    label: t(`milk_production.types.milk_cow`),
  },
  {
    name: 'dairy_ewe',
    icon: 'dairyEwe',
    label: t(`milk_production.types.dairy_ewe`),
  },
  {
    name: 'dairy_goat',
    icon: 'dairyGoat',
    label: t(`milk_production.types.dairy_goat`),
  },
];

const MilkTypesComponent = ({ onChangeSubTypeSelection, validationError }) => {
  const { id } = useParams();
  const { values } = useFormState();
  const { data: farmland } = useGetProductionsQuery({ organizationId: id }, { skip: !id });
  const { data: dairyFarms = [] } = useGetDairyFarmsQuery(
    { productionId: farmland?.id },
    { skip: !farmland },
  );

  const getInitialValue = type => dairyFarms.find(milk => milk?.subType === type);

  const onChange = (name, checked) => {
    onChangeSubTypeSelection(MILK_PRODUCTION, name, checked);
  };

  const isCardVisible =
    values?.dairy_farms?.sub_types?.milk_cow ||
    values?.dairy_farms?.sub_types?.dairy_ewe ||
    values?.dairy_farms?.sub_types?.dairy_goat;

  return (
    <>
      <Card className="product-cultivation__outside-card">
        <div className="product-cultivation__header">{t('milk_production.produce_types')}</div>
        <div className="product-cultivation__description">
          {t('plant_cultivation.types_description')}
        </div>
        <div className="grid grid-cols-2 gap-4 pt-4">
          {milksTypes.map(({ name, label, icon }, index) => (
            <div className="col-span-1">
              <CheckboxWithIcon
                label={label}
                name={name}
                fieldName={`dairy_farms.sub_types.${name}`}
                icon={icon}
                isChecked={!!getInitialValue(name)}
                onChange={onChange}
              />
            </div>
          ))}
        </div>
        {validationError?.dairy_firm && (
          <div className="error-text">{validationError?.dairy_firm}</div>
        )}
      </Card>
      <EnsureTransparencyComponent />
      <Card
        className={classNames({
          'product-cultivation__outside-card': isCardVisible,
          'product-cultivation__outside-card--empty': !isCardVisible,
        })}
      >
        {isCardVisible ? (
          <Card className="product-cultivation__outside-card">
            <div className="product-cultivation__header">{t('plant_cultivation.details')}</div>
            {milksTypes.map(({ name, label, icon }) => (
              <MilkProductionForm
                key={name}
                name={name}
                label={label}
                isVisible={values?.dairy_farms?.sub_types[name]}
                icon={icon}
                initialValue={getInitialValue(name)}
                validationError={validationError}
              />
            ))}
          </Card>
        ) : (
          <EmptyProducerContent />
        )}
      </Card>
    </>
  );
};

MilkTypesComponent.defaultProps = {
  validationError: null,
};

MilkTypesComponent.propTypes = {
  onChangeSubTypeSelection: PropTypes.func.isRequired,
  validationError: PropTypes.shape(),
};

export default MilkTypesComponent;
