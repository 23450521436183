import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Card from 'components/base-components/Card';
import useCustomNavigate from 'hooks/useCustomNavigate';
import addressView from 'utils/address-view';
import Button from 'components/base-components/Button';
import { useParams } from 'react-router-dom';
import ADDRESS_TYPE from '../../../../utils/address-type';
import OverviewContent from './overview-content.component';

const OtherAddress = ({ organisation, isFetching, isAdmin }) => {
  const { invoice, mailing } = ADDRESS_TYPE;
  const mailingAddress = organisation?.addresses?.find(address => address.type === mailing);
  const billingAddress = organisation?.addresses?.find(address => address.type === invoice);
  const buttonLabel = isAdmin
    ? t('organisation_view.overview.edit_address')
    : t('organisation_view.overview.add_address');

  const navigate = useCustomNavigate();
  const { id } = useParams();

  return (
    <Card className="mb-3 sm:mb-4">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
      ) : (
        <>
          <span className="inline-block text-lg font-bold mb-2">
            {t('organisation_view.overview.attributes.other_addresses')}
          </span>
          <div>
            <OverviewContent
              label={t('organisation_view.overview.mailing_address')}
              value={addressView(mailingAddress)}
              noMargin={true}
            />
            <Button
              className="mt-2"
              label={buttonLabel}
              size="small"
              onClick={() => navigate.openOrganisationChangeAddressPage(id, 'mailing')}
            />
            <OverviewContent
              className="mt-4"
              label={t('organisation_view.overview.invoice_mailing_address')}
              value={addressView(billingAddress)}
              noMargin={true}
            />
            <Button
              className="mt-2"
              label={buttonLabel}
              size="small"
              onClick={() => navigate.openOrganisationChangeAddressPage(id, 'billing')}
            />
          </div>
        </>
      )}
    </Card>
  );
};

OtherAddress.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default OtherAddress;
