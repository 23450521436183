import React, { useState } from 'react';
import { useGetUserQuery, useUpdateUserPasswordMutation } from 'api/users';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import { useLogOutMutation } from 'api/session';
import { useDisableTwoFactorAuthenticationMutation } from 'api/two-factor-authentication';
import { Text } from 'components/base-components/Typography';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import TwoFactorConfigurationStatus from './two-factor/configuration-status.component';
import '../users.styles.scss';

const CredentialForm = ({ setEnableConfiguration }) => {
  const [openTwoFactorDisableModal, setOpenTwoFactorDisableModal] = useState(false);
  const [password, setPassword] = useState(null);
  const [updateUserPassword, { isSuccess, isError, requestId }] = useUpdateUserPasswordMutation();
  const [disableTwoFactorAuthentication, { error: { data: errorResponse } = {} }] =
    useDisableTwoFactorAuthenticationMutation();

  const { userId } = useParams();
  const { data: userData } = useGetUserQuery({ id: userId });
  const [userLogOut] = useLogOutMutation();
  const emailOptions = [
    {
      label: userData?.email,
      value: userData?.email,
    },
  ];

  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const logoutCurrentUser = () => {
    userLogOut()
      .unwrap()
      .then(() => navigate.openLoginPage());
  };

  const onSubmit = (values, form) => {
    return updateUserPassword(values)
      .unwrap()
      .then(() => form.reset())
      .catch(({ data: { errors, errorMsg } }) =>
        errorMsg ? { base: errorMsg } : toSnakecaseKeys(errors),
      );
  };

  const onDisablingTwoFactorAuthentication = () => {
    disableTwoFactorAuthentication({ password })
      .unwrap()
      .then(data => {
        setOpenTwoFactorDisableModal(false);
      });
  };

  return (
    <>
      <WarningModal
        isVisible={openTwoFactorDisableModal}
        onConfirm={onDisablingTwoFactorAuthentication}
        onCancel={() => setOpenTwoFactorDisableModal(false)}
        title={t('user_credential.two_factor.modal.header.disable')}
        content={
          <div>
            {t('user_credential.two_factor.modal.content.disable')}
            <Input
              value={password}
              label={t('user_credential.two_factor.enter_password')}
              type="password"
              onChange={setPassword}
              required={true}
              placeholder={t('user_credential.two_factor.enter_password')}
              touched={!!errorResponse?.error}
              error={errorResponse?.error}
              isClearable={false}
            />
          </div>
        }
        confirmActionText={t('shared.action.disable')}
        cancelActionText={t('shared.action.cancel')}
      />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitErrors, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="user__credential-form">
              <div className="col-span-6  user__credential-email">
                <Field name="email" initialValue={userData?.email}>
                  {({ input, meta }) => (
                    <SelectBox
                      size="small"
                      label={t('user_credential.email.label')}
                      placeholderText={t('user_credential.email.placeholder')}
                      isClearable={false}
                      options={emailOptions}
                      value={emailOptions.find(option => option.value === input.value)}
                      selectedValue={input.label}
                      onChange={e => input.onChange(e.value)}
                      touched={!meta.dirtySinceLastSubmit}
                      errorMsg={meta?.submitError}
                    />
                  )}
                </Field>
              </div>
              <div className="col-span-12  user__credential-email-explanation">
                {t('user_credential.login_email_explanation')}
              </div>
              <div className="col-span-6  user__credential-oldpassword">
                <Field name="old_password" initialValue="">
                  {({ input, meta }) => (
                    <Input
                      label={t('user_credential.old_password.label')}
                      type="password"
                      placeholder={t('user_credential.old_password.placeholder')}
                      {...input}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta?.submitError}
                    />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2  direction-row">
                <div className="col-span-1">
                  <Field name="password" initialValue="">
                    {({ input, meta }) => (
                      <Input
                        label={t('user_credential.new_password.label')}
                        type="password"
                        placeholder={t('user_credential.new_password.placeholder')}
                        {...input}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta?.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-span-1 user__credential-form--confirm-password">
                  <Field name="password_confirmation" initialValue="">
                    {({ input, meta }) => (
                      <Input
                        label={t('user_credential.confirm_new_password.label')}
                        type="password"
                        placeholder={t('user_credential.confirm_new_password.placeholder')}
                        {...input}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta?.submitError}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="col-span-12  user__credential--footer">
                <Button
                  label={t('user_credential.update_button')}
                  type="success"
                  size="small"
                  submitType="submit"
                  disabled={submitting}
                />
              </div>
            </div>
            <SubmitModal
              isSuccess={isSuccess}
              isError={isError}
              requestId={requestId}
              successTitle={t('user_credential.update_successfull')}
              successContent={t('user_credential.update_successfull_text')}
              errorTitle={t('user_credential.update_failed')}
              errorContent={submitErrors?.base}
              onSuccess={logoutCurrentUser}
              showLoader={false}
              showError={!!submitErrors?.base}
            />
          </form>
        )}
      />
      <div className="mt-8">
        <TwoFactorConfigurationStatus enabled={userData?.twoFactorEnabled} />
      </div>
      <Text className="mt-4">{t('user_credential.two_factor.disable_message')}</Text>
      <div className="mt-4 user-two-factor-configuration__action">
        <Button
          label={t('user_credential.two_factor.configure')}
          type="success"
          size="small"
          submitType="submit"
          onClick={() => setEnableConfiguration(true)}
        />
        <Button
          label={t('shared.action.disable')}
          size="small"
          submitType="submit"
          onClick={() => setOpenTwoFactorDisableModal(true)}
          disabled={!userData?.twoFactorEnabled}
        />
      </div>
    </>
  );
};

CredentialForm.propTypes = {
  setEnableConfiguration: PropTypes.func.isRequired,
};

export default CredentialForm;
