import React from 'react';
import { t } from 'i18next';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import { getCertificationStatus } from 'utils/get-certification-status';
import Anerkennung from './anerkennung.component';
import OverviewContent from './overview-content.component';

const Status = ({ organisation, isFetching }) => {
  return (
    <Card className="mb-3 sm:mb-4">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={6} showBackground={false} />
      ) : (
        <>
          <span className="inline-block text-lg font-bold mb-2">
            {t('producer.dashboard.status')}
          </span>
          <div className="grid sm:grid-cols-3 gap-x-3 gap-y-0 capitalize">
            <OverviewContent
              className="col-span-1"
              label={t('organisation_view.overview.attributes.status.bio_suisse_status')}
              value={organisation?.bioSuisseStatus?.toUpperCase()}
            />
            <OverviewContent
              className="col-span-1"
              label={t('organisation_view.overview.attributes.status.certification_status')}
              value={getCertificationStatus(organisation?.certificationStatus)?.toUpperCase()}
            />
            <OverviewContent
              className="col-span-1"
              label={t('organisation_view.overview.attributes.status.organisation_status')}
              value={
                organisation?.status &&
                t(`organisation_view.overview.attributes.tag_status.${organisation.status}`)
              }
            />
            <OverviewContent
              className="col-span-1"
              label={t('producer.dashboard.contract_status')}
              noMargin={true}
              value={
                organisation?.contractStatus &&
                t(`contract.attributes.${organisation?.contractStatus}`)
              }
            />
            <Anerkennung organisation={organisation} noMargin={true} />
            <OverviewContent
              className="col-span-1"
              label={t('organisation_view.overview.attributes.status.receives_tvd_stickers')}
              value={
                organisation?.tvdStickerEnabled ? t(`shared.action.yes`) : t(`shared.action.no`)
              }
              noMargin={true}
            />
          </div>
        </>
      )}
    </Card>
  );
};

Status.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default Status;
