import React from 'react';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Card from 'components/base-components/Card';
import './education-loader.styles.scss';

const EducationLoader = () => {
  return (
    <Card>
      <div className="user__education-loader">
        <div className="user__education-loader--first-row col-grid  direction-row">
          <div>
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div>
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div>
          <div className=" col-span-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div>
          <div className=" col-span-2">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="offset-5 col-span-2 ">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div>
          <div className=" col-span-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className=" offset-2 col-span-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div>
          <div className=" col-span-2">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className=" offset-5 col-span-2 ">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
        <div>
          <div className=" col-span-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
          <div className="offset-2 col-span-5">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EducationLoader;
