import { useGetOrganizationQuery } from 'api/organizations';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import OrganisationHeadComponent from 'components/organisations/organisation-head.component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { t } from 'i18next';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router';
import '../local-unit.styles.scss';

const TVDPlaceOrder = () => {
  //TODO: Will be updated when backend is ready

  const navigate = useCustomNavigate();
  const onSubmit = () => {};
  const { id } = useParams();
  const { data: organisation, isSuccess: isOrganisationFetched } = useGetOrganizationQuery(id);
  return (
    <div>
      {isOrganisationFetched && (
        <OrganisationHeadComponent organisation={organisation} showContract={false} />
      )}
      <div className="grid  grid-cols-12 justify-center">
        <Card className="col-span-5 user__general-form--card">
          <div className="tvd__place-order">{t('tvd.place_order.header')}</div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="col-span-6">
                  <Field name="tvdnr">
                    {({ input }) => (
                      <Input
                        size="tiny"
                        label={t('tvd.tvd_no')}
                        placeholder={t('tvd.placeholder.tvd_number')}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-span-12">
                  <div className="col-span-4">
                    <Field name="spoken_languages">
                      {({ input }) => (
                        <SelectBox
                          size="tiny"
                          width="large"
                          label={t('tvd.place_order.item')}
                          placeholderText={t('tvd.place_order.placeholder.item')}
                          isClearable={false}
                          selectedValue={input.label}
                          onChange={e => input.onChange(e.value)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-span-4">
                    <Field name="item">
                      {({ input }) => (
                        <Input
                          size="tiny"
                          label={t('tvd.place_order.item')}
                          placeholder={t('tvd.place_order.placeholder.quantity')}
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-span-4">
                    <Field name="price_per_quantity">
                      {({ input }) => (
                        <SelectBox
                          size="tiny"
                          width="large"
                          label={t('tvd.place_order.price_per_quantity')}
                          placeholderText={t('tvd.place_order.placeholder.price_per_quantity')}
                          isClearable={false}
                          selectedValue={input.label}
                          onChange={e => input.onChange(e.value)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="col-span-12 user__general-form--footer">
                  <Button
                    label={t('tvd.form.add_tvd')}
                    type="success"
                    size="small"
                    disabled={submitting}
                    submitType="submit"
                  />
                  <Button
                    className="user__general-form--footer__cancel-btn"
                    size="small"
                    label={t('user_general.cancel_button')}
                    onClick={() => navigate.openOrganisationViewPage(id, 'tvd')}
                  />
                </div>
              </form>
            )}
          />
        </Card>
      </div>
    </div>
  );
};

export default TVDPlaceOrder;
