import React, { Fragment } from 'react';
import {
  useDeleteUserEducationMutation,
  useGetEducationDeclarationQuery,
  useGetUsersEducationQuery,
} from 'api/educations';
import VerificationTag from 'components/shared-components/verification-tag';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import SidePanel from 'components/base-components/SidePanel';
import DeleteModal from 'components/shared-components/delete-modal';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import PropTypes from 'prop-types';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import EducationLoader from '../loaders/EducationLoader';
import EducationForm from './education-form.component';

const EducationCard = ({
  education,
  setIsSidePanelOpen,
  setEditEducation,
  setDeleteCallbackParams,
}) => {
  const { t } = useTranslation();
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);

  const { data: declaration } = useGetEducationDeclarationQuery({
    educationDeclarationId: education.declarationId,
  });

  return (
    <Card>
      <div className="grid grid-cols-12">
        {isEditButtonClicked ? (
          <EducationForm setIsEditButtonClicked={setIsEditButtonClicked} />
        ) : (
          <div className="col-span-9">
            <div>
              <div className="user__education--card__header">
                {t('user_details.education.table_head.degree')}
              </div>
              <div className="user__education--card__content">
                {t(`educations.degree.${education.degree}`)}
                <VerificationTag status={declaration?.status} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 mt-6">
              <div className="col-span-1">
                <div className="user__education--card__header">
                  {t('user_details.education.table_head.institute')}
                </div>
                <div className="user__education--card__content">
                  {education.institute.toUpperCase() || t('common.not_applicable')}
                </div>
              </div>
              <div className="col-span-1">
                <div className="user__education--card__header">
                  {t('user_details.education.table_head.certification')}
                </div>
                <div className="user__education--card__content">
                  {education.certificationId || t('common.not_applicable')}
                </div>
              </div>
              <div className="col-span-1">
                <div className="user__education--card__header">
                  {t('user_details.education.table_head.doc')}
                </div>
                <div className="user__education--card__content">
                  {education.certificationDate
                    ? dateMonthYearFormatter(education.certificationDate)
                    : t('common.not_applicable')}
                </div>
              </div>
              <div className="col-span-1">
                <div className="user__education--card__header">
                  {t('user_details.education.table_head.dod')}
                </div>
                <div className="user__education--card__content">
                  {education.declarationDate
                    ? dateMonthYearFormatter(education.declarationDate)
                    : t('common.not_applicable')}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="user__education--card__icon col-span-3">
          <IconButton
            className="user__education-action--show"
            icon={<Icon name="delete" size="small" />}
            color="tertiary"
            onClick={() => {
              setDeleteCallbackParams({ educationId: education?.id });
            }}
            disabled={!declaration?.modifiable}
          />
          <IconButton
            className="user__education-action--show"
            icon={<Icon name="edit" size="small" />}
            color="tertiary"
            onClick={() => {
              setIsSidePanelOpen(true);

              setEditEducation(education);
            }}
            disabled={!declaration?.modifiable}
          />
          <a href={education.diplomaForEducationUrl} target="_blank" rel="noreferrer">
            <IconButton
              className="user__education-action--show"
              icon={<Icon name="downloadFile" size="small" />}
              color="tertiary"
              disabled={!education.diplomaForEducationUrl}
            />
          </a>
        </div>
      </div>
    </Card>
  );
};

EducationCard.propTypes = {
  education: PropTypes.shape().isRequired,
  setIsSidePanelOpen: PropTypes.func.isRequired,
  setEditEducation: PropTypes.func.isRequired,
  setDeleteCallbackParams: PropTypes.func.isRequired,
};

const EducationEditComponent = ({ userId }) => {
  const { t } = useTranslation();
  const [deleteEducation, deleteStatus] = useDeleteUserEducationMutation();
  const [editEducation, setEditEducation] = useState(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [deleteCallbackParams, setDeleteCallbackParams] = useState(null);
  const id = useId();

  const {
    data: userEducationFormData,
    isFetching,
    isError,
  } = useGetUsersEducationQuery(userId, { refetchOnMountOrArgChange: true });

  const onDelete = () => deleteEducation(deleteCallbackParams);

  return (
    <>
      {deleteCallbackParams && (
        <DeleteModal
          modelName="education"
          callback={onDelete}
          status={deleteStatus}
          deleteCallbackParams={deleteCallbackParams}
          setDeleteCallbackParams={setDeleteCallbackParams}
          customTranslation={true}
        />
      )}
      <div className="user__education--container">
        <div className="user__education--header">
          <div className="user__form--title">{t('user_edit.education_tab')}</div>
          <Button
            type="success"
            size="small"
            label={t('user_contact.add_button')}
            onClick={() => {
              setIsSidePanelOpen(true);
              setEditEducation(null);
            }}
          />
        </div>
        <>
          {isError || isFetching ? (
            <div className="user__loader--wrapper">
              <EducationLoader />
              <EducationLoader />
            </div>
          ) : userEducationFormData?.length > 0 ? (
            userEducationFormData.map((education, index) => (
              <div key={`${id}--${index}`}>
                <EducationCard
                  education={education}
                  setIsSidePanelOpen={setIsSidePanelOpen}
                  setEditEducation={setEditEducation}
                  setDeleteCallbackParams={setDeleteCallbackParams}
                />
              </div>
            ))
          ) : (
            <EmptyPlaceholder
              emptyCardText={t('user_details.education.empty_placeholder')}
              emptyIcon={<Icon name="stickyNote" />}
            />
          )}

          <SidePanel
            isOpened={isSidePanelOpen}
            title={
              editEducation
                ? t('user_edit.education_edit_title')
                : t('user_edit.education_create_title')
            }
            body={
              isSidePanelOpen && (
                <EducationForm
                  setIsSidePanelOpen={setIsSidePanelOpen}
                  editEducation={editEducation}
                />
              )
            }
            onClose={() => setIsSidePanelOpen(false)}
            onClick={() => setIsSidePanelOpen(false)}
          />
        </>
      </div>
    </>
  );
};

EducationEditComponent.propTypes = {
  userId: PropTypes.node.isRequired,
};

export default EducationEditComponent;
