import React from 'react';
import { useSyncAllOrganizationMutation } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import classNames from 'classnames';
import Badge from 'components/base-components/Badge';
import Button from 'components/base-components/Button';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import useDebounce from 'hooks/useDebounce';
import useNavigationLinks from 'hooks/useNavigationLinks';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { PRODUCER } from 'utils/organisation-industries';
import Icon from '../../base-components/Icon';
import Input from '../../base-components/Input';
import '../organisations.styles.scss';
import OrganisationFilterModal from './filter/organisation-filter-modal';
import OrganizationsTable from './table.component';

const TabContent = ({ tab, searchQuery, organizations, onChangeParams, isLoading, industry }) => {
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const navigationLinks = useNavigationLinks();
  const [totalFilterCount, setTotalFilterCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [syncAll, syncStatus] = useSyncAllOrganizationMutation();

  const pathName = location?.pathname?.slice(1);

  const industryName = {
    producers: t('organisation.create_producer'),
    restaurants: t('organisation.create_restaurant'),
    'control-body': t('organisation.create_control_body'),
  };

  const handleLink = () => {
    const linkName = {
      organisations: navigationLinks.organisationsCreatePage(),
      producers: navigationLinks.producerCreatePage(),
      restaurants: navigationLinks.restaurantCreatePage(),
      'control-body': navigationLinks.controlBofyCreatePage(),
    };

    return linkName[pathName];
  };

  const setSearchQueryInParams = useDebounce(() => {
    onChangeParams({ q: searchInput, page: 1 });
  });

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  return (
    <Fragment>
      <div className="organisation__search-tab--wrapper">
        <div className="organisation__search-tab">
          <Input
            className="organisation__search"
            placeholder={t('organisation.search_organisation')}
            preIcon={<Icon name="search" />}
            size="tiny"
            value={searchInput}
            onChange={onSearch}
          />
          <Button
            className={classNames({ 'organisation__search-tab__filter-button': totalFilterCount })}
            onClick={() => setIsFilterVisible(true)}
            label={
              <span className="organisation__filter-button">
                <Icon fill="none" name="filter" />
                <span>{t('shared.action.filter')}</span>
                {totalFilterCount > 0 && (
                  <Badge
                    classNameForBadge="modal__content--checkbox-badge"
                    label={totalFilterCount}
                    type="default"
                  />
                )}
              </span>
            }
            size="small"
          />
        </div>
        {isAdmin && (
          <div>
            {industry === PRODUCER && (
              <>
                <Button
                  className="organisation__sync-all"
                  size="small"
                  label={t('organisation.sync.all_button')}
                  onClick={syncAll}
                />
                <SubmitModal
                  isLoading={syncStatus.isLoading}
                  isSuccess={syncStatus.isSuccess}
                  isError={syncStatus.isError}
                  requestId={syncStatus.requestId}
                  successTitle={t('organisation.sync.all.title')}
                  successContent={t('organisation.sync.all.description')}
                  errorTitle={t('organisation.sync.error.title')}
                  errorContent={t('organisation.sync.error.description')}
                  showError={true}
                  showLoader={true}
                />
              </>
            )}
            <Link to={handleLink()}>
              <Button type="success" size="small" label={industryName[pathName]} />
            </Link>
          </div>
        )}
      </div>
      <div className="col-span-12 ">
        <OrganizationsTable
          organizations={organizations}
          isLoading={isLoading}
          parentOrganisationId={id}
          industry={industry}
          tab={tab}
        />
      </div>
      <OrganisationFilterModal
        setFilterQueryParams={onChangeParams}
        isFilterVisible={isFilterVisible}
        setIsFilterVisible={setIsFilterVisible}
        setTotalFilterCount={setTotalFilterCount}
      />
    </Fragment>
  );
};

TabContent.defaultProps = {
  searchQuery: '',
  organizations: null,
  onChangeParams: () => {},
  isLoading: false,
};

TabContent.propTypes = {
  searchQuery: PropTypes.string,
  organizations: PropTypes.shape(),
  onChangeParams: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedFilters: PropTypes.shape().isRequired,
  industry: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default TabContent;
