import {
  useGetOfflineSesstionsQuery,
  useVerifyOfflineSessionMutation,
} from 'api/control-body-survey/offline-sessions';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import YesNoInput from 'components/base-components/YesNoInput';
import Textarea from 'components/base-components/Textarea';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';
import React from 'react';
import { Field, Form } from 'react-final-form';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { ADMIN } from 'utils/users-role';
import { boolToYesNo } from 'utils/yes-no-helper';
import NegativeReportsHeader from '../header.component';
import AdditionalTaskSubmitButton from '../helpers/additional-task-submit-button.component';
import NegativeTaskModal from '../helpers/negative-task-modal.component';

const Information = ({ surveyId }) => {
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { data: offlineSessions = [], isFetching } = useGetOfflineSesstionsQuery({
    surveyId,
    session_for: 'audio_recording',
  });

  const activeSession = offlineSessions.find(session => session.confirmedById !== null);
  const offlineSession = activeSession || offlineSessions[0];

  const [verify, approveStatus] = useVerifyOfflineSessionMutation();

  if (isFetching)
    return (
      <div className="col-span-12">
        <ContentLoaderPlaceholder numberOfLines={2} isRounded={true} />
      </div>
    );

  if (!offlineSession) return null;

  const onSave = values => {
    let payload = values;
    if (values.satisfied === 'yes') payload['satisfied'] = true;
    else if (values.satisfied === 'no') payload['satisfied'] = false;

    return verify({ sessionId: offlineSession.id, payload })
      .unwrap()
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <div className="negative-reports__container">
      <NegativeTaskModal approveStatus={approveStatus} />

      <Card>
        <NegativeReportsHeader
          status={offlineSession.satisfied === null ? 'draft' : 'approved'}
          text={t('negative_report.information')}
          date={offlineSession.createdAt}
          dueDate={offlineSession.dueDate}
        />

        {isBioSuisseAdmin(currentUser) ? (
          <Form
            onSubmit={onSave}
            render={({ handleSubmit, submitting, submitErrors }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2">
                  <div className="col-span-1 negative-reports__left-panel ">
                    <Field name="satisfied" initialValue={boolToYesNo(offlineSession.satisfied)}>
                      {({ input, meta }) => (
                        <YesNoInput
                          className="custom-yes-no-input"
                          {...input}
                          error={meta.submitError}
                          disabled={offlineSession.satisfied !== null}
                          label={t('negative_report.satisfaction_question')}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-span-1 negative-reports__right-panel">
                    <Field name="comment" initialValue={offlineSession.comment}>
                      {({ input, meta }) => (
                        <Textarea
                          size="small"
                          disabled={offlineSession.satisfied !== null}
                          label={t('comment.label')}
                          placeholder={t('control_body.survey.form.comments_placeholder')}
                          touched={!meta.dirtySinceLastSubmit}
                          error={meta.submitError}
                          width="full"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-span-12">
                    <AdditionalTaskSubmitButton
                      status={offlineSession.satisfied === null ? 'submitted' : 'approved'}
                      disabled={submitting}
                      approverType={ADMIN}
                    />
                  </div>
                </div>
              </form>
            )}
          />
        ) : (
          <Text className="negative-reports__header-wrapper">
            {t('negative_report.session_conduct_message')}
          </Text>
        )}
      </Card>
    </div>
  );
};

Information.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default Information;
