import React from 'react';
import PropTypes from 'prop-types';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { currentMonthNameWithYear, nextMonthNameWithYear } from 'utils/month-year-formatter';
import { APPROVED, SUBMITTED } from 'utils/verification-status';
import MonthlyData from './monthly-data.component';

const GoodsEntity = ({ goods, goodsDeclarationSummary, showEditButton }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const isCurrentUserAdmin = isBioSuisseAdmin(currentUser);

  const isUpdateDisabled =
    goods?.status === APPROVED || goods?.status === SUBMITTED || isCurrentUserAdmin;

  return (
    <div className="grid grid-cols-12 mt-4 goods__container">
      <div className="col-span-3  goods__subcontainer">
        <MonthlyData
          goodsId={goods?.id}
          month={1}
          period={currentMonthNameWithYear(goods?.periodFrom)}
          bioCertifiedAmount={goods.month1ShareBio}
          shareBioAveragePercentage={goods.month1ShareBioPercentage}
          bioBudCertifiedAmount={goods.month1ShareKnospe}
          shareBioBudAveragePercentage={goods.month1ShareKnospePercentage}
          totalAmount={goods.month1TotalAmount}
          disabled={isUpdateDisabled}
          showEditButton={showEditButton}
        />
      </div>
      <div className="col-span-3  goods__subcontainer">
        <MonthlyData
          goodsId={goods?.id}
          month={2}
          period={nextMonthNameWithYear(goods?.periodFrom)}
          bioCertifiedAmount={goods.month2ShareBio}
          shareBioAveragePercentage={goods.month2ShareBioPercentage}
          bioBudCertifiedAmount={goods?.month2ShareKnospe}
          shareBioBudAveragePercentage={goods?.month2ShareKnospePercentage}
          totalAmount={goods.month2TotalAmount}
          disabled={isUpdateDisabled}
          showEditButton={showEditButton}
        />
      </div>
      <div className="col-span-3  goods__subcontainer">
        <MonthlyData
          goodsId={goods?.id}
          month={3}
          period={currentMonthNameWithYear(goods?.periodUntil)}
          bioCertifiedAmount={goods.month3ShareBio}
          shareBioAveragePercentage={goods.month3ShareBioPercentage}
          bioBudCertifiedAmount={goods?.month3ShareKnospe}
          shareBioBudAveragePercentage={goods?.month3ShareKnospePercentage}
          totalAmount={goods.month3TotalAmount}
          disabled={isUpdateDisabled}
          showEditButton={showEditButton}
        />
      </div>
      <div className="col-span-3  goods__subcontainer">{goodsDeclarationSummary}</div>
    </div>
  );
};
GoodsEntity.defaultProps = {
  showEditButton: false,
};

GoodsEntity.propTypes = {
  goods: PropTypes.shape().isRequired,
  showEditButton: PropTypes.bool,
  goodsDeclarationSummary: PropTypes.node.isRequired,
};

export default GoodsEntity;
