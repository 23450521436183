import {
  useGetAccountingProcessesQuery,
  useGetActiveAccountingProcessesQuery,
} from 'api/control-body-survey';
import {
  useApproveAccountingProcessesAdditionalTaskMutation,
  useDeclareAccountingProcessesAdditionalTaskMutation,
  useGetActiveAccountingProcessesAdditionalTaskQuery,
} from 'api/control-body-survey/accounting-processes';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import SelectBox from 'components/base-components/SelectBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { ADMIN, USER } from 'utils/users-role';
import { DRAFT, SUBMITTED } from 'utils/verification-status';
import NegativeReportsHeader from '../header.component';
import AdditionalTaskSubmitButton from '../helpers/additional-task-submit-button.component';
import NegativeTaskModal from '../helpers/negative-task-modal.component';

const AccountingProcess = ({ surveyId }) => {
  const { t } = useTranslation();
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { data: activeAdditionalTask, isFetching } =
    useGetActiveAccountingProcessesAdditionalTaskQuery({ surveyId });

  const { data: accountingProcessesOptions = [] } = useGetAccountingProcessesQuery();
  const { data: activeAccountingProcess = {} } = useGetActiveAccountingProcessesQuery({ surveyId });

  const [declare, submitStatus] = useDeclareAccountingProcessesAdditionalTaskMutation();
  const [approve, approveStatus] = useApproveAccountingProcessesAdditionalTaskMutation();

  if (isFetching)
    return (
      <div className="negative-reports__container">
        <ContentLoaderPlaceholder numberOfLines={6} isRounded={true} />
      </div>
    );
  if (!activeAdditionalTask) return null;

  const onSave = values => {
    if (activeAdditionalTask.status === SUBMITTED) {
      return approve({ surveyId });
    } else {
      return declare({ surveyId, payload: values })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <div className="negative-reports__container">
      <NegativeTaskModal submitStatus={submitStatus} approveStatus={approveStatus} />
      <Card>
        <NegativeReportsHeader
          status={activeAdditionalTask.status}
          text={t('negative_report.accounting_process')}
          date={activeAdditionalTask.createdAt}
          dueDate={activeAdditionalTask.dueDate}
        />

        <Form
          onSubmit={onSave}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2">
                <div className="col-span-1 negative-reports__left-panel">
                  <ul className="negative-reports__item-list">
                    {accountingProcessesOptions.map(option => (
                      <li>
                        <div className="negative-reports__item-list__wrapper">
                          <span>{t(`control_body.survey.form.radio.${option.value}`)}</span>
                          {option.value === activeAccountingProcess.type
                            ? t('negative_report.selected')
                            : t('negative_report.not_selected')}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-span-1 negative-reports__right-panel">
                  <Field name="type" initialValue={activeAdditionalTask.type}>
                    {({ input, meta }) => (
                      <SelectBox
                        size="small"
                        label={t('negative_report.established_system')}
                        isDisabled={
                          activeAdditionalTask.status !== DRAFT || currentUser.role !== USER
                        }
                        isClearable={false}
                        options={accountingProcessesOptions}
                        value={accountingProcessesOptions.find(
                          option => option.value === input.value,
                        )}
                        onChange={e => input.onChange(e.value)}
                        touched={!meta.dirtySinceLastSubmit}
                        errorMsg={meta?.submitError}
                        required={true}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-span-12">
                  <AdditionalTaskSubmitButton
                    status={activeAdditionalTask.status}
                    disabled={submitting}
                    approverType={ADMIN}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

AccountingProcess.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default AccountingProcess;
