import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useVerifyPinMutation } from 'api/session/index';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import { Form, Field } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import Spinner from 'components/base-components/Spinner/Spinner';
import useCustomNavigate from '../../../hooks/useCustomNavigate';

const VerifyPinCodeForm = () => {
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [verificationToken, setVerifificationToken] = useState(null);
  const [verifyPinCode, { isLoading: isPinVerifingLoading }] = useVerifyPinMutation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const token = searchParams.get('token');

  const onVerifyPinCode = values => {
    const params = { pin_code: values['pin_code'], verify_token: token };
    return verifyPinCode(params)
      .unwrap()
      .then(result => {
        setVerifificationToken(result.verificationToken);
        setVerifyModalOpen(true);
      })
      .catch(({ data }) => data);
  };

  const modalContent = () => {
    if (isPinVerifingLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('user_verify.modal.loading_header')}
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('user_verify.modal.success_header')}</div>
          <div>{t('user_verify.modal.success_description')}</div>
        </div>
      </div>
    );
  };

  return (
    <WelcomePageComponent>
      <Form
        onSubmit={onVerifyPinCode}
        render={({ handleSubmit, pristine, submitting, submitErrors }) => (
          <div className="mt-6">
            <form onSubmit={handleSubmit}>
              <div className="mb-4 text-center">{t('signup.form.onboarding_pin')}</div>
              <Field name="pin_code">
                {({ input, meta }) => (
                  <Input
                    className="mt-6 w-[345px]"
                    label={t('signup.form.pin')}
                    placeholder="123456"
                    {...input}
                    touched={!meta.dirtySinceLastSubmit}
                    error={submitErrors?.base}
                  />
                )}
              </Field>
              <Button
                label={t('signup.form.action.verify')}
                className="w-[345px] mt-9"
                size="normal"
                type="success"
                fullWidth={true}
                submitType="submit"
                disabled={submitting || pristine}
              />
            </form>
          </div>
        )}
      />
      <ConfirmationModalWrapper
        isVisible={verifyModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent()}
        className="user__invite--modal"
        timeOut={!isPinVerifingLoading ? 3000 : 0}
        onTimeout={() => {
          setVerifyModalOpen(false);
          navigate.openCredentialPage(verificationToken);
        }}
      />
    </WelcomePageComponent>
  );
};

export default VerifyPinCodeForm;
