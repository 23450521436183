import { useGetIngredientReservesQuery } from 'api/control-body-survey';
import {
  useApproveIngredientReservesAdditionalTaskMutation,
  useDeclareIngredientReservesAdditionalTaskMutation,
  useGetActiveIngredientReservesAdditionalTaskQuery,
} from 'api/control-body-survey/ingredient-reserves';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Textarea from 'components/base-components/Textarea';
import YesNoInput from 'components/base-components/YesNoInput';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { CONTROL_BODY_USER, USER } from 'utils/users-role';
import { ACTIVE, DRAFT, SUBMITTED } from 'utils/verification-status';
import { boolToYesNo } from 'utils/yes-no-helper';
import NegativeReportsHeader from '../header.component';
import AdditionalTaskSubmitButton from '../helpers/additional-task-submit-button.component';
import NegativeTaskModal from '../helpers/negative-task-modal.component';
import ProductName from '../helpers/product-name.component';

const IngredientsInStock = ({ surveyId }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { data: activeAdditionalTask, isFetching } =
    useGetActiveIngredientReservesAdditionalTaskQuery({
      surveyId,
    });
  const { data: activeReservedIngredients = [], isFetching: isIngredientsFetching } =
    useGetIngredientReservesQuery({ surveyId, type: ACTIVE });

  const [declare, submitStatus] = useDeclareIngredientReservesAdditionalTaskMutation();
  const [approve, approveStatus] = useApproveIngredientReservesAdditionalTaskMutation();

  if (isFetching || isIngredientsFetching)
    return (
      <div className="negative-reports__container">
        <ContentLoaderPlaceholder numberOfLines={6} isRounded={true} />
      </div>
    );
  if (!activeAdditionalTask) return null;

  const requiredFileCount = activeReservedIngredients.filter(item => !item.inStock).length;

  const uploadedFilesCount =
    activeAdditionalTask.status !== DRAFT
      ? activeAdditionalTask.logos.length
      : uploadedFiles.length;

  const onSave = values => {
    if (activeAdditionalTask.status === SUBMITTED) {
      let payload = {};
      if (values.fulfilled === 'yes') payload['fulfilled'] = true;
      else if (values.fulfilled === 'no') payload['fulfilled'] = false;
      return approve({ surveyId, payload })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      const payload = new FormData();
      if (values.reason) {
        payload.append('reason', values.reason);
      }

      uploadedFiles.forEach(item => {
        payload.append('logos[]', item.file);
      });

      return declare({ surveyId, payload })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <div className="negative-reports__container">
      <NegativeTaskModal submitStatus={submitStatus} approveStatus={approveStatus} />
      <Card>
        <NegativeReportsHeader
          status={activeAdditionalTask.status}
          text={t('negative_report.ingredients_stock')}
          date={activeAdditionalTask.createdAt}
          dueDate={activeAdditionalTask.dueDate}
        />

        <Form
          onSubmit={onSave}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2">
                <div className="col-span-1 negative-reports__left-panel">
                  <div className="negative-reports__item-header">
                    {t('control_body.survey.form.second_step')}
                  </div>
                  <ul className="negative-reports__item-list">
                    {activeReservedIngredients.map(ingredient => (
                      <li key={ingredient.id}>
                        <div className="negative-reports__item-list__wrapper">
                          <span>
                            <ProductName productId={ingredient.productId} />
                          </span>
                          <span>
                            {t(`control_body.survey.shared.${ingredient.inStock ? 'yes' : 'no'}`)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-span-1 negative-reports__right-panel">
                  <div className="logo-attachment-task">
                    <div className="logo-attachment-task__header">
                      <div className="logo-attachment-task__header--title">
                        {t('negative_report.logo.uploaded_files')}
                      </div>
                      <div className="logo-attachment-task__header--files">
                        {uploadedFilesCount}/{requiredFileCount}{' '}
                        {t('negative_report.logo.uploaded')}
                        <ChooseFile
                          className="logo-attachment-task__header--upload-btn"
                          showText={false}
                          disabled={
                            uploadedFiles.length === requiredFileCount ||
                            activeAdditionalTask.status !== DRAFT ||
                            currentUser.role !== USER
                          }
                          onChange={file =>
                            setUploadedFiles(prev => [...prev, { file, id: new Date().getTime() }])
                          }
                          labelText={
                            <>
                              <Icon
                                className="choose-file__contract-delete--icon"
                                name="uploadFile"
                                size="normal"
                              />
                              <span className="choose-file__contract-delete--text">
                                {t('negative_report.logo.upload_logo')}
                              </span>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {activeAdditionalTask.status === DRAFT && currentUser.role === USER ? (
                    <>
                      <div>
                        {uploadedFiles.map(uploadedFile => (
                          <div className="col-span-6 logo-attachment-task__file-dynamic-margin">
                            <div className="logo-attachment-task__file-wrapper">
                              <div className="logo-attachment-task__file-info">
                                <Icon name="photo" color="tertiary" />
                                <span className="logo-attachment-task__file-info--name">
                                  {uploadedFile?.file.name}
                                </span>
                              </div>
                              <div>{bytesToMegabytes(uploadedFile?.file.size)}MB</div>
                              <IconButton
                                className="topnav__back-button"
                                color="danger"
                                icon={
                                  <Icon
                                    className="choose-file__contract-delete--icon"
                                    name="delete"
                                    size="small"
                                    color="danger"
                                  />
                                }
                                onClick={() => {
                                  const filteredImage = uploadedFiles.filter(
                                    file => file.id !== uploadedFile.id,
                                  );
                                  setUploadedFiles(filteredImage);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>

                      {!!submitErrors?.logos && (
                        <div className="logo-attachment-task__upload-errors">
                          {submitErrors?.logos}
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {activeAdditionalTask.logos.map(logo => (
                        <div className="col-span-6 logo-attachment-task__file-dynamic-margin">
                          <div className="logo-attachment-task__file-wrapper">
                            <div className="logo-attachment-task__file-info">
                              <Icon name="photo" color="tertiary" />
                              <span className="logo-attachment-task__file-info--name">
                                {logo?.filename}
                              </span>
                            </div>

                            <div className="organisation-checklist__audio-card--icon">
                              <span>{bytesToMegabytes(logo?.sizeInByte)} MB</span>
                              <a href={logo?.url} target="_blank" rel="noreferrer">
                                <IconButton
                                  className="topnav__back-button"
                                  icon={
                                    <Icon
                                      className="choose-file__contract-delete--icon"
                                      name="downloadFile"
                                      size="small"
                                    />
                                  }
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <Field name="reason" initialValue={activeAdditionalTask.declarationReason}>
                    {({ input, meta }) => (
                      <Textarea
                        disabled={
                          activeAdditionalTask.status !== DRAFT || currentUser.role !== USER
                        }
                        size="small"
                        label={t('control_body.survey.form.declare_reason')}
                        placeholder={t('control_body.survey.form.declare_reason_placeholder')}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                        required={true}
                        width="full"
                        {...input}
                      />
                    )}
                  </Field>

                  <div className="negative-reports__form-description">
                    {currentUser.role === USER ? (
                      <div>{t('control_body.survey.form.declare_reason_description')}</div>
                    ) : (
                      <Field
                        name="fulfilled"
                        initialValue={boolToYesNo(activeAdditionalTask.fulfilled)}
                      >
                        {({ input, meta }) => (
                          <YesNoInput
                            {...input}
                            error={meta.submitError}
                            disabled={
                              activeAdditionalTask.status !== SUBMITTED ||
                              currentUser.role !== CONTROL_BODY_USER
                            }
                            label={t('control_body.survey.form.instock_query')}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="col-span-12">
                  <AdditionalTaskSubmitButton
                    status={activeAdditionalTask.status}
                    disabled={submitting}
                    approverType={CONTROL_BODY_USER}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

IngredientsInStock.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default IngredientsInStock;
