import { useCreateTransactionMutation } from 'api/invoices';
import Button from 'components/base-components/Button';
import CheckBox from 'components/base-components/CheckBox';
import Input from 'components/base-components/Input';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { OPEN, PENDING } from 'utils/invoice-statuses';
import { moveToPreviousPage } from 'utils/navigation-utils';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';

const RecordPaymentForm = ({ invoice }) => {
  const navigate = useNavigate();
  const [create, status] = useCreateTransactionMutation();
  const isRecordPaymentOpen = invoice.status === OPEN || invoice.status === PENDING;

  const onSubmit = (values, form) => {
    const payload = removeEmptyFromObject(values);
    return create({ invoiceId: invoice.id, payload })
      .unwrap()
      .then(() => form.reset())
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values, form }) => {
          const isAutoFilled = values.fillCreditAmount || values.fillDebitAmount;
          const fillAmount = field => {
            if (field === 'received_amount') {
              form.change('written_off_amount', undefined);
              form.change('fillDebitAmount', false);
            } else {
              form.change('received_amount', undefined);
              form.change('fillCreditAmount', false);
            }

            form.change(field, invoice.totalRemainingDue);
          };

          const changeAmount = (field, value) => {
            if (field === 'received_amount') form.change('written_off_amount', undefined);
            else form.change('received_amount', undefined);

            form.change(field, value);
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-12">
                <div className="col-span-6">
                  <Field name="fillCreditAmount" type="checkbox">
                    {({ input }) => (
                      <CheckBox
                        isChecked={input.checked}
                        size="tiny"
                        label={t('invoice.record_payments.form.label.credit_checkbox')}
                        onChange={e => {
                          input.onChange(e.target.checked);
                          if (e.target.checked) {
                            fillAmount('received_amount');
                          }
                        }}
                      />
                    )}
                  </Field>
                  <Field name="received_amount">
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        onChange={value => changeAmount('received_amount', value)}
                        className="product-form__field--input"
                        error={meta?.submitError}
                        size="tiny"
                        label={t('invoice.record_payments.form.label.received_amount')}
                        placeholder={t('invoice.record_payments.form.enter_amount')}
                        touched={!meta.dirtySinceLastSubmit}
                        disabled={isAutoFilled || values.written_off_amount}
                      />
                    )}
                  </Field>
                  <Button
                    className="product-form__field--button"
                    label={t('shared.action.save')}
                    type="success"
                    size="small"
                    disabled={submitting || !isRecordPaymentOpen}
                    submitType="submit"
                  />
                  <Button
                    size="small"
                    label={t('invoice.record_payments.form.discard')}
                    onClick={() => navigate(moveToPreviousPage)}
                  />
                </div>
                <div className="col-span-6">
                  <Field name="fillDebitAmount" type="checkbox">
                    {({ input }) => (
                      <CheckBox
                        isChecked={input.checked}
                        size="tiny"
                        label={t('invoice.record_payments.form.label.debit_checkbox')}
                        onChange={e => {
                          input.onChange(e.target.checked);
                          if (e.target.checked) {
                            fillAmount('written_off_amount');
                          }
                        }}
                      />
                    )}
                  </Field>
                  <Field name="written_off_amount">
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        onChange={value => changeAmount('written_off_amount', value)}
                        className="product-form__field--input"
                        error={meta?.submitError}
                        size="tiny"
                        label={t('invoice.record_payments.form.label.written_off_amount')}
                        placeholder={t('invoice.record_payments.form.enter_amount')}
                        touched={!meta.dirtySinceLastSubmit}
                        disabled={isAutoFilled || values.received_amount}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </form>
          );
        }}
      />
      <SubmitModal
        isLoading={status.isLoading}
        isSuccess={status.isSuccess}
        requestId={status.requestId}
        successTitle={t('invoice.record_payments.modal.success_title')}
        successContent={t('invoice.record_payments.modal.success_description')}
        showError={false}
        showLoader={false}
      />
    </>
  );
};

RecordPaymentForm.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default RecordPaymentForm;
