import { useGetOrganizationsQuery } from 'api/organizations';
import { useVerifyTokenMutation } from 'api/public-registration';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { Header } from 'components/base-components/Typography';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNonEmptyArray } from 'utils/check-object-length';
import { dateMonthYear } from 'utils/date-time-formatter';

const PublicOrganisationComponent = () => {
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const [verifyToken, { isSuccess: isTokenVerified }] = useVerifyTokenMutation();

  const skipFetchOrganisation = () => {
    if (!token) return false;

    return !isTokenVerified;
  };

  const { data } = useGetOrganizationsQuery(
    {},
    {
      skip: skipFetchOrganisation(),
    },
  );

  const organisations = data?.organizations || [];

  useEffect(() => {
    token && verifyToken(token);
  }, [token]);

  return (
    <WelcomePageComponent onlyChildren={true}>
      <Card className="organisation__public">
        <Header weight="boldest" level={5}>
          {t('welcome_page.welcome_message')}
        </Header>
        <Button
          className="margin-top-30 organisation__public--create-button"
          type="success"
          size="small"
          label={t('organisation.create_organisation')}
          disabled={organisations.length === 3}
          onClick={() => navigate.openPublicOrganisationCreatePage()}
        />
        <div>
          {isNonEmptyArray(organisations) && (
            <div className="flex-space-between">
              <hr className="organisation__public--horizontal-line" />
              <div className="margin-top-20 organisation__public--horizontal-line__text">
                {t('public_organisation.select_draft')}
              </div>
              <hr className="organisation__public--horizontal-line" />
            </div>
          )}
          {organisations.map(organisation => (
            <Card className="margin-top-20 organisation__public--list">
              <div className="flex-space-between">
                <div>
                  <div className="font-bold">{organisation?.name}</div>
                  <div className="margin-top-5 flex">
                    <div>
                      {organisation?.uid
                        ? t('public_organisation.uid', { value: organisation.uid })
                        : t('common.not_applicable')}
                    </div>
                    <div className="margin-left-20 recognition__vertical-line" />
                    <div>
                      {t('public_organisation.last_updated', {
                        date: dateMonthYear(organisation.updatedAt) || t('common.not_applicable'),
                      })}
                    </div>
                  </div>
                </div>
                <IconButton
                  className="topnav__back-button"
                  isIconOnly={true}
                  icon={
                    <Icon
                      className="choose-file__contract-delete--icon"
                      name="arrowForwardAlt"
                      size="small"
                      showTooltip={true}
                      iconLabel={t('public_organisation.errorToolTip')}
                    />
                  }
                  onClick={() => navigate.openPublicOrganisationEditPage(organisation?.id)}
                />
              </div>
            </Card>
          ))}
        </div>
      </Card>
    </WelcomePageComponent>
  );
};

export default PublicOrganisationComponent;
