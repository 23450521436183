import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import classNames from 'classnames';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { useGetOrganizationQuery } from 'api/organizations';
import ChartaOverview from './charta-overview.component';
import SupplierCertificatesOverview from './supplier-certificates-overview.component';
import ProductDeclarationsOverview from './product-declarations-overview.component';
import GoodsOverviewComponent from './goods-overview.component';
import EducationsOverview from './educations-overview.component';
import ControlBodyOverview from './control-body-overview.component';
import './overview.styles.scss';

const OverviewComponent = ({
  chartaEnabled,
  setSearchParams,
  organizationId,
  declaredChartaPreviously,
}) => {
  const { data: organisation } = useGetOrganizationQuery(organizationId);

  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-1">
        <Card className="organisation-overview__item organisation-overview__item--no-left-margin">
          <ChartaOverview
            organizationId={organizationId}
            chartaEnabled={chartaEnabled}
            onClick={() => {
              setSearchParams({ tab: 'charta' });
            }}
          />
        </Card>
      </div>
      <div className="col-span-1">
        <Card
          className={classNames(
            'organisation-overview__item organisation-overview__item--no-left-margin',
            {
              'organisation-overview__item--disabled':
                !chartaEnabled || ORGANISATION_TYPE.sub_organisation === organisation?.type,
            },
          )}
        >
          <ControlBodyOverview
            organizationId={organizationId}
            chartaEnabled={chartaEnabled}
            onClick={() => {
              setSearchParams({ tab: 'control_body' });
            }}
          />
        </Card>
      </div>
      <div className="col-span-1">
        <Card
          className={classNames(
            'organisation-overview__item organisation-overview__item--no-left-margin',
            {
              'organisation-overview__item--disabled': !chartaEnabled,
            },
          )}
        >
          <EducationsOverview
            organizationId={organizationId}
            chartaEnabled={chartaEnabled}
            onClick={() => {
              setSearchParams({ tab: 'educations' });
            }}
          />
        </Card>
      </div>
      <div className="col-span-1">
        <Card
          className={classNames(
            'organisation-overview__item organisation-overview__item--no-left-margin',
            { 'organisation-overview__item--disabled': !chartaEnabled },
          )}
        >
          <SupplierCertificatesOverview
            organizationId={organizationId}
            chartaEnabled={chartaEnabled}
            onClick={() => {
              setSearchParams({ tab: 'supplier_certificate' });
            }}
          />
        </Card>
      </div>
      <div className="col-span-1">
        <Card
          className={classNames(
            'organisation-overview__item organisation-overview__item--no-left-margin',
            { 'organisation-overview__item--disabled': !declaredChartaPreviously },
          )}
        >
          <ProductDeclarationsOverview
            organizationId={organizationId}
            chartaEnabled={chartaEnabled}
            declaredChartaPreviously={declaredChartaPreviously}
            onClick={() => {
              setSearchParams({ tab: 'products' });
            }}
          />
        </Card>
      </div>
      <div className="col-span-1">
        <Card
          className={classNames(
            'organisation-overview__item organisation-overview__item--no-left-margin',
            {
              'organisation-overview__item--disabled': !chartaEnabled,
            },
          )}
        >
          <GoodsOverviewComponent
            organizationId={organizationId}
            chartaEnabled={chartaEnabled}
            onClick={() => {
              setSearchParams({ tab: 'goods' });
            }}
          />
        </Card>
      </div>
    </div>
  );
};

OverviewComponent.defaultProps = {
  chartaEnabled: false,
  setSearchParams: () => {},
  declaredChartaPreviously: false,
};

OverviewComponent.propTypes = {
  chartaEnabled: PropTypes.bool,
  setSearchParams: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  declaredChartaPreviously: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default OverviewComponent;
