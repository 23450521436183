import React from 'react';
import { useCreateUserEmailMutation, useUpdateUserEmailMutation } from 'api/users';
import Button from 'components/base-components/Button';
import CheckBox from 'components/base-components/CheckBox';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';

const EmailForm = ({ setIsEmailSidepanelOpen, editEmail }) => {
  const [createEmail] = useCreateUserEmailMutation();
  const [updateEmail] = useUpdateUserEmailMutation();
  const { userId } = useParams();
  const { t } = useTranslation();

  const emailTypeOptions = [
    {
      label: t('user.email_type.home'),
      value: 'home',
    },
    {
      label: t('user.email_type.work'),
      value: 'work',
    },
    {
      label: t('user.email_type.other'),
      value: 'other',
    },
  ];

  const onSubmit = (values, form) => {
    const payload = removeEmptyFromObject(values);

    if (editEmail) {
      return updateEmail({ params: { userId, id: editEmail.id }, payload })
        .unwrap()
        .then(() => {
          form.reset();
          setIsEmailSidepanelOpen(false);
        })
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      return createEmail({ userId, payload })
        .unwrap()
        .then(() => {
          form.reset();
          setIsEmailSidepanelOpen(false);
        })
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="user__edit--form-content">
            <div className="col-span-12 ">
              <Field name="email_type" initialValue={editEmail?.emailType || ''}>
                {({ input, meta }) => (
                  <SelectBox
                    size="small"
                    width="large"
                    label={t('user_contact.email_type.label')}
                    placeholderText={t('user_contact.email_type.placeholder')}
                    isClearable={false}
                    options={emailTypeOptions}
                    value={emailTypeOptions.find(option => option.value === input.value)}
                    selectedValue={input.label}
                    onChange={e => input.onChange(e.value)}
                    touched={!meta.dirtySinceLastSubmit}
                    errorMsg={meta.submitError}
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12 ">
              <Field name="email" initialValue={editEmail?.email || ''}>
                {({ input, meta }) => (
                  <Input
                    size="small"
                    label={t('user_contact.email_address.label')}
                    placeholder={t('user_contact.email_address.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="col-span-12  col-grid justify-center">
              <Field name="primary" type="checkbox" initialValue={editEmail?.primary || false}>
                {({ input, meta }) => (
                  <CheckBox
                    isChecked={input.checked}
                    size="tiny"
                    label={t('user_contact.email_check')}
                    onChange={e => input.onChange(e.target.checked)}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="col-span-12 ">
            <Button
              label={editEmail ? t('common.save_button') : t('user_education.add_button')}
              type="success"
              size="small"
              submitType="submit"
              className="user__edit--submit"
              disabled={submitting}
            />
          </div>
        </form>
      )}
    />
  );
};

EmailForm.defaultProps = {
  editEmail: null,
  setIsEmailSidepanelOpen: () => {},
};

EmailForm.propTypes = {
  editEmail: PropTypes.shape(),
  setIsEmailSidepanelOpen: PropTypes.func,
};

export default EmailForm;
