import React from 'react';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import { Trans } from 'react-i18next';
import Icon from 'components/base-components/Icon';
import OverviewContent from '../overview-content.component';

const AddressOverview = props => {
  const { address, isFetching, type, isAddressPresent, emptyPlaceholderTranslationKey } = props;

  return (
    <>
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
      ) : (
        <>
          {isAddressPresent ? (
            <Card className="min-h-[190px]">
              <div className="grid grid-cols-4 p-3">
                <span className="col-span-4 organisation-overview__card-title">{type}</span>
                <div className="col-span-2">
                  <OverviewContent
                    label={t('organisation_view.overview.address_header.address_line')}
                    value={address.line1}
                  />
                </div>
                <div className="col-span-2">
                  <OverviewContent
                    label={t('organisation_view.overview.address_header.address_suffix')}
                    value={address.line2}
                  />
                </div>
                <div className="col-span-1">
                  <OverviewContent
                    label={t('shared.label.address.city')}
                    value={address.city}
                    noMargin={true}
                  />
                </div>
                <div className="col-span-1">
                  <OverviewContent
                    label={t('control_body.input_field.zip')}
                    value={address.zipCode}
                    noMargin={true}
                  />
                </div>
                <div className="col-span-1">
                  <OverviewContent
                    label={t('organisation_view.overview.address_header.canton')}
                    value={address.canton}
                    noMargin={true}
                  />
                </div>
                <div className="col-span-1">
                  <OverviewContent
                    label={t('shared.label.address.country')}
                    value={address?.country?.name}
                    noMargin={true}
                  />
                </div>
              </div>
            </Card>
          ) : (
            <EmptyPlaceholder
              className="min-h-[190px]"
              emptyCardText={<Trans i18nKey={emptyPlaceholderTranslationKey} />}
              emptyIcon={<Icon name="add" color="tertiary" />}
            />
          )}
        </>
      )}
    </>
  );
};

AddressOverview.defaultProps = {
  address: {},
  type: null,
};

AddressOverview.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isAddressPresent: PropTypes.bool.isRequired,
  emptyPlaceholderTranslationKey: PropTypes.string.isRequired,
  address: PropTypes.shape({
    line1: PropTypes.node,
    line2: PropTypes.node,
    city: PropTypes.node,
    zipCode: PropTypes.node,
    canton: PropTypes.node,
    country: PropTypes.shape({
      name: PropTypes.node,
      code: PropTypes.node,
    }),
  }),
  type: PropTypes.node,
};

export default AddressOverview;
