import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import Input from 'components/base-components/Input/Input';
import Link from 'components/base-components/Link';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import React, { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import isControlBodyMember from 'utils/is-control-body-member';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { CONTROL_BODY_USER, OWNER } from 'utils/users-role';
import { useParams } from 'react-router-dom';
import '../users.styles.scss';
import { CONTROL_BODY } from 'utils/organisation-type';
import UsersTable from './table.component';
import '../users.styles.scss';

const TabContent = ({
  tab,
  onChangeParams,
  users,
  isLoading,
  organizationId,
  isOrganisationView,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page');

  const currentType = searchParams.get('type');

  const navigationLinks = useNavigationLinks();

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => {
      onChangeParams({ q: value, page: 1 }, tab);
    }),
  ).current;

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const { data: organisation } = useGetOrganizationQuery(organizationId);

  const isDeactivated = organisation?.status === ORGANISATIONS_STATUS.inactive;

  const isInviteEnabled = () => {
    if (currentUser.role === CONTROL_BODY_USER) {
      const isOwner = currentUser.assignments?.some(assignment => assignment.role === OWNER);
      if (isOwner) return true;
      return false;
    }
    return true;
  };

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  useEffect(() => {
    onChangeParams({ tab, page: 1 }, tab, true);
  }, []);

  return (
    <>
      <div className="user__list--header">
        <Input
          className="user__search align-self-start"
          placeholder={t('user.search')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={onSearch}
        />
        <div>
          {organizationId &&
            !isControlBodyMember(currentUser) &&
            organisation?.type !== CONTROL_BODY && (
              <RouterLink to={navigationLinks.organisationUserRolePage(id, currentPage)}>
                <Button size="small" label={t('user.roles.assign')} className="margin-right-10" />
              </RouterLink>
            )}
          {isInviteEnabled() && (
            <Link
              href={
                isOrganisationView
                  ? navigationLinks.usersInvitationPageFromOrganisation(organizationId)
                  : navigationLinks.usersInvitationPage(currentType)
              }
              modifier="success"
              type="button"
              disabled={isDeactivated || (organizationId && isControlBodyMember(currentUser))}
              size="small"
            >
              {t('user.invite_user')}
            </Link>
          )}
        </div>
      </div>
      <div className="col-span-12 ">
        <UsersTable
          users={users}
          isLoading={isLoading}
          isOrganisationView={isOrganisationView}
          organizationId={organizationId}
          tab={tab}
        />
      </div>
    </>
  );
};

TabContent.defaultProps = {
  users: [],
  organizationId: null,
};

TabContent.propTypes = {
  tab: PropTypes.string.isRequired,
  onChangeParams: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool.isRequired,
  organizationId: PropTypes.number,
  isOrganisationView: PropTypes.bool.isRequired,
};

export default TabContent;
