import { useGetCurrentUserQuery } from 'api/users';
import BioLogo from 'assets/bio_logo.png';
import Avatar from 'components/base-components/Avatar';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Popover from 'components/base-components/Popover';
import SidePanel from 'components/base-components/SidePanel';
import { Header } from 'components/base-components/Typography';
import BreadCrumbComponent from 'components/breadcrumb/breadcrumb-component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { useLogOutMutation } from 'api/session';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import breadcrumbRoutes from 'utils/breadcrum-helper/route-names';
import LanguageComponent from 'components/shared-components/language';
import { Link } from 'react-router-dom';
import useResponsive from 'hooks/useResponsive';
import PropTypes from 'prop-types';
import Topnav from '../base-components/Topnav';
import TopnavMessageComponent from './message/topnav-message.component';
import TopnavTaskComponent from './task/topnav-task.component';
import './topnav.styles.scss';

const TopnavComponent = ({ setIsSideNavOpen }) => {
  const [isUserPopoverVisible, setIsUserPopoverVisible] = useState(false);
  const [isTaskSidePanelOpen, setIsTaskSidePanelOpen] = useState(false);
  const [isMessageSidePanelOpen, setIsMessageSidePanelOpen] = useState(false);
  const [userLogOut] = useLogOutMutation();
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();
  const { isMobile } = useResponsive();

  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();

  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes(t));
  const logOut = () => {
    userLogOut()
      .unwrap()
      .then(() => (window.location.href = '/login'));
  };

  return (
    <>
      <Topnav
        imageSrc={
          isMobile ? (
            <IconButton
              icon={<Icon name="hamburgerMenu" />}
              size="huge"
              isIconOnly={true}
              onClick={() => setIsSideNavOpen(prev => !prev)}
            />
          ) : (
            <img src={BioLogo} alt="Bio-Suisse" height="50" width="50" />
          )
        }
        pageHeader={
          <Header level={5} weight="boldest">
            {breadcrumbs[breadcrumbs.length - 1].breadcrumb.props.children}
          </Header>
        }
        breadcrumbs={!isMobile && <BreadCrumbComponent breadcrumbs={breadcrumbs} />}
        actions={[
          <IconButton
            icon={<Icon size="large" name="chatAlt" />}
            color="default"
            onClick={() => setIsMessageSidePanelOpen(true)}
          />,
          <IconButton
            icon={<Icon size="medium" name="tasksAlt" />}
            color="default"
            onClick={() => setIsTaskSidePanelOpen(true)}
          />,
          <div class="w-px h-[26px] bg-light-lemon hidden md:block" />,
          !isMobile && <LanguageComponent />,
          <Popover
            isVisible={isUserPopoverVisible}
            onOutsideClick={() => setIsUserPopoverVisible(false)}
            direction="bottom-right"
            content={
              <>
                <div className="flex flex-col items-center justify-center">
                  <Avatar
                    className="mt-[14px]"
                    src={currentUser.profilePictureUrl}
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    size="large"
                    showImageModal={false}
                  />
                  <Link to={navigationLinks.userEditPage(currentUser.id)}>
                    <IconButton
                      className="relative bottom-[60px] left-6 hover:bg-transparent"
                      icon={<Icon name="edit" showBGColor={true} />}
                      color="default"
                      size="tiny"
                      label={t('common.edit_button')}
                      onClick={() => setIsUserPopoverVisible(false)}
                    />
                  </Link>
                  <div className="text-sm leading-[21px] mt-2 text-blueish-black">
                    {currentUser.name}
                  </div>
                  <div className="text-sm leading-[21px] text-blueish-black">
                    {currentUser.email}
                  </div>
                  <Button
                    className="mt-2"
                    label={t('common.sign_out')}
                    size="small"
                    onClick={logOut}
                  />
                </div>
              </>
            }
          >
            <div
              className="topnav__user"
              onClick={() => setIsUserPopoverVisible(!isUserPopoverVisible)}
            >
              {isCurrentUserFetched && (
                <Avatar
                  src={currentUser.profilePictureUrl}
                  firstName={currentUser?.firstName}
                  lastName={currentUser?.lastName}
                  size="small"
                  bgColor={5}
                  showImageModal={false}
                />
              )}
            </div>
          </Popover>,
        ]}
        showBackButton={false}
        extraOptions={
          isMobile && (
            <div className="ml-4 mt-3">
              <BreadCrumbComponent breadcrumbs={breadcrumbs} />
              <hr className="text-blueish-black-8" />
            </div>
          )
        }
      />
      {isTaskSidePanelOpen && (
        <SidePanel
          className="topnav__sidepanel--task"
          isOpened={isTaskSidePanelOpen}
          title={t('tasks.title')}
          body={<TopnavTaskComponent setIsSidePanelOpen={setIsTaskSidePanelOpen} />}
          onClose={() => setIsTaskSidePanelOpen(false)}
          onClick={() => setIsTaskSidePanelOpen(false)}
        />
      )}
      {isMessageSidePanelOpen && (
        <SidePanel
          className="topnav__sidepanel--message"
          isOpened={isMessageSidePanelOpen}
          title={t('messages.title')}
          body={<TopnavMessageComponent setIsSidePanelOpen={setIsMessageSidePanelOpen} />}
          onClose={() => setIsMessageSidePanelOpen(false)}
          onClick={() => setIsMessageSidePanelOpen(false)}
        />
      )}
    </>
  );
};

TopnavComponent.defaultProps = {
  setIsSideNavOpen: () => {},
};

TopnavComponent.propTypes = {
  setIsSideNavOpen: PropTypes.func,
};

export default TopnavComponent;
