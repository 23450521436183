import React from 'react';
import { useGetContractsQuery } from 'api/contract';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import Pagination from 'components/base-components/Pagination';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import { dateMonthYear } from 'utils/date-time-formatter';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import UserName from 'components/users/user-name.component';
import { t } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const AttributesMapper = ({ value, children }) => {
  if (!value) return t('common.not_applicable');

  return children;
};

AttributesMapper.propTypes = {
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const ContractHistory = () => {
  const { id: organisationId } = useParams();
  const { data } = useGetContractsQuery({ organisationId, latest: false });
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });

  const currentPage = parseInt(searchParams.get('page'), 10);

  const previousContracts = data?.collection || [];

  return (
    <div className="organisation-contract__wrapper">
      {previousContracts.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>{t('user_details.assignment.table_head.valid_from')}</TableHeader>
                <TableHeader>{t('user_details.assignment.table_head.valid_until')}</TableHeader>
                <TableHeader>{t('shared.status')}</TableHeader>
                <TableHeader>{t('contract.attributes.termination_date')}</TableHeader>
                <TableHeader>{t('contract.attributes.terminated_by')}</TableHeader>
                <TableHeader>{t('contract.attributes.termination_reason')}</TableHeader>
                <TableHeader>{t('contract.attributes.action')}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {previousContracts.map((history, index) => (
                <TableRow key={index}>
                  <TableData>
                    <AttributesMapper value={history.validFrom}>
                      {dateMonthYear(history.validFrom)}
                    </AttributesMapper>
                  </TableData>
                  <TableData>
                    <AttributesMapper value={history.validUntil}>
                      {dateMonthYear(history.validUntil)}
                    </AttributesMapper>
                  </TableData>
                  <TableData>{t(`contract.attributes.${history.status}`)}</TableData>
                  <TableData>
                    <AttributesMapper value={history.terminatedAt}>
                      {dateMonthYear(history.terminatedAt)}
                    </AttributesMapper>
                  </TableData>
                  <TableData>
                    <AttributesMapper value={history.terminatorId}>
                      <UserName userID={history.terminatorId} />
                    </AttributesMapper>
                  </TableData>
                  <TableData>
                    <AttributesMapper value={history.terminationReason}>
                      {t(`contract.reasons.${history.terminationReason}`)}
                    </AttributesMapper>
                  </TableData>
                  <TableData>
                    <AttributesMapper value={history.contract?.url}>
                      <Link
                        href={history.contract?.url}
                        modifier="icon-button"
                        type="anchor"
                        openNewTab={true}
                      >
                        <Icon name="download" color="tertiary" />
                      </Link>
                    </AttributesMapper>
                  </TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            onPaginationClick={newPage => {
              setSearchParams({ tab: searchParams.get('tab'), page: newPage });
            }}
            totalPages={data && parseInt(data.pagination.totalPages, 10)}
            currentPage={currentPage}
            nextText={t('pagination.next')}
            previousText={t('pagination.prev')}
            firstText={t('pagination.first')}
            lastText={t('pagination.last')}
          />
        </>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('goods.empty_history')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      )}
    </div>
  );
};

export default ContractHistory;
