import { useGetOrganizationQuery } from 'api/organizations';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import BioCuisineStatus from 'components/organisations/bio-cuisine-status.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './status-component.styles.scss';
import { useGetControlBodyDeclarationsOverviewQuery } from 'api/control-body-declarations';
import { APPROVED, DRAFT, COMPLAINED } from 'utils/verification-status';
import { useGetSupplierCertificatesOverviewQuery } from 'api/supplier-certificates';
import { useGetProductDeclarationsOverviewQuery } from 'api/product-declarations';
import { useGetActiveGoodsOverviewQuery } from 'api/goods-declarations';
import { useGetChartaOverviewQuery } from 'api/chartas';
import { useGetEducationsOverviewQuery } from 'api/educations';

const ContentAvailability = ({
  name,
  status,
  gracePeriodEnabled,
  enabled,
  isLeftEnabled,
  isChartaDeclared,
  isCharta,
  isProduct,
}) => {
  const approved = status === APPROVED;
  const draft = status === DRAFT;
  const complained = status === COMPLAINED;

  const handleIconName = () => {
    if (status === 'expired') return 'highPriority';
    if (
      status === 'approved' ||
      gracePeriodEnabled === true ||
      status === 'submitted' ||
      status === 'active'
    )
      return 'check';

    return 'minimize';
  };

  return (
    <div className="status__step">
      <div
        className={classNames('status__step-bar', {
          'status__step-bar--disabled': !approved && status !== 'active',
          'status__step-bar--disabled-line': !approved && status !== 'active',
        })}
      />
      <div className="status__step-container">
        <div className="status__step-text">{name}</div>
        <Icon
          name={handleIconName()}
          size="tiny"
          showBGColor={true}
          className={classNames('status__step-icon', {
            'status__step-icon--disabled': !approved || !isChartaDeclared,
            'status__step-icon--submitted':
              status === 'submitted' ||
              draft ||
              complained ||
              (isProduct && status === 'new_declaration') ||
              (!isCharta && isChartaDeclared && status === null) ||
              (isCharta && status !== 'active' && !isChartaDeclared),
            'status__step-icon--success':
              (approved && !gracePeriodEnabled) ||
              status === 'active' ||
              (isCharta && isChartaDeclared),
            'status__step-icon--expired': status === 'expired' && !gracePeriodEnabled,
            'status__step-icon--grace-period': gracePeriodEnabled,
          })}
        />
      </div>
    </div>
  );
};

ContentAvailability.defaultProps = {
  name: null,
  enabled: false,
  isCharta: false,
  isProduct: false,
  isLeftEnabled: false,
  gracePeriodEnabled: false,
  status: null,
};

ContentAvailability.propTypes = {
  name: PropTypes.string,
  enabled: PropTypes.bool,
  isChartaDeclared: PropTypes.bool.isRequired,
  isCharta: PropTypes.bool,
  isProduct: PropTypes.bool,
  isLeftEnabled: PropTypes.bool,
  gracePeriodEnabled: PropTypes.bool,
  status: PropTypes.string,
};

const StatusComponent = ({ organisationOverview }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: organisation } = useGetOrganizationQuery(id);
  const organizationId = parseInt(id, 10);

  const { data: chartaOverview = {} } = useGetChartaOverviewQuery({
    organizationId,
  });

  const { data: controlBodyOverview = {} } = useGetControlBodyDeclarationsOverviewQuery({
    organizationId,
  });

  const { data: certificatesOverview = {} } = useGetSupplierCertificatesOverviewQuery({
    organizationId,
  });

  const { data: educationsOverview = {} } = useGetEducationsOverviewQuery({
    organizationId,
  });

  const { data: productDeclarationOverview = {} } = useGetProductDeclarationsOverviewQuery({
    organizationId,
  });

  const { data: activeGoodsOverview = {} } = useGetActiveGoodsOverviewQuery({
    organizationId,
  });

  const starStatus = {
    oneStar: 'one_star',
    twoStars: 'two_stars',
    threeStars: 'three_stars',
  };

  return (
    <div className="grid grid-cols-12 status__step-wrapper">
      <Card className="col-span-12 pt-6 pb-3 status__step-card">
        <div className="status__step-wrapper">
          <ContentAvailability
            name={t('bio_cuisine_status.steps.charta')}
            status={chartaOverview.status}
            gracePeriodEnabled={chartaOverview.gracePeriodEnabled}
            isLeftEnabled={chartaOverview.chartaEnabled}
            isChartaDeclared={chartaOverview.completed}
            isCharta={true}
          />
          <ContentAvailability
            name={t('bio_cuisine_status.steps.control_body')}
            status={controlBodyOverview.status}
            gracePeriodEnabled={controlBodyOverview.gracePeriodEnabled}
            isLeftEnabled={organisationOverview.chartaEnabled}
            isChartaDeclared={chartaOverview.completed}
          />
          <ContentAvailability
            name={t('bio_cuisine_status.steps.education')}
            status={educationsOverview.status}
            gracePeriodEnabled={educationsOverview.gracePeriodEnabled}
            isLeftEnabled={organisationOverview.controlBodyEnabled}
            isChartaDeclared={chartaOverview.completed}
          />
          <ContentAvailability
            name={t('bio_cuisine_status.steps.suppliers')}
            status={certificatesOverview.status}
            gracePeriodEnabled={certificatesOverview.gracePeriodEnabled}
            isLeftEnabled={organisationOverview.goodsEnabled}
            isChartaDeclared={chartaOverview.completed}
          />
          <ContentAvailability
            name={t('organisation.details.tabs.products')}
            status={productDeclarationOverview.status}
            gracePeriodEnabled={productDeclarationOverview.gracePeriodEnabled}
            isLeftEnabled={organisationOverview.educationEnabled}
            isChartaDeclared={chartaOverview.completed}
            isProduct={true}
          />
          <ContentAvailability
            name={t('bio_cuisine_status.steps.goods')}
            status={activeGoodsOverview.status}
            gracePeriodEnabled={activeGoodsOverview.gracePeriodEnabled}
            isLeftEnabled={organisationOverview.productsEnabled}
            isChartaDeclared={chartaOverview.completed}
          />

          <div className="status__step">
            <div
              className={classNames('status__step-bar', {
                'status__step-bar--disabled':
                  organisation?.bioCuisineStars < 1 ||
                  !organisationOverview.controlBodyEnabled ||
                  organisation.downgraded,
              })}
            />
            <div className="status__step-star">
              <div className="status__step-percentage--text">
                {t('bio_cuisine_status.knospp20')}
              </div>
              <div className="status__step-certified">
                {t('bio_cuisine_status.bio_certified_30')}
              </div>
              <BioCuisineStatus
                status={starStatus.oneStar}
                iconSize="medium"
                disabled={organisation?.bioCuisineStars < 1}
                isDowngraded={organisation.downgraded}
              />
              <div className="status__step-star-count">{t('bio_cuisine_status.one_star')}</div>
            </div>
          </div>
          <div className="status__step">
            <div
              className={classNames('status__step-bar', {
                'status__step-bar--disabled':
                  organisation?.bioCuisineStars < 2 || organisation.downgraded,
              })}
            />
            <div className="status__step-star">
              <div className="status__step-percentage--text">
                {t('bio_cuisine_status.knospp40')}
              </div>
              <div className="status__step-certified">
                {t('bio_cuisine_status.bio_certified_60')}
              </div>
              <BioCuisineStatus
                status={starStatus.twoStars}
                iconSize="medium"
                disabled={organisation?.bioCuisineStars < 2}
                isDowngraded={organisation.downgraded}
              />
              <div className="status__step-star-count">{t('bio_cuisine_status.two_stars')}</div>
            </div>
          </div>
          <div className="status__step">
            <div
              className={classNames('status__step-bar', {
                'status__step-bar--disabled':
                  organisation?.bioCuisineStars < 3 || organisation.downgraded,
              })}
            />
            <div className="status__step-star">
              <div className="status__step-percentage--text">
                {t('bio_cuisine_status.knospp60')}
              </div>
              <div className="status__step-certified">
                {t('bio_cuisine_status.bio_certified_90')}
              </div>
              <BioCuisineStatus
                status={starStatus.threeStars}
                iconSize="medium"
                disabled={organisation?.bioCuisineStars < 3}
                isDowngraded={organisation.downgraded}
              />
              <div className="status__step-star-count">{t('bio_cuisine_status.three_stars')}</div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

StatusComponent.propTypes = {
  organisationOverview: PropTypes.shape().isRequired,
};

export default StatusComponent;
