import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  useGetActiveGoodsOverviewQuery,
  useGetOrganizationActiveGoodsQuery,
} from 'api/goods-declarations';
import { useGetCurrentUserQuery } from 'api/users';
import { currentMonthNameWithYear } from 'utils/month-year-formatter';
import { dateMonthYear } from 'utils/date-time-formatter';
import Alert from 'components/base-components/Alert';
import Card from 'components/base-components/Card';
import VerificationTag from 'components/shared-components/verification-tag';
import ViewComplaint from 'components/shared-components/view-complaint';
import ProgressBar from 'components/base-components/ProgressBar';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { dayDifference } from 'utils/day-difference';
import { MAX_STARS } from 'utils/goods';
import BIO_CUISINE_STATUS from 'utils/bio-cuisine-status';
import BioCuisineStatus from '../bio-cuisine-status.component';
import CreateComplaint from './create-complaint.component';
import GoodsEntity from './goods-entity.component';
import BeginDeclaration from './begin-declaration.component';
import DeclareGoods from './declare-goods.component';
import ApproveGoods from './approve-goods.component';

const StarPercentageSummary = ({ percentage, startingPercentage }) => (
  <div>
    {Array.from(Array(MAX_STARS)).map((x, index) => {
      const expectedPercentage = startingPercentage * (index + 1);
      return (
        <span
          className={classNames('goods__average-suggestion--star--percentage', {
            'goods__average-suggestion--star--percentage__disabled':
              percentage >= expectedPercentage,
          })}
        >
          {expectedPercentage}%
        </span>
      );
    })}
  </div>
);

StarPercentageSummary.propTypes = {
  percentage: PropTypes.number.isRequired,
  startingPercentage: PropTypes.number.isRequired,
};

const DeclarationProgressBar = ({ shareBioAveragePercentage, shareBioBudAveragePercentage }) => {
  const { t } = useTranslation();
  const starCount = [
    BIO_CUISINE_STATUS.oneStar,
    BIO_CUISINE_STATUS.twoStar,
    BIO_CUISINE_STATUS.threeStar,
  ];

  const checkBioLevelProgress = (previousThreshold, currentThreshold, actualPercentage, step) => {
    if (parseInt(actualPercentage) >= currentThreshold) {
      return 100;
    } else if (
      parseInt(actualPercentage) <= currentThreshold &&
      parseInt(actualPercentage) > previousThreshold
    ) {
      if (step === 1) {
        return ((parseInt(actualPercentage) - previousThreshold) * 100) / currentThreshold;
      } else if (step === 2) {
        return (((parseInt(actualPercentage) - previousThreshold) * 100) / currentThreshold) * 2;
      }
      return (((parseInt(actualPercentage) - previousThreshold) * 100) / currentThreshold) * 3;
    } else if (parseInt(actualPercentage) < previousThreshold) {
      return 0;
    }
  };

  return (
    <div className="goods__progressbar">
      <div className="goods__progressbar--text">
        <div>{t('goods.bio_certified')}</div>
        <div>{t('goods.bio_bud_certified')}</div>
      </div>
      {Array.from(Array(MAX_STARS)).map((x, index) => {
        const step = index + 1;
        const expectedBioCertifiedPercentage = 30 * step;
        const expectedBioBudCertifiedPercentage = 20 * step;

        return (
          <Fragment>
            <div className="goods__progressbar--wrapper">
              <div className="goods__progressbar--percentage">
                {expectedBioCertifiedPercentage}%
              </div>
              <ProgressBar
                completed={checkBioLevelProgress(
                  expectedBioCertifiedPercentage - 30,
                  expectedBioCertifiedPercentage,
                  shareBioAveragePercentage,
                  step,
                )}
                bgColor="#72B469"
              />
              <ProgressBar
                completed={checkBioLevelProgress(
                  expectedBioBudCertifiedPercentage - 20,
                  expectedBioBudCertifiedPercentage,
                  shareBioBudAveragePercentage,
                  step,
                )}
                bgColor="#72B469"
              />
              <div className="goods__progressbar--percentage">
                {expectedBioBudCertifiedPercentage}%
              </div>
            </div>
            <BioCuisineStatus
              status={starCount[index]}
              iconSize="medium"
              disabled={
                !shareBioAveragePercentage ||
                !shareBioBudAveragePercentage ||
                shareBioAveragePercentage < expectedBioCertifiedPercentage ||
                shareBioBudAveragePercentage < expectedBioBudCertifiedPercentage
              }
            />
          </Fragment>
        );
      })}
    </div>
  );
};

DeclarationProgressBar.propTypes = {
  shareBioAveragePercentage: PropTypes.number.isRequired,
  shareBioBudAveragePercentage: PropTypes.number.isRequired,
};

const ActiveGoodsDeclarationSummary = ({
  shareBioAveragePercentage,
  shareBioBudAveragePercentage,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="goods__last-card">
      <div className="goods__average-title"> {t('goods.higher_rating')}</div>
      <div className="goods__average-header">{t('goods.current_average')}</div>
      <div className="goods__average-wrapper">
        <div className="goods__average-certified">
          {t('goods.bio_certified')}
          <span className="goods__average-certified--percentage">
            {`${parseFloat(shareBioAveragePercentage).toFixed(2)}%` || t('shared.tbd')}
          </span>{' '}
        </div>
        <div className="goods__average-buds">
          {t('goods.bio_bud')}{' '}
          <span className="goods__average-buds--percentage">
            {`${parseFloat(shareBioBudAveragePercentage).toFixed(2)}%` || t('shared.tbd')}
          </span>
        </div>
      </div>
      <div className="goods__average-suggestion--text">{t('goods.suggestion')}</div>
      <div className="goods__average-suggestion--wrapper">
        <div className="goods__average-suggestion--header">
          <div className="goods__average-suggestion--bio-certified">{t('goods.bio_certified')}</div>
          <div className="goods__average-suggestion--bio-bud-certified">
            {t('goods.bio_bud_certified')}
          </div>
        </div>
        <div className="goods__average-suggestion--body">
          <div>
            <span className="goods__average-suggestion--star">
              {t('bio_cuisine_status.one_star')}
            </span>
            <span className="goods__average-suggestion--star">
              {t('bio_cuisine_status.two_stars')}
            </span>
            <span className="goods__average-suggestion--star">
              {t('bio_cuisine_status.three_stars')}
            </span>
          </div>
          <StarPercentageSummary percentage={shareBioAveragePercentage} startingPercentage={30} />
          <StarPercentageSummary
            percentage={shareBioBudAveragePercentage}
            startingPercentage={20}
          />
        </div>
      </div>
    </Card>
  );
};

ActiveGoodsDeclarationSummary.propTypes = {
  shareBioAveragePercentage: PropTypes.number.isRequired,
  shareBioBudAveragePercentage: PropTypes.number.isRequired,
};

const ActiveDeclaration = ({ organizationId }) => {
  const { t } = useTranslation();
  const [goodsDeclarationError, setGoodsDeclarationError] = useState(null);

  const { data: goods } = useGetOrganizationActiveGoodsQuery(organizationId);
  const { data: goodsOverview } = useGetActiveGoodsOverviewQuery({ organizationId });
  const { data: currentUser } = useGetCurrentUserQuery();

  const handleGoodsDeclarationButtonDisabled = () => {
    if (
      !goods ||
      goods?.status === goodsStatus.approved ||
      goods?.status === goodsStatus.submitted ||
      goods?.month1ShareBio === null ||
      goods?.month1ShareKnospe === null ||
      goods?.month1TotalAmount === null ||
      goods?.month2ShareBio === null ||
      goods?.month2ShareKnospe === null ||
      goods?.month2TotalAmount === null ||
      goods?.month3ShareBio === null ||
      goods?.month3ShareKnospe === null ||
      goods?.month3TotalAmount === null
    ) {
      return true;
    }

    return false;
  };

  const goodsStatus = {
    draft: 'draft',
    approved: 'approved',
    submitted: 'submitted',
  };

  const isCurrentUserAdmin = isBioSuisseAdmin(currentUser);

  const complaint = goods?.complaint;

  const lastDeclarationDate = goodsOverview?.gracePeriod || goods?.periodUntil;
  const days = Math.max(dayDifference(lastDeclarationDate, new Date()), 0);

  const hideChangingCurrentMonth = isCurrentUserAdmin || goods?.status !== goodsStatus.draft;

  return (
    <div className="grid grid-cols-12">
      {goodsDeclarationError && (
        <div className="col-span-12">
          <Alert
            isVisible={!!goodsDeclarationError}
            hideClose={true}
            type="danger"
            isIconVisible={false}
          >
            {goodsDeclarationError}
          </Alert>
        </div>
      )}
      <div className="col-span-12">
        <div className="goods-period">
          <div className="goods-period__container">
            <div className="goods-period__container-current">
              {t('goods.current_period')}
              <span className="goods-period__container-current--date">
                {goods?.periodFrom
                  ? `${currentMonthNameWithYear(goods?.periodFrom)} - ${currentMonthNameWithYear(
                      goods?.periodUntil,
                    )}`
                  : t('shared.tbd')}
              </span>
              {!complaint && <VerificationTag status={goods?.status} />}
              {complaint && <ViewComplaint complaint={complaint} />}
            </div>
            <div className="goods__deadline">
              {t('goods.deadline_declaration')}
              <span className="goods__deadline--date">
                {goodsOverview?.deadline ? dateMonthYear(goodsOverview?.deadline) : t('shared.tbd')}
              </span>
            </div>
          </div>
          {!hideChangingCurrentMonth && <BeginDeclaration changeMonth={true} />}
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <Card>
          <div className="goods__wrapper">
            <div>
              <div className="goods__remaining-time"> {t('goods.remaining_time')} </div>
              <div className="goods__remaining-time--day">
                {goods ? t('goods.day', { count: days }) : t('shared.tbd')}
              </div>
            </div>
            <DeclarationProgressBar
              shareBioAveragePercentage={goods?.shareBioAveragePercentage}
              shareBioBudAveragePercentage={goods?.shareBioBudAveragePercentage}
            />
            {isCurrentUserAdmin ? (
              <div className="goods__action">
                <div className="goods__action-complain">
                  <CreateComplaint
                    isDisabled={goods?.status !== goodsStatus.submitted}
                    goodsDeclarationId={goods?.id}
                  />
                </div>
                <ApproveGoods
                  disabled={goods?.status !== goodsStatus.submitted}
                  peridoFrom={goods?.periodUntil}
                  periodUntil={goods?.periodUntil}
                />
              </div>
            ) : (
              <div className="goods__action">
                <DeclareGoods
                  disabledDeclaration={handleGoodsDeclarationButtonDisabled()}
                  setGoodsDeclarationError={setGoodsDeclarationError}
                  periodFrom={goods?.periodFrom}
                  periodUntil={goods?.periodUntil}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
      {goods ? (
        <div className="col-span-grid direction-row col-span-12 goods__container">
          <GoodsEntity
            goods={goods}
            goodsDeclarationSummary={
              <ActiveGoodsDeclarationSummary
                shareBioAveragePercentage={goods.shareBioAveragePercentage}
                shareBioBudAveragePercentage={goods.shareBioBudAveragePercentage}
              />
            }
            showEditButton={true}
          />
        </div>
      ) : (
        !isBioSuisseAdmin(currentUser) && (
          <div className="col-span-12">
            <BeginDeclaration />
          </div>
        )
      )}
    </div>
  );
};

ActiveDeclaration.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default ActiveDeclaration;
