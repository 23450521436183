import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import { t } from 'i18next';
import LatestInvoiceComponent from './dashboard/latest-invoice.component';
import TvdComponent from './dashboard/tvd.component';
import SeasonalDataComponent from './dashboard/seasonal-data.component';
import OverviewComponent from './dashboard/overview.component';
import './producer.styles.scss';

const ProducerOverview = ({ customerSinceYear }) => {
  return (
    <div className="grid lg:grid-cols-3 gap-3 sm:gap-4">
      <div className="col-span-1">
        <OverviewComponent />
      </div>
      <div className="col-span-1">
        <Card className="rounded-2">
          <LatestInvoiceComponent />
          <TvdComponent />
        </Card>
      </div>

      <div className="col-span-1">
        <Card className="rounded-2">
          {customerSinceYear > new Date().getFullYear() ? (
            <SeasonalDataComponent
              className="sm:min-h-[550px]"
              year={customerSinceYear}
              title={t('producer.dashboard.upcoming_season', {
                year: customerSinceYear,
              })}
              season="upcoming_season"
            />
          ) : (
            <>
              <SeasonalDataComponent
                year={new Date().getFullYear()}
                title={t('producer.dashboard.current_season', {
                  year: new Date().getFullYear(),
                })}
                season="current_season"
              />
              <hr className="border-horizontal-line my-4" />
              <SeasonalDataComponent
                className="mt-4"
                year={new Date().getFullYear() - 1}
                title={t('producer.dashboard.previous_season', {
                  year: new Date().getFullYear() - 1,
                })}
                season="previous_season"
              />
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

ProducerOverview.defaultProps = {
  customerSinceYear: null,
};

ProducerOverview.propTypes = {
  customerSinceYear: PropTypes.shape(),
};

export default ProducerOverview;
