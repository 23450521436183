import React from 'react';
import PropTypes from 'prop-types';
import { dateMonthYear } from 'utils/date-time-formatter';
import { useGetCompanyUnitsQuery } from 'api/producers';
import { useTranslation } from 'react-i18next';
import Card from 'components/base-components/Card';
import AttributeAlignment from './attribute-alignment.component';

const GeneralInformation = ({ organisationDataLakeAttribute, companyIdentifier }) => {
  const { t } = useTranslation();
  const burNRs = [];
  const tvdNRs = [];
  const { data: companyUnitData } = useGetCompanyUnitsQuery(
    { companyIdentifier },
    { skip: !companyIdentifier },
  );

  const companyUnits = companyUnitData ? companyUnitData.collection : [];
  companyUnits.forEach(companyUnit => {
    burNRs.push(companyUnit.burnr);
    tvdNRs.push(companyUnit.tvdnr);
  });

  return (
    <Card
      className="super-office-details--general_information"
      title={t('organisation.super_office.attributes.general_information')}
    >
      <div className="super-office-details__basic grid grid-cols-4">
        <div className="col-span-1">
          <AttributeAlignment
            label={t('organisation_type.uid')}
            value={organisationDataLakeAttribute?.uid}
          />
        </div>
        <div className="col-span-1">
          <AttributeAlignment
            label={t('organisation.super_office.attributes.bio_betriebsnummer')}
            value={organisationDataLakeAttribute?.debitNumber}
          />
        </div>
        <div className="col-span-1">
          <AttributeAlignment
            label={t('organisation.super_office.attributes.bur_no')}
            value={burNRs[0]}
          />
        </div>
        <div className="col-span-1">
          <AttributeAlignment
            label={t('organisation.super_office.attributes.customer_since')}
            value={
              organisationDataLakeAttribute?.customerSince &&
              dateMonthYear(organisationDataLakeAttribute.customerSince)
            }
          />
        </div>
      </div>
    </Card>
  );
};

GeneralInformation.propTypes = {
  organisationDataLakeAttribute: PropTypes.shape().isRequired,
  companyIdentifier: PropTypes.number.isRequired,
};

export default GeneralInformation;
