import { useGetInvoicesQuery } from 'api/invoices';
import Card from 'components/base-components/Card';
import InvoiceStatusTag from 'components/invoices/helpers/invoice-tag.component';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { OPEN, OVERDUE, PAID, PENDING } from 'utils/invoice-statuses';

const LatestInvoiceComponent = () => {
  const navigationLinks = useNavigationLinks();
  const { id } = useParams();

  const invoiceParams = {
    direction: 'down',
    per_page: 3,
    page: 1,
    column: 'due_date',
    'organization_ids[]': [id],
    'statuses[]': [OPEN, OVERDUE, PENDING, PAID],
  };

  const { data: invoiceCollection } = useGetInvoicesQuery(invoiceParams);

  const invoiceList = invoiceCollection?.collection || [];

  return (
    <>
      <div className="font-bold text-lg mb-5">{t('producer.dashboard.latest_invoice')}</div>
      {invoiceList.length > 0 && (
        <Card className="mt-2 border-nont shadow-none py-0 p-x-4">
          <div className="flex justify-between font-bold">
            <span>{t('producer.dashboard.due_date')}</span>
            <span>{t('producer.dashboard.due_amount')}</span>
            <span>{t('producer.dashboard.status')}</span>
          </div>
        </Card>
      )}
      {invoiceList.length > 0 ? (
        invoiceList.map(invoice => (
          <Card className="mt-2">
            <div className="flex justify-between">
              <span>{invoice.dueDate || t('common.not_applicable')}</span>
              <span>{invoice.totalRemainingDue || t('common.not_applicable')}</span>
              <InvoiceStatusTag invoice={invoice} />
            </div>
          </Card>
        ))
      ) : (
        <div className="font-bold text-xl mb-5 [&_.card]:min-h-[266px]">
          <EmptyContentPlaceholder iconName="invoice" text={t('invoice.no_invoice')} />
        </div>
      )}
      {invoiceList.length > 0 && (
        <div className="mt-4 text-info-dark text-base font-semibold">
          <Link to={navigationLinks.organisationInvoicePage(id)}>
            {t('producer.dashboard.view_invoice')}
          </Link>
        </div>
      )}
      <hr className="border-horizontal-line my-4" />
    </>
  );
};

export default LatestInvoiceComponent;
