import { useGetAdminOrganizationOverviewQuery } from 'api/organizations';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import { Header } from 'components/base-components/Typography';
import useNavigationLinks from 'hooks/useNavigationLinks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const OrganisationOverviewAdmin = () => {
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();

  const { data: organisationAdminOverviewData, isSuccess: isOrganisationOverviewFetched } =
    useGetAdminOrganizationOverviewQuery();

  return (
    <Card>
      {isOrganisationOverviewFetched && (
        <>
          <Header level={6} weight="boldest">
            {t('dashboard.organisation_overview')}
          </Header>
          <hr className="my-2" />
          <div className="dashboard__admin-total-organisation">
            <span className="dashboard__admin-total-organisation__title">
              {t('dashboard.total_organisation')}
            </span>
            <span> {organisationAdminOverviewData.organizationCount} </span>
          </div>
          <div className="dashboard__admin-star-count">
            <span className="dashboard__admin-star-count__title">
              {' '}
              {t('bio_cuisine_status.three_stars')}{' '}
            </span>
            <span> {organisationAdminOverviewData.organizationStars[3]} </span>
          </div>
          <div className="dashboard__admin-star-count">
            <span className="dashboard__admin-star-count__title">
              {t('bio_cuisine_status.two_stars')}
            </span>
            <span> {organisationAdminOverviewData.organizationStars[2]} </span>
          </div>
          <div className="dashboard__admin-star-count">
            <span className="dashboard__admin-star-count__title">
              {t('bio_cuisine_status.one_star')}
            </span>
            <span> {organisationAdminOverviewData.organizationStars[1]} </span>
          </div>
          <div className="dashboard__admin-star-count">
            <span className="dashboard__admin-star-count__title">
              {t('organisation.not_certified')}
            </span>
            <span> {organisationAdminOverviewData.organizationStars[0]} </span>
          </div>
          <Header level={6} weight="boldest">
            {t('dashboard.departments_overview')}
          </Header>
          <hr className="my-2" />
          {Object.keys(organisationAdminOverviewData.industries).map(key => (
            <div className="dashboard__admin-department">
              {t(`dashboard.${key}`)}
              <span> {organisationAdminOverviewData.industries[key]} </span>
            </div>
          ))}
          <div className="margin-top-10">
            <Link to={navigationLinks.organisationsCreatePage()}>
              <Button type="success" size="small" label={t('organisation.create_organisation')} />
            </Link>
          </div>
        </>
      )}
    </Card>
  );
};

export default OrganisationOverviewAdmin;
