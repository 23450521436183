import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Avatar from 'components/base-components/Avatar/Avatar';
import { useGetUserQuery } from 'api/users';

const ContactUser = ({ assignment }) => {
  const { data: user = {} } = useGetUserQuery({ id: assignment.userId });

  return (
    <div className="flex items-start mt-2 col-span-1">
      <Avatar src={user.profilePictureUrl} firstName={user.firstName} lastName={user.lastName} />
      <div className="flex flex-col font-normal mt-[3px] mr-2 mb-[3px] ml-2 capitalize">
        <span>{user.name}</span>
        <span className="text-[0.625rem]">{t(`role.${assignment.role}`)}</span>
      </div>
    </div>
  );
};

ContactUser.propTypes = {
  assignment: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactUser;
