import { t } from 'i18next';
import { FieldArray } from 'react-final-form-arrays';
import React from 'react';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import PropTypes from 'prop-types';
import { useGetProcessingTypeOptionsQuery } from 'api/producers/productions';
import CheckboxWithIcon from '../shared/checkbox-with-icon.component';

const FarmLandProcessingCheckbox = ({ processings }) => {
  const { data: processingTypeOptions = [] } = useGetProcessingTypeOptionsQuery();

  const ICON_MAPPER = {
    farm_production: 'farmHouse',
    direct_marketing: 'marketing',
    teaching_farm: 'teachingFarm',
  };

  const processTypes = processingTypeOptions.map(({ value, label }) => {
    return {
      name: value,
      label,
      icon: ICON_MAPPER[value],
    };
  });

  const processingsDataToSnakeCase = processings && toSnakecaseKeys(processings);

  return (
    <>
      <div className="farmland__production-title">{t('farmland.processing')}</div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-6 my-4">
        <FieldArray name="processings">
          {({ fields }) => (
            <>
              {processTypes.map(({ name, label, icon }, index) => (
                <div key={name} className="col-span-1">
                  <CheckboxWithIcon
                    label={label}
                    fieldName={`processings[${index}]`}
                    name={name}
                    icon={icon}
                    isChecked={processingsDataToSnakeCase ? processingsDataToSnakeCase[name] : ''}
                  />
                </div>
              ))}
            </>
          )}
        </FieldArray>
      </div>
    </>
  );
};

FarmLandProcessingCheckbox.defaultProps = {
  processings: null,
};

FarmLandProcessingCheckbox.propTypes = {
  processings: PropTypes.shape(),
};

export default FarmLandProcessingCheckbox;
