import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetCurrentUserQuery } from 'api/users';
import SidenavComponent from 'components/sidenav/sidenav.component';
import TopnavComponent from 'components/topnav/topnav.component';
import { useState } from 'react';

const ProtectedRoute = ({ allowedRoles }) => {
  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  if (isCurrentUserFetched) {
    return allowedRoles.includes(currentUser.role) ? (
      <>
        <TopnavComponent user={currentUser} setIsSideNavOpen={setIsSideNavOpen} />
        <SidenavComponent
          currentUser={currentUser}
          isSideNavOpen={isSideNavOpen}
          setIsSideNavOpen={setIsSideNavOpen}
        />
        <Outlet />
      </>
    ) : (
      (window.location.href = '/')
    );
  }
  return null;
};

ProtectedRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProtectedRoute;
