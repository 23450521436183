import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { SuccessModal } from 'components/shared-components/modal/submit-modal';
import { Text } from 'components/base-components/Typography';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useGetUserQuery } from 'api/users';
import {
  useGetProvisioningUriQuery,
  useEnableTwoFactorAuthenticationMutation,
} from 'api/two-factor-authentication';
import { useParams } from 'react-router';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import Alert from 'components/base-components/Alert';
import TwoFactorConfigurationStatus from './configuration-status.component';

const TwoFactorConfiguration = ({ onSave }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(null);
  const { data: provisioningUri, error } = useGetProvisioningUriQuery();
  const { userId } = useParams();
  const { data: userData } = useGetUserQuery({ id: userId }, { skip: !userId });
  const [enableTwoFactorAuthentication, { isSuccess, error: { data: errorResponse } = {} }] =
    useEnableTwoFactorAuthenticationMutation();

  const onEnableTwoFactorAuthentication = () => {
    enableTwoFactorAuthentication({ otp });
  };

  const isExpired = error?.data?.token || errorResponse?.token;

  return (
    <>
      <SuccessModal
        title={t('user_credential.two_factor.modal.header.enable')}
        content={t('user_credential.two_factor.modal.content.enable')}
        isVisible={isSuccess}
        timeout={3000}
        onTimeout={onSave}
      />
      <div className="user-two-factor-configuration">
        <TwoFactorConfigurationStatus enabled={userData?.twoFactorEnabled} />
        <div className="mt-4">
          <Text level={1} weight="boldest">
            {t('user_credential.two_factor.step_1.header')}
          </Text>
          <Text isInline={true} className="inline-block mt-2">
            <Trans
              i18nKey="user_credential.two_factor.step_1.message"
              components={{
                anchor: (
                  <a
                    href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                    target="_blank"
                    rel="noreferrer"
                    className="organisation__form-sub-label--color"
                  />
                ),
              }}
            />
          </Text>
        </div>
        <div className="flex flex-col mt-4">
          <Text level={1} weight="boldest">
            {t('user_credential.two_factor.step_2.header')}
          </Text>
          <Text isInline={true} className="mt-2">
            {t('user_credential.two_factor.step_2.message')}
          </Text>
          <QRCode
            size={172}
            value={provisioningUri}
            className="self-center mt-4"
            imageSettings={{
              height: 22,
              width: 22,
              excavate: true,
            }}
          />

          {isExpired && (
            <div className="mt-3">
              <Alert isVisible={true} hideClose={true} type="warning" isIconVisible={false}>
                {isExpired}
              </Alert>
            </div>
          )}
        </div>
        <div className="mt-4">
          <Text level={1} weight="boldest">
            {t('user_credential.two_factor.step_3.header')}
          </Text>
          <Input
            label={t('user_credential.two_factor.authentication_code.label')}
            placeholder={t('user_credential.two_factor.authentication_code.placeholder')}
            className="mt-6 w-[345px]"
            required={true}
            value={otp}
            onChange={setOtp}
            error={errorResponse?.error}
            touched={!!errorResponse?.error}
            isClearable={false}
          />
        </div>
        <div className="user-two-factor-configuration__action mt-4">
          <Button
            label={t('user_credential.two_factor.configure')}
            type="success"
            size="small"
            submitType="submit"
            disabled={!otp}
            onClick={onEnableTwoFactorAuthentication}
          />
          <Button label={t('shared.action.cancel')} onClick={onSave} size="small" />
        </div>
      </div>
    </>
  );
};

TwoFactorConfiguration.propTypes = {
  onSave: PropTypes.func.isRequired,
};
export default TwoFactorConfiguration;
