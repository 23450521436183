import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Text } from 'components/base-components/Typography';
import EmphasisTag from 'components/base-components/EmphasisTag';

const TwoFactorConfigurationStatus = ({ enabled }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text
        level={1}
        weight="boldest"
        isInline={true}
        className="block text-[30px] mb-4 text-left w-full md:text-center md:mb-8 mt-6 md:mt-20"
      >
        {t('user_credential.two_factor.header')}
      </Text>
      <EmphasisTag
        type={enabled ? 'success' : 'neutral'}
        size="small"
        text={enabled ? t('shared.enabled') : t('shared.disabled')}
      />
    </>
  );
};

TwoFactorConfigurationStatus.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default TwoFactorConfigurationStatus;
