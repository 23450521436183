import React from 'react';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Card from 'components/base-components/Card';
import './contact-loader.styles.scss';

const ContactLoadingPlaceholder = () => {
  return (
    <div className="user__contact-loader">
      <Card>
        <div className="user__contact-loader--first col-grid  direction-row">
          <div className="col-grid  direction-row">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="50%" />
          </div>
          <div className="col-grid  direction-row justify-end">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="12%" />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="12%" />
          </div>
        </div>
      </Card>
      <Card>
        <div className="user__contact-loader--second col-grid  direction-row">
          <div className="col-grid  direction-row">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="40%" />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="20%" />
          </div>
          <div className="col-grid  direction-row justify-end">
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="12%" />
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} contentWidth="12%" />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContactLoadingPlaceholder;
