import useNavigationLinks from 'hooks/useNavigationLinks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import React from 'react';
import useResponsive from 'hooks/useResponsive';
import classNames from 'classnames';
import BioLogo from 'assets/bio_logo.png';
import IconButton from 'components/base-components/IconButton';
import LanguageComponent from 'components/shared-components/language';
import Icon from '../base-components/Icon';
import { SideNav, SideNavFooterBrand, SideNavLink, SideNavList } from '../base-components/Sidenav';
import './sidenav.styles.scss';

export default function SidenavComponent({ currentUser, isSideNavOpen, setIsSideNavOpen }) {
  const { t } = useTranslation();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const isCBMember = isControlBodyMember(currentUser);
  const navigationLinks = useNavigationLinks();
  const location = useLocation();
  const { isMobile } = useResponsive();
  const basePath = location.pathname === '/' ? 'dashboard' : location.pathname.split('/')[1];

  return (
    <SideNav
      className={classNames('side-nav', {
        '-left-full transition-all ease-in-out duration-500': isMobile,
        '!left-0': isSideNavOpen,
      })}
    >
      <SideNavList>
        {isMobile && (
          <>
            <div className="flex justify-between items-center side-nav__wrapper">
              <div className="flex items-center">
                <IconButton
                  icon={<Icon name="arrowBackAlt" size="small" />}
                  size="medium"
                  color="white"
                  onClick={() => setIsSideNavOpen(false)}
                />
                <img className="ml-2" src={BioLogo} alt="Bio-Suisse" height="50" width="50" />
              </div>
              <LanguageComponent />
            </div>
            <hr className=" mt-2 text-white-24" />
          </>
        )}
        <div onClick={() => setIsSideNavOpen(false)}>
          <SideNavLink
            href={navigationLinks.rootPage()}
            icon={<Icon name="home" size="medium" />}
            label={t('sidenav.dashboard')}
            activeLink={basePath === 'dashboard'}
          />
          {(isAdmin || isCBMember) && (
            <SideNavLink
              href={navigationLinks.usersPage()}
              icon={<Icon name="person_outline" size="medium" />}
              label={t('sidenav.user')}
              activeLink={basePath === 'users'}
            />
          )}
          {isCBMember ? (
            <SideNavLink
              href={navigationLinks.organisationsPage()}
              icon={<Icon name="organization" />}
              label={t('sidenav.organisation')}
              activeLink={basePath === 'organisations'}
            />
          ) : (
            <>
              <SideNavLink
                className="side-nav__organisation"
                href={navigationLinks.restaurantsPage()}
                icon={<Icon name="restaurant" size="medium" />}
                label={t('sidenav.restaurants')}
                activeLink={basePath === 'restaurants'}
              />
              <SideNavLink
                className="side-nav__organisation"
                href={navigationLinks.producersPage()}
                icon={<Icon name="producer" size="medium" />}
                label={t('sidenav.producers')}
                activeLink={basePath === 'producers'}
              />
              <SideNavLink
                className="side-nav__organisation"
                href={navigationLinks.controlBodyPage()}
                icon={<Icon name="controlBody" size="medium" />}
                label={t('sidenav.control_body')}
                activeLink={basePath === 'control-body'}
              />
            </>
          )}
          <SideNavLink
            href={navigationLinks.tasksListPage()}
            icon={<Icon name="tasksAlt" size="medium" />}
            label={t('sidenav.task_management')}
            activeLink={basePath === 'tasks'}
          />
          {!isCBMember && (
            <SideNavLink
              href={navigationLinks.messagesListPage()}
              icon={<Icon name="envelop" size="medium" />}
              label={t('sidenav.message_management')}
              activeLink={basePath === 'messages'}
            />
          )}
          {isAdmin && (
            <SideNavLink
              href={navigationLinks.invoiceJobListPage()}
              icon={<Icon name="invoice_outline" size="medium" />}
              label={t('sidenav.invoices')}
              activeLink={basePath === 'invoices'}
            />
          )}
          {isAdmin && (
            <SideNavLink
              href={navigationLinks.dataCenterPage()}
              icon={<Icon name="database" size="medium" />}
              label={t('sidenav.data_center')}
              activeLink={basePath === 'data-center'}
            />
          )}
        </div>
      </SideNavList>
      <SideNavList type="footer">
        <SideNavFooterBrand />
      </SideNavList>
    </SideNav>
  );
}

SidenavComponent.defaultProps = {
  isSideNavOpen: false,
  setIsSideNavOpen: () => {},
};

SidenavComponent.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  isSideNavOpen: PropTypes.bool,
  setIsSideNavOpen: PropTypes.func,
};
