import { useGetRestrictedIngredientsQuery } from 'api/control-body-survey';
import {
  useApproveRestrictedIngredientsAdditionalTaskMutation,
  useDeclareRestrictedIngredientsAdditionalTaskMutation,
  useGetActiveRestrictedIngredientsAdditionalTaskQuery,
} from 'api/control-body-survey/restricted-ingredients';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import Textarea from 'components/base-components/Textarea';
import YesNoInput from 'components/base-components/YesNoInput';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { CONTROL_BODY_USER, USER } from 'utils/users-role';
import { ACTIVE, DRAFT, SUBMITTED } from 'utils/verification-status';
import { boolToYesNo } from 'utils/yes-no-helper';
import NegativeReportsHeader from '../header.component';
import AdditionalTaskSubmitButton from '../helpers/additional-task-submit-button.component';
import NegativeTaskModal from '../helpers/negative-task-modal.component';

const RestrictedIngredients = ({ surveyId }) => {
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { data: activeAdditionalTask, isFetching } =
    useGetActiveRestrictedIngredientsAdditionalTaskQuery({
      surveyId,
    });
  const { data: activeRestrictedIngredients = [] } = useGetRestrictedIngredientsQuery({
    surveyId,
    type: ACTIVE,
  });

  const [declare, submitStatus] = useDeclareRestrictedIngredientsAdditionalTaskMutation();
  const [approve, approveStatus] = useApproveRestrictedIngredientsAdditionalTaskMutation();

  if (isFetching)
    return (
      <div className="negative-reports__container">
        <ContentLoaderPlaceholder numberOfLines={6} isRounded={true} />
      </div>
    );
  if (!activeAdditionalTask) return null;

  const onSave = values => {
    if (activeAdditionalTask.status === SUBMITTED) {
      let payload = {};
      if (values.fulfilled === 'yes') payload['fulfilled'] = true;
      else if (values.fulfilled === 'no') payload['fulfilled'] = false;

      return approve({ surveyId, payload })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      return declare({ surveyId, payload: values })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  return (
    <div className="negative-reports__container">
      <NegativeTaskModal submitStatus={submitStatus} approveStatus={approveStatus} />
      <Card>
        <NegativeReportsHeader
          status={activeAdditionalTask.status}
          text={t('negative_report.restricted_ingredients')}
          date={activeAdditionalTask.createdAt}
          dueDate={activeAdditionalTask.dueDate}
        />

        <Form
          onSubmit={onSave}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2">
                <div className="col-span-1 negative-reports__left-panel">
                  <div className="negative-reports__item-header">
                    {t('control_body.survey.form.third_step')}
                  </div>
                  <ul className="negative-reports__item-list">
                    {activeRestrictedIngredients.map(ingredient => (
                      <li>
                        <div className="negative-reports__item-list__wrapper">
                          <span>
                            {t(`control_body.survey.form.restricted_items.${ingredient.type}`)}
                          </span>
                          <span>
                            {t(`control_body.survey.shared.${ingredient.exists ? 'yes' : 'no'}`)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-span-1 negative-reports__right-panel">
                  <Field name="reason" initialValue={activeAdditionalTask.declarationReason}>
                    {({ input, meta }) => (
                      <Textarea
                        size="small"
                        label={t('control_body.survey.form.declare_reason')}
                        placeholder={t('control_body.survey.form.declare_reason_placeholder')}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                        required={true}
                        disabled={
                          activeAdditionalTask.status !== DRAFT || currentUser.role !== USER
                        }
                        {...input}
                      />
                    )}
                  </Field>

                  <div className="negative-reports__form-description">
                    {currentUser.role === USER ? (
                      <div>{t('control_body.survey.form.declare_reason_description')}</div>
                    ) : (
                      <Field
                        name="fulfilled"
                        initialValue={boolToYesNo(activeAdditionalTask.fulfilled)}
                      >
                        {({ input, meta }) => (
                          <YesNoInput
                            {...input}
                            error={meta.submitError}
                            disabled={
                              activeAdditionalTask.status !== SUBMITTED ||
                              currentUser.role !== CONTROL_BODY_USER
                            }
                            label={`${t('control_body.survey.form.third_step_cb')}`}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="col-span-12">
                  <AdditionalTaskSubmitButton
                    status={activeAdditionalTask.status}
                    disabled={submitting}
                    approverType={CONTROL_BODY_USER}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

RestrictedIngredients.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default RestrictedIngredients;
