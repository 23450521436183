import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { useTranslation } from 'react-i18next';
import Card from 'components/base-components/Card';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import useNavigationLinks from 'hooks/useNavigationLinks';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { parseTaskDescription, parseTaskTitle } from 'components/task-management/task-helper';
import { Link } from 'react-router-dom';

const TopnavTaskTabContentComponent = ({
  tasks,
  isFetched,
  onChangeParams,
  status,
  assigned_to,
  setIsSidePanelOpen,
}) => {
  const { t } = useTranslation();

  const emphasisTagType = {
    high: 'danger',
    low: 'disabled',
    medium: 'warning',
  };

  const navigationLinks = useNavigationLinks();

  useEffect(() => {
    onChangeParams({ assigned_to, status, page: 1 }, status, true);
  }, []);

  return (
    <div className="grid grid-cols-12">
      {isFetched && tasks.length > 0 ? (
        tasks.map(task => (
          <div className="col-span-12">
            <Card>
              <div className="topnav__task">
                <div className="topnav__task-tag">
                  <EmphasisTag
                    type={emphasisTagType[task?.priority]}
                    text={t(`tasks.priority.${task?.priority}`)}
                    size="tiny"
                  />
                  <div className="topnav__task-tag--due-date">
                    {dateMonthYearFormatter(task?.dueDate)}
                  </div>
                </div>
                <Link to={navigationLinks.taskDetailsPage(task.id)}>
                  <IconButton
                    icon={<Icon name="show" />}
                    isIconOnly={true}
                    color="tertiary"
                    onClick={() => setIsSidePanelOpen(false)}
                  />
                </Link>
              </div>
              <div className="topnav__task-text"> {parseTaskTitle(task)}</div>
              <div className="topnav__task-body"> {parseTaskDescription(task)} </div>
            </Card>
          </div>
        ))
      ) : (
        <EmptyContentPlaceholder
          text={t('tasks.no_task_found')}
          iconName="tasks"
          showCard={false}
        />
      )}

      {isFetched && tasks.length > 0 && (
        <Link className="col-span-12" to={navigationLinks.tasksListPage()}>
          <div onClick={() => setIsSidePanelOpen(false)}>
            <hr className="topnav__task-horizontal" />
            <div className="topnav__task-view"> {t('dashboard.view_task')} </div>
            <hr className="topnav__task-horizontal" />
          </div>
        </Link>
      )}
    </div>
  );
};

TopnavTaskTabContentComponent.defaultProps = {
  tasks: [],
  isFetched: false,
  status: null,
  assigned_to: null,
  setIsSidePanelOpen: () => {},
  onChangeParams: () => {},
};

TopnavTaskTabContentComponent.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape()),
  isFetched: PropTypes.bool,
  onChangeParams: PropTypes.func,
  status: PropTypes.string,
  assigned_to: PropTypes.func,
  setIsSidePanelOpen: PropTypes.func,
};

export default TopnavTaskTabContentComponent;
